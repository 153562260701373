import { useState } from "react";
import Filters from "./components/Filters";
import WithoutPlan from "./components/WithoutPlan";
import RejectedPlan from "./components/RejectedPlan";
import AwaitingPlan from "./components/AwaitingPlan";
import ReservedPlan from "./components/ReservedPlan";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Filters activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 1 && <WithoutPlan />}
      {activeTab === 2 && <RejectedPlan />}
      {activeTab === 3 && <AwaitingPlan />}
      {activeTab === 4 && <ReservedPlan />}
    </>
  );
}
