import moment from "moment-jalaali";
import { svgFileUrl } from "../../../../../../../../config";
import { useState } from "react";
import PDFDownload from "./PDFDownload";
import { Button } from "../../../../../../../../components/common";
import { useAxiosWithToken } from "../../../../../../../../hooks";
import api from "../../../../../../../../api";
import { notify } from "../../../../../../../../helper";
import { NotifyMessage } from "../../../../../../../../enums";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData, reloadTable, showModal }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  /*   const [detailShow, setDetailShow] = useState(false); */
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const approvePrescription = (id) => {
    setLoading(true);
    useAxiosWithToken
      .put(api.doctor.prescriptionChangeStatus + "?medicalPrescriptionStatus=APPROVE&id=" + id)
      .then((res) => {
        setLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        reloadTable();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const rejectPrescription = (id) => {
    setRemoveLoading(true);
    useAxiosWithToken
      .put(api.doctor.prescriptionChangeStatus + "?medicalPrescriptionStatus=REJECT&id=" + id)
      .then((res) => {
        setRemoveLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        reloadTable();
      })
      .catch((err) => {
        setRemoveLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:grid flex justify-between xl:grid-cols-6 gap-1 mt-[12px] border border-[#E8E7EB] h-auto p-4 xl:p-0 xl:h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span>نسخه</span>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section>{moment(rowData?.createdOn).format("jYYYY/jMM/jDD")}</section>
        <section className="flex justify-center  items-center gap-2">
          <button onClick={() => showModal(rowData)} className="px-2 text-purple font-semibold py-2 rounded-full bg-[whitesmoke] flex justify-center gap-1 items-center">
            <span> نمایش جزییات</span>
            <img className="w-[20px] h-[20px]" src={svgFileUrl + "detail.svg"} alt="" />
          </button>
        </section>
        <section>
          <PDFDownload data={rowData} />
        </section>
        <section className="flex gap-2 justify-center col-span-2">
          <Button onClick={() => approvePrescription(rowData.id)} loading={loading} className="w-[150px] h-[30px] bg-green-600 text-white rounded-full">
            تایید نهایی
          </Button>
          <Button onClick={() => rejectPrescription(rowData.id)} loading={removeLoading} className="w-[110px] h-[30px] bg-red-500 text-white rounded-full">
            حذف
          </Button>
        </section>
      </section>
      {/*    {detailShow && (
        <section className="w-full bg-slate-100 min-h-[100px] rounded-lg">
          <section className="grid grid-cols-4 py-2 text-xs">
            <span>نام دارو</span>
            <span>نوع دارو</span>
            <span>تعداد</span>
            <span>توضیحات</span>
          </section>
          {rowData?.medicalPrescriptionDetails?.map((item, index) => (
            <section key={index} className="grid grid-cols-4 py-2 font-semibold text-xs text-purple">
              <span>{item.drugName}</span>
              <span>{item.drugType}</span>
              <span>{item.count}</span>
              <span>{item.description}</span>
            </section>
          ))}
          <hr />
          <section className="flex justify-start px-[10px] mt-2 text-purple">
            <span>توضیحات:</span>
            <span className="font-semibold">{rowData?.description}</span>
          </section>
        </section>
      )} */}
    </>
  );
}
