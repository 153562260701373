import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

Font.register({
  family: "iransans",
  src: "/fonts/vazir/Vazir.ttf",
});

const PDFTable = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "iransans",
      position: "relative",
    },
    table: {
      display: "table",
      width: "auto",
      marginTop: 10,
    },
    tableRow: {
      display: "flex",
      flexDirection: "row-reverse",
      alignContent: "stretch",
      marginTop: 10,
      border: "1px solid silver",
      borderRadius: 5,
    },
    tableCol: {
      width: "25%",
      padding: 5,
      textAlign: "center",
    },
    tableCol2: {
      width: "75%",
      padding: 5,
      textAlign: "center",
    },
    tableCell: {
      fontSize: 8,
      textAlign: "center",
      direction: "rtl",
    },
    pkgBox: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row-reverse",
      padding: 5,
    },
    pkgName: {
      backgroundColor: "#5606a8",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      marginRight: "auto",
      width: "100%",
      paddingRight: 10,
      height: 20,
    },
    watermark: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
    },
    watermarkImage: {
      width: "50%",
      opacity: "0.1",
    },
  });
  try {
    return (
      <Document>
        <Page break wrap={true} size="A4" style={styles.page}>
          <View style={styles.watermark}>
            <Image alt="" style={styles.watermarkImage} src="/images/watermark.png" />
          </View>
          {/*  <View style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center" }}>
            <Text style={{ fontSize: 12, fontWeight: 600 }}>برنامه غذایی</Text>
            <Text style={{ fontSize: 12, marginRight: 10 }}>{basicInfo?.firstName}</Text>
            <Text style={{ fontSize: 12, marginRight: 2 }}>{basicInfo?.lastName}</Text>
          </View> */}

          <View style={styles.table} wrap={false}>
            {data?.breakfasts?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.breakfasts?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <View style={{ ...styles.tableCell, fontSize: 15 }}>
                    <Text>صبحانه</Text>
                  </View>
                </View>
                <View style={styles.tableCol2}>
                  {data?.breakfasts?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " - "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}
            {data?.lunches?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.lunches?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>ناهار</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.lunches?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " - "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}

          </View>
          <View style={styles.table} wrap={false}>
            {data?.snackBeforeLunches?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.snackBeforeLunches?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>میان وعده</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.snackBeforeLunches?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " - "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}
            {data?.dinners?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.dinners?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>شام</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.dinners?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " - "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}
          </View>
        </Page>
      </Document>
    );
  } catch (error) {
    console.error("خطا در تولید PDF:", error);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>خطایی رخ داده است. لطفاً داده‌ها را بررسی کنید.</Text>
        </Page>
      </Document>
    );
  }
};

export default PDFTable;
