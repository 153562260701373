import { svgFileUrl } from "../../../../../config";
import { useState } from "react";
import { Modal } from "../../../../../components/common";
import DetailModal from '../Detail_Modal'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [modalOpen, setModalOpen] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  /*  useEffect(() => {
    if (!detailShow) {
      setDetail2Show(false);
    }
  }, [detailShow]); */
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-1 px-2 py-4 mt-[12px] border border-[#E8E7EB] h-auto   items-center rounded-[12px] cursor-pointer font-normal transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section className="flex justify-center  items-center gap-2">
          <button className="px-2 text-purple font-semibold py-2 rounded-full bg-[whitesmoke] flex justify-center gap-1 items-center" onClick={() => setModalOpen(true)}>
            <span> نمایش جزییات</span>
            <img className="w-[20px] h-[20px]" src={svgFileUrl + "detail.svg"} alt="" />
          </button>
        </section>
      </section>
      {/*  {detailShow && (
        <>
          <section className="w-full bg-slate-100 min-h-[70px] rounded-lg">
            <section className="grid grid-cols-5 py-2 text-xs">
              <section className="w-full font-bold">شناسه تمرین</section>
              <section className="w-full font-bold">سیستم تمرینی</section>
              <section className="w-full font-bold">نام تمرین</section>
              <section className="w-full font-bold">نام عضله</section>
              <section className="w-full font-bold">جزییات</section>
            </section>
            {rowData?.personalSportProgramDetails?.map((item, index) => (
              <Fragment key={index}>
                <Level2Dtl item={item} />
              </Fragment>
            ))}
          </section>
        </>
      )} */}
      <Modal width={1200} title="جزییات" open={modalOpen} onClose={() => setModalOpen(false)}>
        <DetailModal rowData={rowData} />
      </Modal>
    </>
  );
}
