import { imagesFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%] group mx-auto mb-[50px] xl:mb-[150px] h-auto mt-[20px]">
      <section className="grid xl:grid-cols-2  mt-[40px] w-full  xl:w-[1128px]  mx-auto gap-6">
        <Item id={100} tags={["فیتنس", "بدنسازی", "موبیلیتی", "تمرین در منزل", "تمرینات چربی سوزی", "ارزیابی"]} src={imagesFileUrl + "coach/zivari-p.png"} price="1,200,000" title="مربی آقای  محسن زیوری" backGround="bg-white" />
        <Item id={17} tags={["فیتنس", "بدنسازی", "پرورش اندام مسابقات", "ارزیابی"]} src={imagesFileUrl + "coach/bassami-p.png"} price="1,200,000" title="مربی آقای ایوب بسامی" backGround="bg-white" />
        <Item id={18} tags={["فیتنس", "بدنسازی", "موبیلیتی", "تمرین در منزل", "تمرینات چربی سوزی", "ارزیابی"]} src={imagesFileUrl + "coach/tarverdi-p.png"} price="1,200,000" title="مربی خانم نگار تارویردی " backGround="bg-white" />
        <Item id={1} tags={["فیتنس", "بدنسازی", "موبیلیتی", "تمرین در منزل", "تمرینات چربی سوزی", "ارزیابی"]} src={imagesFileUrl + "coach/nazari-p.jpg"} price="1,200,000" title="مربی خانم مهدیه نظری" backGround="bg-white" />
        {/*         <Item id={19} tags={["فیتنس", "بدنسازی", "موبیلیتی", "تمرین در منزل", "کراسفیت", "ارزیابی"]} src={imagesFileUrl + "coach/mohsenzadeh-p.png"} price="1,200,000" title="مربی آقای مهدی محسن زاده" backGround="bg-white" />*/}        <Item id={20} tags={["فیتنس", "بدنسازی", "ارزیابی"]} src={imagesFileUrl + "coach/karimi-p.png"} price="1,200,000" title="مربی آقای محمد کریمی زند " />
        <Item id={21} tags={["فیتنس", "بدنسازی", "موبیلیتی", "تمرین در منزل", "ارزیابی"]} src={imagesFileUrl + "coach/hosseini-p.png"} price="1,200,000" title="مربی خانم بیتا حسینی" />
        <Item id={22} tags={["فیتنس", "بدنسازی", "بدنسازی فوتبال", "ارزیابی"]} src={imagesFileUrl + "coach/naderi-p.png"} price="1,200,000" title="مربی آقای علی نادری" backGround="bg-white" />
      </section>
    </section>
  );
}
