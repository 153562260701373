import { useState } from "react";
import { baseFileUrl } from "../../../../../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ item }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [detail2Show, setDetail2Show] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-5 py-2 font-semibold text-xs text-purple text-center">
        <span>{item.id}</span>
        <span>{item.trainingSystem}</span>
        <span>{item.exerciseName}</span>
        <span>{item.muscleName}</span>
        <button onClick={() => setDetail2Show(!detail2Show)}>
          <img className={`w-[20px] m-auto ${!detail2Show ? "rotate-90" : "rotate-[-90deg]"}`} src={baseFileUrl + "/arrowRight.png"} alt="" />
        </button>
      </section>
      {detail2Show && (
        <section className="w-full bg-slate-100 min-h-[100px] rounded-lg text-center">
          <section className="grid grid-cols-5 py-2 text-xs">
            <span>تعداد تکرار</span>
            <span>استراحت</span>
            <span>tempo</span>
            <span>توان به درصد</span>
            <span>وزن به کیلوگرم</span>
          </section>
          {item?.sets?.map((item, index) => (
            <section key={index} className="grid grid-cols-5 py-2 font-semibold text-xs text-purple">
              <span>{item.setRepeat}</span>
              <span>{item.rest}</span>
              <span>{item.tempo}</span>
              <span>{item.tavan}</span>
              <span>{item.weight}</span>
            </section>
          ))}
          <hr />
          <section className="flex justify-start px-[10px] mt-2 text-purple">
            <span>توضیحات:</span>
            <span className="font-semibold mr-1">{item?.description}</span>
          </section>
        </section>
      )}
    </>
  );
}
