import { Fragment } from "react";
import { ComponentLoading, NoRecord } from "../../../../../components/common";
import TableRow from "../TableRow";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ loading, data, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full m-auto mt-10 text-center text-[14px] relative">
      <section className="grid grid-cols-4 xl:grid-cols-4 gap-1">
        <section className="w-full">شناسه</section>
        <section className="w-full">نام پکیچ</section>
        {/*  <section className="w-full">وعده غذایی</section> */}
        <section className="w-full">توضیحات</section>
        <section className="w-full">جزییات</section>
      </section>
      <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
        <ComponentLoading loading={loading} />
        {data?.length > 0 ? (
          data?.map((item, index) => (
            <Fragment key={index}>
              <TableRow reloadTable={reloadTable} rowData={item?.foodProgramPackages[0]} />
            </Fragment>
          ))
        ) : (
          <NoRecord />
        )}
      </section>
    </section>
  );
}
