import { Fragment } from "react";
import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../../../config";
import { Link } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ src, title, price, btnColor, id, tags }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`group/box cursor-pointer relative transition-all hover:shadow-lg hover:shadow-[#DDCEEE] hover:translate-y-[-10px]  border-[#A6A9BD4D] w-full items-center justify-between border h-auto p-4 xl:min-h-[270px] rounded-[20px] border-solid`}>
        <section className="flex justify-center flex-wrap items-center">
          <section className="w-[190px] h-[236px] bg-silver rounded-[14px] overflow-hidden relative">
            <img className="absolute top-[10px] right-[10px]" src={imagesFileUrl + "icons/vip-badge.png"} alt="" />
            <img src={src} alt="" className={`xl:w-[190px]  w-full rounded-lg`} />
          </section>
          <section className="relative">
            <section className="xl:w-[307px] w-full flex flex-col gap-5 pr-5">
              <section className="flex justify-between items-center">
                <h3 className={` leading-[34.1px]  text-[18px] font-bold`}>{title}</h3>
                <img src={baseFileUrl + "/Rate-section3.png"} alt="" className="w-[49px] " />
              </section>
              {/* <p className="text-[#3E4151] text-sm">{text}</p> */}
              {/*      <section className="p-3 pr-4 h-[200px] flex items-center">
                <ul className="list-disc ">
                  {details?.map((item, index) => (
                    <li key={index} className="text-[12px] text-justify">
                      {item}
                    </li>
                  ))}
                </ul>
              </section> */}
              <section className="flex items-start h-[120px] flex-wrap gap-[6px]">
                {tags &&
                  tags.map((item, index) => (
                    <Fragment key={index}>
                      <span className={`text-[#5521B4] bg-[#F9F5FE]  px-3 py-2 font-bold text-sm rounded-[150px]`}>{item}</span>
                    </Fragment>
                  ))}
              </section>
              <section className="flex  justify-between items-center gap-[10px]">
                <section className="w-[80px]">
                  <section className="flex justify-center items-center gap-1">
                    <img alt="" className="w-[17px] h-[17px]" src={svgFileUrl + "timer1.svg"} />
                    <span> 3 روزه </span>
                  </section>
                </section>
                {/*  <section className="xl:group-hover/box:hidden">
                  <span className="text-[24px] font-semibold">{price}</span>
                  <span className="text-[14px] font-bold mr-2">تومان</span>
                </section> */}
                <Link to={`/coach-profile/${id ? id : 1}`} className={`flex  rounded-full text-[14px]  h-[30px] transition-all w-[210px] hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-4px] justify-center items-center border bg-[${btnColor ? btnColor : "#5521B4"}] border-purple text-white`}>
                  مشاهده بیشتر و دریافت برنامه
                </Link>
              </section>
              {/* <Link to={`/coach-profile/${id ? id : 1}`} className={`flex xl:hidden rounded-full text-[14px] w-full h-[30px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-4px] justify-center items-center border bg-[${btnColor ? btnColor : "#5521B4"}] border-purple text-white`}>
                مشاهده بیشتر و دریافت برنامه
              </Link> */}
            </section>
          </section>
        </section>
      </section>
    </>
  );
}
