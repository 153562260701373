import { baseFileUrl } from "../../../../config";
import { Button } from "../../../../components/common";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { NotifyMessage, Regex } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import api from "../../../../api";
import { notify } from "../../../../helper";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const onSubmit = (data) => {
    let params = {
      firstName: "خدمات سازمانی",
      lastName: "خدمات سازمانی",
      mobileNumber: data.phone,
      email: "خدمات سازمانی",
      description: "خدمات سازمانی",
    };
    /* console.log(params); */
    setLoading(true);
    useAxios
      .post(api.contactUs.addContactUs, params)
      .then((res) => {
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-auto  relative rounded-[30px] overflow-hidden  bg-[linear-gradient(324.74deg,_#5521B4_52.75%,_#9721B4_90.88%),linear-gradient(0deg,_rgba(0,_24,_97,_0.4),_rgba(0,_24,_97,_0.4))]  mt-[120px]">
      <img src={baseFileUrl + "/images/heroCor1.png"} className=" w-full h-full  hidden md:block absolute" alt="" />
      <section className="md:px-[90px] z-10 relative w-[90%] md:w-full mx-auto flex justify-between py-6 md:py-[42px]">
        <section className="md:py-[20px] text-white md:w-[476px]">
          <h2 className="md:text-[40px] text-3xl flex-col flex  font-bold md:leading-[52px]">
            <span className="md:text-[32px] text-xl font-bold md:leading-[49.6px] ">خدمات سازمانی</span>
            دستگاه آنالیز بدن
          </h2>
          <p className="leading-[30px]  mt-6 text-sm md:text-[14px] md:mt-0 font-light text-justify">
            مجموعه بادینس با افتخار آماده همکاری با سازمان شما در جهت ارتقاء سلامت و بهره‌وری کارکنان است. خدمات ما شامل آنالیز ترکیبات بدن با دستگاه بادی آنالیز، مشاوره تخصصی تغذیه و ورزش، ارزیابی ناهنجاری‌های قامتی، و طراحی برنامه‌های غذایی و ورزشی شخصی‌سازی‌شده می‌باشد. هدف این همکاری، بهبود کیفیت زندگی و افزایش کارایی کارکنان سازمان شما است. ما این خدمات را با تمرکز بر نیازهای خاص هر فرد ارائه می‌دهیم. مجموعه بادینس فرصتی ارزشمند برای ترویج فرهنگ سلامت در میان اعضای سازمان شما فراهم می‌کند.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="w-full h-auto md:h-[68px] flex flex-col md:flex-row gap-2 text-xs md:text-base  rounded-[30px] md:rounded-[170px] mt-8 md:mt-12 bg-[#F1E8FC] p-[12px]">
            <input
              {...register("phone", {
                required: "فیلد تلفن نباید خالی باشد",
                pattern: {
                  value: Regex.MOBILE,
                  message: "لطفا شماره موبایل را به درستی وارد کنید",
                },
              })}
              type="text"
              placeholder="شماره موبایل خود را وارد کنید"
              className=" h-[40px] xl:h-[48px] rounded-full w-full border border-solid border-[#8B929A36] px-8 text-sm text-black"
            />
            {/* <TextBox
              borderRadius="rounded-full"
              placeholder="شماره موبایل خود را وارد کنید"
              className=" "
            /> */}
            <Button type="submit" loading={loading} height="md:h-[44px] h-[38px] " padding="px-0" className="rounded-full w-[120px]  md:w-[200px]  text-xs md:text-sm  ">
              ارسال درخواست
            </Button>
          </form>
        </section>
      </section>
    </section>
  );
}
