import PrescriptionList from "./components/PrescriptionList";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
import { useState } from "react";
import { Button, TextBox } from "../../../../../components/common";
import { svgFileUrl } from "../../../../../config";
import { useSearchParams } from "react-router-dom";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { notify } from "../../../../../helper";
export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [versionList, setVersionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const addVersion = () => {
    setVersionList((prev) => [
      ...prev,
      {
        /*   requestId: parseInt(searchParams.get("userId") || "0"),
        username: searchParams.get("mobileNumber"), */
        drugName: "",
        drugType: "",
        count: "",
        description: "",
      },
    ]);
  };
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const Reload = () => {
    setReload(!reload);
  };
  const changeVersionList = (index, objTitle, value) => {
    let arr = [...versionList];
    arr[index][objTitle] = value;
    setVersionList(arr);
  };
  const saveVersion = () => {
    let params = [
      {
        requestId: parseInt(searchParams.get("userId")),
        username: searchParams.get("mobileNumber"),
        /*  signature: "ljadsljadsljk", */
        firstName: data?.userOutputDTO?.firstName,
        lastName: data?.userOutputDTO?.lastName,
        medicalPrescriptionDetails: [...versionList],
      },
    ];
    setLoading(true);
    useAxiosWithToken
      .post(api.doctor.addMedicalPrescription, params)

      .then((res) => {
        setLoading(false);
        setVersionList([]);
        Reload();
        notify.Success("نسخه با موفقیت افزوده شد");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" h-auto mt-[40px] py-8 px-4 xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
      <section className="flex">
        <Button onClick={addVersion} className="bg-purple xl:px-0 px-3 mr-auto text-white xl:w-[200px] h-[40px] mb-5 xl:mb-0 rounded-full text-sm flex justify-center gap-1 items-center">
          <img src={svgFileUrl + "plus-white.svg"} alt="" className="w-4 h-4 xl:w-6 xl:h-6" />
          افزودن دارو
        </Button>
      </section>
      <section>
        <section className="flex gap-2 text-sm xl:text-base items-center bg-purple mr-3 w-[70%] xl:w-[300px] rounded-tl-full text-white p-2 font-semibold">
          <span>نسخه</span>
          <span>{data?.userOutputDTO?.gender === "MALE" ? "آقای" : "خانم"}</span>
          <span>{data?.userOutputDTO?.firstName + " " + data?.userOutputDTO?.lastName}</span>
        </section>
        <section className="border border-slate-200 flex flex-col justify-center items-center min-h-[100px] rounded-md">
          {versionList.length === 0 && <h3 className="text-[22px] text-gray">با زدن دکمه افزودن دارو، داروی خود را به نسخه اضافه کنید</h3>}
          {versionList.map((item, index) => (
            <section>
              <section className="grid grid-cols-3 gap-4 xl:grid-cols-5 text-sm xl:text-base p-3">
                <section>
                  <span>شکل دارو:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "drugType", e.target.value)} alt="" />
                </section>
                <section>
                  <span>نام دارو:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "drugName", e.target.value)} alt="" />
                </section>
                <section>
                  <span>تعداد :</span>
                  <TextBox onChange={(e) => changeVersionList(index, "count", e.target.value)} alt="" />
                </section>
                <section className="xl:col-span-2 col-span-3">
                  <span>توضیحات:</span>
                  <TextBox onChange={(e) => changeVersionList(index, "description", e.target.value)} alt="" />
                </section>
              </section>
            </section>
          ))}
        </section>
      </section>

      {versionList.length > 0 && (
        <section className="flex mt-10">
          <Button loading={loading} onClick={saveVersion} className="bg-purple xl:px-0 px-3 mr-auto text-white xl:w-[200px] h-[40px]   rounded-full text-sm flex justify-center gap-1 items-center">
            ثبت اولیه نسخه
          </Button>
        </section>
      )}
      <PrescriptionList reloadTable={Reload} reload={reload} />
    </section>
  );
}
