import Button from "../../../../components/common/Button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { NotifyMessage, Regex } from "../../../../enums";
import ErrorMessage from "../../../../components/common/Message/ErrorMessage";
import { imagesFileUrl } from "../../../../config";
import { useAxios } from "../../../../hooks";
import api from "../../../../api";
import { notify } from "../../../../helper";

/* import { notify } from "../../../../helper"; */
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const onSubmit = (data) => {
    let params = {
      firstName: data.name,
      lastName: data.family,
      mobileNumber: data.phone,
      email: data.email,
      description: data.description,
    };
    console.log(params);
    setLoading(true);
    useAxios
      .post(api.contactUs.addContactUs, params)
      .then((res) => {
        setLoading(false);
        reset();

        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="z-[2000]  mx-auto mt-11 flex flex-col xl:grid  h-auto w-[90%] xl:w-[860px] xl:grid-cols-2 items-start gap-2 xl:gap-4 rounded-xl bg-white p-4  xl:p-8 shadow-[0px_4px_8px_0px_#0000000F,0px_0px_4px_0px_#0000000A]">
        <section className="relative w-full">
          <label className="font-bold text-sm xl:text-base text-[#A6A9BD]"> نام:</label>
          <input {...register("name", { required: "فیلد نام نباید خالی باشد" })} type="text" placeholder="نام" className="mt-[5px]  h-[40px] xl:h-[48px] w-full rounded-lg border border-solid border-[#8B929A36] px-8 text-sm" />
          <img alt="" src={imagesFileUrl + "/icons/UserRoundedComment.svg"} width={24} height={24} className="absolute right-2 h-5 w-5 xl:h-6 xl:w-6 top-[40px]" />
          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </section>

        <section className="relative w-full">
          <label className="font-bold text-sm xl:text-base text-[#A6A9BD]">نام خانوادگی:</label>
          <input
            {...register("family", {
              required: "فیلد نام خانوادگی نباید خالی باشد",
            })}
            type="text"
            placeholder="نام خانوادگی"
            className="mt-[5px] h-[40px] xl:h-[48px] w-full rounded-lg border border-solid border-[#8B929A36] px-8 text-sm"
          />
          <img alt="" src={imagesFileUrl + "/icons/UserRoundedComment.svg"} width={24} height={24} className="absolute right-2 top-[40px] h-5 w-5 xl:h-6 xl:w-6" />
          {errors.family && <ErrorMessage>{errors.family.message}</ErrorMessage>}
        </section>

        <section className="relative w-full">
          <label className="font-bold text-sm xl:text-base text-[#A6A9BD]">تلفن:</label>
          <input
            {...register("phone", {
              required: "فیلد تلفن نباید خالی باشد",
              pattern: {
                value: Regex.MOBILE,
                message: "لطفا شماره موبایل را به درستی وارد کنید",
              },
            })}
            type="text"
            placeholder="تلفن"
            className="mt-[5px] h-[40px] xl:h-[48px] w-full rounded-lg border border-solid border-[#8B929A36] px-8 text-sm"
          />
          <img alt="" src={imagesFileUrl + "/icons/PhoneCallingAbout.svg"} width={24} height={24} className="absolute h-5 w-5 xl:h-6 xl:w-6 right-2 top-[40px]" />
          {errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
        </section>

        <section className="relative w-full">
          <label className="font-bold text-sm xl:text-base text-[#A6A9BD]">ایمیل:</label>
          <input
            {...register("email", {
              required: "فیلد ایمیل نباید خالی باشد",
              pattern: {
                value: Regex.EMAIL,
                message: "لطفا ایمیل را به درستی وارد کنید",
              },
            })}
            type="text"
            placeholder="ایمیل"
            className="mt-[5px] h-[40px] xl:h-[48px] w-full rounded-lg border border-solid border-[#8B929A36] px-[35px] text-sm"
          />
          <img alt="" src={imagesFileUrl + "/icons/Lettercomment.svg"} width={24} height={24} className="absolute right-2 h-5 w-5 xl:h-6 xl:w-6 top-[40px]" />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </section>
        <section className="relative w-full xl:col-span-2">
          <label className="font-bold text-sm xl:text-base text-[#A6A9BD]">توضیحات:</label>
          <textarea
            {...register("description", {
              required: "فیلد توضیحات نباید خالی باشد",
              maxLength: {
                value: 10,
                message: "توضیحات نباید بیشتر از 400 کاراکتر باشد",
              },
            })}
            placeholder="توضیحات"
            className="mt-[5px]  h-[78px] w-full rounded-lg border border-solid border-[#8B929A36] px-2 py-2 text-sm"
          />
          {errors.description && <ErrorMessage>{errors.description.message}</ErrorMessage>}
        </section>

        <section className="col-span-2 flex w-full justify-end ">
          <Button type="submit" loading={loading} className="h-[40px] transition-all hover:shadow-lg hover:shadow-[#A6A9BD] hover:translate-y-[-5px] xl:h-[48px] w-full xl:w-[200px]  rounded-lg bg-purple font-bold text-white">
            ثبت
          </Button>
        </section>
      </form>
    </>
  );
}
