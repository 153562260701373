//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ item }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-3 xl:grid-cols-3 py-2 font-semibold text-xs text-purple">
        <span>{item.id}</span>
        <span>{item.mainFoodDescription}</span>
        <span>
          {Math.trunc(item.consumedGram)} {item.consumedMeasureFa}
        </span>
        {/*  <span>{Math.trunc(item.energyKcal)}</span>
        <span>{Math.trunc(item.proteinG)}</span>
        <span>{Math.trunc(item.totalFatG)}</span>
        <span>{Math.trunc(item.carbohydrateG)}</span> */}
      </section>
    </>
  );
}
