import { Link } from "react-router-dom";
import { svgFileUrl } from "../../../../../../config";
import MenuWrapper from "./components/MenuWrapper";
import { useEffect, useState } from "react";
import { useRouteListener } from "../../../../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [routeStatus] = useRouteListener();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [open, setOpen] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setOpen(false);
  }, [routeStatus]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <MenuWrapper open={open} setOpen={setOpen} />
      <section className="fixed left-0 bottom-0 w-full h-[64px] bg-white shadow-[0px_-1px_9px_0px_#5408A926] rounded-t-[20px] z-[1000] px-[10%] flex justify-between xl:hidden ">
        <Link to="/dashboard" className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuPeople.svg"} alt="" className="w-[18px] h-[18px]" />
          داشبورد
        </Link>
        <Link to={"/dashboard/basicInfo"} className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuTarget.svg"} alt="" className="w-[18px] h-[18px]" />
          هدف
        </Link>
        <button onClick={() => setOpen(true)} className="flex w-[56px] h-[56px] cursor-pointer  bg-[#5408A9] mt-[-18px] rounded-full shadow-[0px_14px_23px_-6px_#5408A980] justify-center items-center">
          <img src={svgFileUrl + "mobileMenu.svg"} alt="" className="w-[20px] h-[20px]" />
        </button>
        <Link to="/dashboard/exercise-list" className="flex flex-col justify-center cursor-pointer  items-center">
          <img src={svgFileUrl + "mobileMenuSport.svg"} alt="" className="w-[18px] h-[18px]" />
          ورزش
        </Link>
        <Link to="/dashboard/recipe" className="flex flex-col justify-center cursor-pointer items-center">
          <img src={svgFileUrl + "mobileMenuKnife.svg"} alt="" className="w-[18px] h-[18px]" />
          آشپزی
        </Link>
      </section>
    </>
  );
}
