import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import moment from "moment-jalaali";
Font.register({
  family: "iransans",
  src: "/fonts/vazir/Vazir.ttf",
});

const PDFTable = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "iransans",
      position: "relative",
    },
    watermarkImage: {
      width: "100%",
    },
    box: {
      position: "absolute",
      width: 350,
      height: 200,
      top: 70,
      right: 30,
    },
    drugItem: {
      fontSize: 8,
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      textAlign: "right",
      gap: 15,
    },
    userInfoBox: {
      position: "absolute",
      width: 175,
      height: 100,
      bottom: 90,
      left: 20,
      paddingRight: 10,
    },
    userInfo: {
      fontSize: 10,
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      textAlign: "right",
    },

    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ccc",
      borderRadius: 10,
      marginTop: 10,
    },
    tableRow: {
      flexDirection: "row-reverse",
    },
    tableCol: {
      width: "25%",
      padding: 5,
      textAlign: "center",
    },
    tableCell: {
      fontSize: 8,
      textAlign: "center",
      direction: "rtl",
    },
  });
  return (
    <Document>
      <Page break wrap={true} orientation="landscape" size="A5" style={styles.page}>
        <View style={styles.watermark}>
          <Image alt="" style={styles.watermarkImage} src="/images/prescription/imani.png" />
        </View>
        <View style={styles.box}>
          {/* {data?.medicalPrescriptionDetails?.map((item, index) => (
            <View style={styles.drugItem}>
              <Text>{item?.drugType}</Text>
              <Text>{item?.drugName}</Text>
              <Text>{item?.count}</Text>
              <Text>{item?.description}</Text>
            </View>
          ))} */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>نوع دارو</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>نام دارو</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>تعداد</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>توضیحات</Text>
              </View>
            </View>
            {data?.medicalPrescriptionDetails?.map((row, idx) => (
              <View style={styles.tableRow} key={idx}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.drugType}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.drugName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.count}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.description}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.userInfoBox}>
          <Text style={styles.userInfo}>
            نام و نام خانوادگی: {data?.firstName} {data?.lastName}
          </Text>
          <Text style={styles.userInfo}>تاریخ: {moment(data?.createdOn).format("jYYYY/jMM/jDD")}</Text>
        </View>

        {/*   {data?.map((item, index) => (
          <View style={styles.wrapper} wrap={false} key={index}>
            <Text style={styles.title}>تمرین {index + 1}</Text>
            <View style={styles.box}>
              <View style={styles.header}>
                <View style={styles.headerBox}>
                  <Text style={styles.headerTitle}>سیستم تمرینی</Text>
                  <Text style={styles.headerValue}>{item?.trainingSystem}</Text>
                </View>
                <View style={styles.headerBox}>
                  <Text style={styles.headerTitle}>نام حرکت</Text>
                  <Text style={styles.headerValue}>{item?.exerciseName}</Text>
                </View>
                <View style={styles.headerBox}>
                  <Text style={styles.headerTitle}>تعداد ست</Text>
                  <Text style={styles.headerValue}>{item?.setCount}</Text>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>ست ها</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>تعداد تکرار</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>تکرار دستی</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>استراحت</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>rir</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>tempo</Text>
                  </View>
                </View>
                {item?.sets?.map((row, idx) => (
                  <View style={styles.tableRow} key={idx}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ست {row?.id}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row?.setRepeat}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row?.repeatHandle}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row?.rest} ثانیه</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row?.rir}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row?.tempo}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.descBox}>
                  <Text style={{ fontSize: 10 }}>:توان به درصد</Text>
                  <Text style={{ fontSize: 10 }}>{item?.tavan}</Text>
                </View>
                <View style={styles.descBox}>
                  <Text style={{ fontSize: 10 }}>:وزن به کیلوگرم</Text>
                  <Text style={{ fontSize: 10 }}>{item?.weight}</Text>
                </View>
                <View style={styles.descBox}>
                  <Text style={{ fontSize: 10 }}>:توضیحات</Text>
                  <Text style={{ fontSize: 10 }}>{item?.description}</Text>
                </View>
              </View>
            </View>
          </View>
        ))} */}
      </Page>
    </Document>
  );
};

export default PDFTable;
