import { baseFileUrl, imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[564px] bg-white mt-8 rounded-2xl flex flex-col justify-center items-center">
      <section className="w-[283px] h-[148px] rounded-[10px] overflow-hidden relative">
        <img src={baseFileUrl + "/images/dashboard/vid1.png"} alt="" />
        <section className="py-2 px-3 w-full h-full flex flex-col justify-center items-center top-0 absolute">
          <section className="flex self-start justify-between items-center  w-[45px] h-[15px]">
            <img src={imagesFileUrl + "dashboard/clock.png"} className="w-[12px] h-[13px]" alt="" />
            <span className="block h-[12px] text-white text-xs font-semibold">2:00</span>
          </section>
          <section className="font-bold text-lg text-white mt-4 mb-4 text-center">آیا لاغری با ذهن امکان‌پذیر است؟ با ماه همراه باشید</section>
          <button className="w-[86px] h-[24px] rounded-full bg-[#0000004D] text-white text-xs font-normal self-end">کالری نامرعی</button>
        </section>
      </section>
      <section className="w-[283px] h-[148px] rounded-[10px] mt-3  overflow-hidden relative">
        <img src={baseFileUrl + "/images/dashboard/vid2.png"} alt="" />
        <section className="py-2 px-3 w-full h-full flex flex-col justify-center items-center top-0 absolute">
          <section className="w-[56px] h-[56px] bg-white opacity-80 rounded-full shadow-md flex justify-center items-center">
            <section className="bg-purple w-[43px] h-[43px] rounded-full flex justify-center items-center">
              <img src={imagesFileUrl + "dashboard/play.svg"} alt="" />
            </section>
          </section>
        </section>
      </section>
      <section className="w-[283px] h-[148px] rounded-[10px] mt-3  overflow-hidden relative">
        <img src={baseFileUrl + "/images/dashboard/vid3.png"} alt="" />
        <section className="py-2 px-3 w-full h-full flex flex-col justify-center items-center top-0 absolute">
          <section className="flex self-start justify-between items-center  w-[45px] h-[15px]">
            <img src={imagesFileUrl + "dashboard/clock.png"} className="w-[12px] h-[13px]" alt="" />
            <span className="block h-[12px] text-white text-xs font-semibold">2:00</span>
          </section>
          <section className="font-bold h-[55px] flex justify-center items-center text-lg text-white mt-4 mb-4 text-center">آیا لاغری با ذهن امکان‌پذیر است؟</section>
          <button className="w-[86px] h-[24px] rounded-full bg-[#0000004D] text-white text-xs font-normal self-end">کالری نامرعی</button>
        </section>
      </section>
      <button className="w-[149px] h-[40px] bg-purple rounded-full text-white mt-4">به زودی</button>
    </section>
  );
}
