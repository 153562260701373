import { baseFileUrl } from "../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const list = ["اندازه‌گیری تفکیکی توده چربی و بدون چربی", "اندازه گیری و آنالیز دقیق آب  بدن", "اندازه‌گیری دقیق ترکیب بدنی", "ثبت تاریخچه اطلاعات", "پروفایل اختصاصی", "شخصی‌سازی برنامه‌های تمرینی و تغذیه‌ای"];
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="relative h-auto md:h-[510px] md:mb-[150px] ">
      <img style={{ transform: "rotateY(180deg)" }} src={baseFileUrl + "/images/heroCor.png"} className=" w-full h-full -z-10  absolute " alt="" />
      <section className="md:px-[90px] flex flex-col md:flex-row justify-between w-[90%] mx-auto py-8 md:py-[42px]">
        <img style={{ transform: "rotateY(180deg)" }} src={baseFileUrl + "/images/picCor.png"} className=" md:w-auto w-[50%] mx-auto md:h-[425px] mt-8 md:mt-0 order-last md:order-first " alt="" />
        <div className="md:w-[421px]">
          <h2 className="md:text-[40px] text-3xl flex-col flex  font-bold md:leading-[52px]">
            <span className="md:text-[32px] text-2xl mb-1 font-bold md:leading-[49.6px] text-purple">ویژگی‌های</span>
            دستگاه آنالیز بدن
          </h2>
          <ul className="flex flex-col text-xs md:text-base font-medium gap-3 md:gap-[20px] mt-6 md:mt-10">
            {list.map((item, index) => (
              <li className="flex gap-3 items-center">
                <img src={baseFileUrl + "/images/icons/VectorList.svg"} className=" md:w-[10px] w-2 md:h-auto" alt="" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </section>
    </section>
  );
}
