import { useState, useEffect, Fragment } from "react";
import { ComponentLoading, Modal, NoRecord } from "../../../components/common";
import TableRow from "./components/TableRow";
import { useAxiosWithToken } from "../../../hooks";
import api from "../../../api";
import DetailModal from "./components/Detail_Modal";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [versionList, setVersionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getVersionList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.doctor.getMedicalPrescriptionList)
      .then((res) => {
        setLoading(false);
        setVersionList(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const showModal = (rowData) => {
    setRowData(rowData);
    setModalOpen(true);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getVersionList();
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] px-1 xl:px-[34px] py-[44px] shadow-md">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">نسخ پزشکی</span>
      </section>
      <section className="xl:w-[700px] m-auto mt-10 text-center text-[14px] relative">
        <section className="hidden xl:grid grid-cols-3 gap-1">
          <section className="w-full">شماره نسخه</section>
          <section className="w-full">تاریخ ثبت نسخه</section>
          <section className="w-full">جزییات نسخه</section>
        </section>
        <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
          <ComponentLoading loading={loading} />
          {versionList.length > 0 ? (
            versionList.map((item, index) => (
              <Fragment key={item?.id}>
                <TableRow showModal={showModal} rowData={item} />
              </Fragment>
            ))
          ) : (
            <NoRecord />
          )}
        </section>
      </section>
      <Modal width={1000} title="جزییات" open={modalOpen} onClose={() => setModalOpen(false)}>
        <DetailModal rowData={rowData} />
      </Modal>
    </section>
  );
}
