import React, { useState } from "react";
import { baseFileUrl } from "../../../../config";
import { Button } from "../../../../components/common";
import { useForm } from "react-hook-form";
import { useAxios } from "../../../../hooks";
import { notify } from "../../../../helper";
import api from "../../../../api";
import { NotifyMessage, Regex } from "../../../../enums";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const onSubmit = (data) => {
    let params = {
      firstName: "خدمات سازمانی",
      lastName: "خدمات سازمانی",
      mobileNumber: data.phone,
      email: "خدمات سازمانی",
      description: "خدمات سازمانی",
    };
    /* console.log(params); */
    setLoading(true);
    useAxios
      .post(api.contactUs.addContactUs, params)
      .then((res) => {
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="relative rounded-xl  bg-[linear-gradient(109.14deg,_#F02484_-35.6%,_#5408A9_50.56%,_#4CC9EC_144.09%)] md:bg-none ">
      <section className=" flex w-[90%] mx-auto py-10 justify-between items-center ">
        <div className=" md:w-[498px] w-full md:mx-[70px] flex flex-col items-center">
          <h2 className="md:text-[27px] text-xl text-white  md:leading-[49.6px]">
            <span className="font-bold">برای دریافت اطلاعات بیشتر و بهره مندی از مشاوره رایگان درخواست خود را ثبت کنید.</span>
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full h-auto md:h-[68px] flex flex-col md:flex-row gap-2 text-xs md:text-base  rounded-[30px] md:rounded-[170px] mt-8 md:mt-12 bg-[#F1E8FC] p-[12px]">
            <input
              {...register("phone", {
                required: "فیلد تلفن نباید خالی باشد",
                pattern: {
                  value: Regex.MOBILE,
                  message: "لطفا شماره موبایل را به درستی وارد کنید",
                },
              })}
              type="text"
              placeholder="شماره موبایل خود را وارد کنید"
              className=" h-[40px] xl:h-[48px] rounded-full w-full border border-solid border-[#8B929A36] px-8 text-sm text-black"
            />
            <Button type="submit" loading={loading} height="md:h-[44px] h-[38px] " padding="px-0" className="rounded-full w-[120px]  md:w-[200px]  text-xs md:text-sm  ">
              ارسال درخواست
            </Button>
          </form>
        </div>

        <img src={baseFileUrl + "/images/sectionnew.png"} className=" w-full left-0 md:block hidden absolute -z-10 " alt="" />
      </section>
    </section>
  );
}
