//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ children }) => {
  return <span className="bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center">{children}</span>;
};
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="rounded-[10px] border border-[#E6E8E9] p-[20px]  mt-[32px]">
      <h2 className="text-[18px] font-bold ">معرفی</h2>
      <section className="flex flex-wrap gap-[12px] mt-[32px]">
        <Item> فیتنس</Item>
        <Item> بدنسازی</Item>
        <Item>موبیلیتی</Item>
        <Item>تغذیه ورزشی</Item>
        <Item>ارزیابی</Item>
      </section>
    </section>
  );
}
