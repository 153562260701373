import toast from "react-hot-toast";
import CryptoJS from "crypto-js";
export const mobile_Regex = new RegExp(/^[0][9][0-9][0-9]{8}$/);
export const isValue = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return true;
  } else {
    return false;
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
export const scrollTo = (top) => {
  window.scrollTo({ top: top, left: 0, behavior: "smooth" });
};
export const calcBMI = (height, weight) => {
  let hei = height / 100;
  return Math.floor(weight / (hei * hei));
};
export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const numberWithCommas = (num) => {
  if (isValue(num)) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num;
  }
};
export const correctPersianDate = (value) => {
  let part1 = value.substring(0, 4);
  let part2 = value.substring(4, 6);
  let part3 = value.substring(6, 8);
  return part1 + "/" + part2 + "/" + part3;
};
export const objectToQueryString = (obj) => {
  const filteredObj = Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null && value !== undefined && value !== ""));
  const params = new URLSearchParams(filteredObj);
  return params.toString();
};
const secretKey = "ad682309412"; // کلید محرمانه (این کلید را در کدهای عمومی قرار ندهید)
export const encrypt = (role) => {
  return CryptoJS.AES.encrypt(role, secretKey).toString(); // رمزگذاری داده
};
export const decrypt = (encryptedRole) => {
  const bytes = CryptoJS.AES.decrypt(encryptedRole, secretKey); // رمزگشایی داده
  return bytes.toString(CryptoJS.enc.Utf8);
};
export const notify = {
  Success: (text) =>
    toast.success(text, {
      style: {
        border: "1px solid #1CAE81",
        padding: "16px",
        color: "black",
        backgroundColor: "#DDF3EC",
      },
      iconTheme: {
        primary: "#1CAE81",
        secondary: "#FFFAEE",
      },
    }),
  Error: (text) =>
    toast.error(text, {
      style: {
        border: "1px solid red",
        padding: "16px",
        color: "black",
        backgroundColor: "#ffc7c7",
      },
      iconTheme: {
        primary: "red",
        secondary: "#ffc7c7",
      },
    }),
};
export const isAdmin = () => {
  let roles = decrypt(localStorage.getItem("ur")).split(",");
  if (roles.includes("admin")) {
    return true;
  } else {
    return false;
  }
};
export const isDoctor = () => {
  let roles = decrypt(localStorage.getItem("ur")).split(",");
  if (roles.includes("doctor")) {
    return true;
  } else {
    return false;
  }
};
export const isCoach = () => {
  let roles = decrypt(localStorage.getItem("ur")).split(",");
  if (roles.includes("coach")) {
    return true;
  } else {
    return false;
  }
};
export const isAdminDoctor = () => {
  let roles = decrypt(localStorage.getItem("ur")).split(",");
  if (roles.includes("admin_doctor")) {
    return true;
  } else {
    return false;
  }
};
export const isUser = () => {
  let roles = decrypt(localStorage.getItem("ur")).split(",");
  if (roles.includes("user")) {
    return true;
  } else {
    return false;
  }
};
export const getDateNumberPersian = (value) => {
  switch (value) {
    case "FIRST":
      return "اول";
    case "SECOND":
      return "دوم";
    case "THIRD":
      return "سوم";
    case "FOURTH":
      return "چهارم";
    case "FIFTH":
      return "پنجم";
    case "SIXTH":
      return "ششم";
    case "SEVENTH":
      return "هفتم";
    case "EIGHTH":
      return "هشتم";
    case "NINTH":
      return "نهم";
    case "TENTH":
      return "دهم";
    case "ELEVENTH":
      return "یازدهم";
    case "TWELFTH":
      return "دوازدهم";
    default:
    // code block
  }
};
export const hasValue = (value) => {
  if (value === "" || value === null || value === undefined) {
    return false;
  } else return true;
};

export const getPersianMealName = (value) => {
  let mealList = [
    {
      englishTitle: "BREAKFAST",
      persianTitle: "صبحانه",
    },
    {
      englishTitle: "LUNCH",
      persianTitle: "ناهار",
    },
    {
      englishTitle: "DINNER",
      persianTitle: "شام",
    },
    {
      englishTitle: "SNACK_BEFORE_LUNCH",
      persianTitle: "میان وعده",
    },
  ];
  return mealList.find((el) => el.englishTitle === value).persianTitle;
};
