import React from "react";
import { imagesFileUrl } from "../../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mb-[19.11px] flex justify-center items-center gap-[8.99px]">
        <span className="text-white bg-[#7639BA] rounded-full h-[29.98px] w-[29.98px]  flex justify-center items-center">2</span>
        <span className="text-[22px] font-bold">عضله رو برای تقویت انتخاب کن!</span>
      </section>
      <section className="home-slider-box relative w-[167px] h-[293px] bg-white rounded-[22px] flex justify-center items-center">
        <img alt="" className="w-[155px] h-[282px]" src={imagesFileUrl + "male-slider.png"} />
        <img alt="" className="slider-card" src={imagesFileUrl + "slider-card.png"} />
      </section>
      <section className="w-full text-center">
        <span className="font-bold text-[14px] text-center block mt-[29px]">ماهیچه‌هات رو تقویت کن</span>
        {/* <span className='text-[14px] font-normal'>
                    توضیحات تمرین و حرکات که باید چه کاری انجام بدهد
                </span> */}
      </section>
    </>
  );
}
