import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "../../_Dashboard/components/SideBar/svg/Dashboard";
import ExitIcon from "../../_Dashboard/components/SideBar/svg/Exit";
import { useAuthStore } from "../../../store/auth/auth";
import { useUserStatus } from "../../../hooks";
import { Button } from "../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  const deleteReturnUrl = useAuthStore((state) => state.deleteReturnUrl);
  const [userStatus] = useUserStatus();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [showMenu, setShowMenu] = useState(false);
  // ─── Refs ───────────────────────────────────────────────────────────────────────
  const menuBtnRef = useRef(null);
  const menuContainerRef = useRef(null);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  /*   const checkToken = () => {
    axios
      .get(api.user.getUserInfo, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {})
      .catch((e) => {
        if (e?.response?.status === 401) {
          localStorage.clear();
        
        }
      });
  }; */
  /*  const checkToken = useCallback(() => {
    axios
      .get(api.user.getUserInfo, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {})
      .catch((e) => {
        if (e?.response?.status === 401) {
          localStorage.clear();
        }
      });
  }, []); */
  const logOut = () => {
    localStorage.clear();
    deleteReturnUrl();
    navigation("/login");
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  const handleClickOutside = useCallback((event) => {
    if (menuBtnRef.current && !menuBtnRef.current.contains(event.target) && menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);
  /*  useEffect(() => {
    checkToken();
  }, []); */
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {userStatus === 1 ? (
        <Link to="/login" className="hidden xl:block">
          <Button light={true} className="rounded-full">
            ورود / ثبت نام
          </Button>
        </Link>
      ) : (
        <section
          className="relative border min-w-[190px] h-[48px]  justify-center items-center hidden xl:flex  rounded-full bg-[#ff008a] transition-all *:
      hover:bg-[#F02484] border-none  px-4  cursor-pointer"
          onClick={() => setShowMenu(!showMenu)}
        >
          {userStatus === 2 ? (
            <span ref={menuBtnRef} className="text-white ">
              {localStorage.mobileNumber} جان خوش اومدی!
            </span>
          ) : userStatus === 3 ? (
            <span ref={menuBtnRef} className="text-white">
              {localStorage.firstName} جان خوش اومدی!
            </span>
          ) : (
            ""
          )}
          <section ref={menuContainerRef} className={`  w-[100%] top-[60px] ${showMenu ? "block" : "hidden"} right-0 bg-white shadow-xl absolute rounded-md overflow-hidden`}>
            <ul>
              {userStatus === 3 && (
                <li onClick={() => navigation("/dashboard")} className="w-full h-10  flex items-center pr-2 font-semibold text-sm transition-all hover:bg-slate-200">
                  <DashboardIcon fill="#5606a8" />
                  <span className="mr-3">داشبورد</span>
                </li>
              )}
              {userStatus === 2 && (
                <li onClick={() => navigation("/basicInfo")} className="w-full h-10  flex items-center pr-2 font-semibold text-sm transition-all hover:bg-slate-200">
                  <DashboardIcon fill="#5606a8" />
                  <span className="mr-3">تکمیل اطلاعات کاربر</span>
                </li>
              )}

              <li onClick={logOut} className="w-full h-10  flex items-center pr-2 font-semibold text-sm transition-all hover:bg-slate-200">
                <ExitIcon fill="#5606a8" />
                <span className="mr-3">خروج</span>
              </li>
            </ul>
          </section>
        </section>
      )}
    </>
  );
}
