import { useState } from "react";
import { Button, Select } from "../../../../../../../../../components/common";
import { svgFileUrl } from "../../../../../../../../../config";
import { useAxiosWithToken } from "../../../../../../../../../hooks";
import api from "../../../../../../../../../api";
import { NotifyMessage } from "../../../../../../../../../enums";
import { notify } from "../../../../../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setFormData, exerciseId, setExerciseId, exerciseObj, createExercise, addExerciseLoading }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [removeLoading, setRemoveLoading] = useState(false)
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const rejectExercise = (id) => {
    setRemoveLoading(true);
    useAxiosWithToken
      .delete(api.coach.removeCoachExercise + exerciseId)
      .then((res) => {
        setRemoveLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setExerciseId(null)
      })
      .catch((err) => {
        setRemoveLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {!exerciseId && <>
        <section className="hidden xl:block">
          <section className="flex justify-between">
            <section className="flex justify-center items-center">
              <section className="flex items-center justify-between w-full h-[38px] mr-[24px] gap-2">
                <span className="block text-[#3E4151] W-[100px]">انتخاب ماه:</span>
                <button onClick={() => setFormData({ monthEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه اول
                </button>
                <button onClick={() => setFormData({ monthEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه دوم
                </button>
                <button onClick={() => setFormData({ monthEnum: "THIRD" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "THIRD" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه سوم
                </button>
                <button onClick={() => setFormData({ monthEnum: "FOURTH" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "FOURTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه چهارم
                </button>
                <button onClick={() => setFormData({ monthEnum: "FIFTH" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "FIFTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه پنجم
                </button>
                <button onClick={() => setFormData({ monthEnum: "SIXTH" })} className={`w-[92px] h-[38px] ${exerciseObj.monthEnum === "SIXTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  ماه ششم
                </button>
              </section>
            </section>
          </section>
          <section className="flex justify-between mt-[20px]">
            <section className="flex justify-center items-center">
              <section className="w-full h-[38px] mr-[24px] gap-2 flex items-center justify-between">
                <span className="block text-[#3E4151] W-[350px]">انتخاب هفته:</span>
                <button onClick={() => setFormData({ weekEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  هفته اول
                </button>
                <button onClick={() => setFormData({ weekEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  هفته دوم
                </button>
                <button onClick={() => setFormData({ weekEnum: "THIRD" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "THIRD" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  هفته سوم
                </button>
                <button onClick={() => setFormData({ weekEnum: "FOURTH" })} className={`w-[92px] h-[38px] ${exerciseObj.weekEnum === "FOURTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple  rounded-full`}>
                  هفته چهارم
                </button>
              </section>
            </section>
          </section>
          <section className="flex justify-between">
            <section className="flex justify-center items-center">
              <section className="w-full h-[38px] mt-[20px] mr-[24px] gap-2 flex items-center justify-between">
                <span className="block w-[90px] text-[#3E4151]">انتخاب روز:</span>
                <button onClick={() => setFormData({ dayEnum: "FIRST" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FIRST" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز اول
                </button>
                <button onClick={() => setFormData({ dayEnum: "SECOND" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SECOND" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز دوم
                </button>
                <button onClick={() => setFormData({ dayEnum: "THIRD" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "THIRD" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز سوم
                </button>
                <button onClick={() => setFormData({ dayEnum: "FOURTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FOURTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز چهارم
                </button>
                <button onClick={() => setFormData({ dayEnum: "FIFTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "FIFTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز پنجم
                </button>
                <button onClick={() => setFormData({ dayEnum: "SIXTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SIXTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز ششم
                </button>
                <button onClick={() => setFormData({ dayEnum: "SEVENTH" })} className={`w-[92px] h-[38px] ${exerciseObj.dayEnum === "SEVENTH" ? `bg-purple text-white` : `bg-white text-purple`} border border-purple rounded-full`}>
                  روز هفتم
                </button>
              </section>
            </section>
          </section>
        </section>
        {/* -------------Mobile------------------------------- */}
        <section className="grid mb-[20px] grid-cols-1 xl:hidden">
          <section>
            <label>انتخاب ماه</label>
            <Select
              setState={(e) => setFormData({ monthEnum: e.value })}
              /*   state={{ label: "ماه اول", value: "FIRST" }} */
              options={[
                { label: "ماه اول", value: "FIRST" },
                { label: "ماه دوم", value: "SECOND" },
                { label: "ماه سوم", value: "THIRD" },
                { label: "ماه چهارم", value: "FOURTH" },
                { label: "ماه پنجم", value: "FIFTH" },
                { label: "ماه ششم", value: "SIXTH" },
              ]}
            />
          </section>
          <section className="mt-2">
            <label>انتخاب هفته</label>
            <Select
              /* state={{ label: "هفته اول", value: "FIRST" }} */
              setState={(e) => setFormData({ weekEnum: e.value })}
              options={[
                { label: "هفته اول", value: "FIRST" },
                { label: "هفته دوم", value: "SECOND" },
                { label: "هفته سوم", value: "THIRD" },
                { label: "هفته چهارم", value: "FOURTH" },
              ]}
            />
          </section>
          <section className="mt-3">
            <label>انتخاب روز</label>
            <Select
              /*  state={{ label: "روز اول", value: "FIRST" }} */
              setState={(e) => setFormData({ dayEnum: e.value })}
              options={[
                { label: "روز اول", value: "FIRST" },
                { label: "روز دوم", value: "SECOND" },
                { label: "روز سوم", value: "THIRD" },
                { label: "روز چهارم", value: "FOURTH" },
                { label: "روز پنجم", value: "FIFTH" },
                { label: "روز ششم", value: "SIXTH" },
                { label: "روز هفتم", value: "SEVENTH" },
              ]}
            />
          </section>
        </section>
      </>}

      <section className="w-full flex justify-end ">
        <Button loading={addExerciseLoading} disabled={exerciseId} onClick={createExercise} className="  w-[200px] h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
          <img src={svgFileUrl + "plus-white.svg"} alt="" />
          ایجاد تمرین
        </Button>

        {exerciseId &&
          <>
            <Button loading={addExerciseLoading} onClick={() => setExerciseId(null)} className="w-[200px] mr-2 h-[40px] rounded-full text-sm flex justify-center gap-1 items-center">
              ذخیره نهایی
            </Button>
            <Button secondary onClick={rejectExercise} loading={removeLoading} className="h-[40px] rounded-full mr-3">
              لغو
            </Button>
          </>

        }

      </section>
    </>
  );
}
