import Header from "./components/Header";
import Description from "./components/Description";
import Video from "./components/Video";
import Rating from "./components/Rating";
import Expertise from "./components/Expertise";
/* import Activity from "./components/Activity"; */
import Document from "./components/Document";
import FoodPackage from "./components/FoodPackage";
import Gallery from "./components/Gallery";
import { certificateFileUrl, imagesFileUrl } from "../../config";
import { useParams } from "react-router-dom";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  let { id } = useParams();
  let coachNames = [
    {
      id: 100,
      normalPkgId: 22,
      vipPkgId: 23,
      name: "آقای محسن زیوری",
      img: imagesFileUrl + "coach/zivari-p.png",
      docs: [certificateFileUrl + "zivari-1.jpg", certificateFileUrl + "zivari-2.jpg", certificateFileUrl + "zivari-3.jpg", certificateFileUrl + "zivari-4.jpg", certificateFileUrl + "zivari-5.jpg", certificateFileUrl + "zivari-6.jpg", certificateFileUrl + "zivari-7.jpg"],
      description: (
        <section>
          <p>من محسن زیوری هستم، فارغ‌التحصیل رشته تکنولوژی شبکه‌های کامپیوتری و فردی که همواره در مسیر تحقق رویاها و اهدافش قدم برداشته است. از نوجوانی، علاقه‌ام به ورزش باعث شد در رشته کیوکوشین کاراته فعالیت کنم و به موفقیت‌های ارزشمندی دست یابم، از جمله کسب عنوان تکنیکی‌ترین فایتر مسابقات قهرمان قهرمانان تیم ملی امید.</p>
          <p>با وجود این موفقیت‌ها، به دلیل برخی چالش‌ها و ناملایمات، چند سالی از دنیای ورزش فاصله گرفتم و به دنیای کسب‌وکار بین‌المللی وارد شدم. در این دوران، تجربه‌های گرانبهایی کسب کردم و علاقه‌ام به کارآفرینی بیشتر شد. این علاقه باعث شد اولین استارتاپ خود را در حوزه گردشگری سلامت راه‌اندازی کنم. هرچند این پروژه در زمان رشد با بحران جهانی کرونا مواجه شد و به سرانجام نرسید، اما تجربه آن برایم بسیار ارزشمند بود و مسیر آینده‌ام را روشن‌تر کرد.</p>
          <p>علاقه شخصی‌ام به ورزش و سلامت و پیشنهادات دوستان و همکارانم، من را به بازگشت به دنیای ورزش ترغیب کرد. در این مسیر، دوره‌های مختلف مربیگری و تغذیه ورزشی را در داخل و خارج از کشور گذراندم و موفق به دریافت مدارک معتبری از جمله:</p>
          <ul>
            <li>IFBB</li>
            <li>NSCA</li>
            <li>ISSA</li>
            <li>Advanced Fitness Nutrition Coach</li>
            <li>International Advanced Coach Update 2023</li>
          </ul>
          <p>نتیجه این تلاش‌ها، شکل‌گیری ایده اپلیکیشن بادینس بود؛ پروژه‌ای که پس از یک سال و نیم کار مداوم، به ثمر نشسته است. بادینس به‌عنوان یک مرجع در زمینه سلامت، تغذیه و ورزش طراحی شده است و اهداف متعددی را دنبال می‌کند، از جمله:</p>
          <ul>
            <li>آموزش صحیح حرکات ورزشی مانند فیتنس و بدن‌سازی.</li>
            <li>ارائه دوره‌های تخصصی مانند آموزش حرکات بدنسازی، چربی‌سوزی، و دوره‌های مخصوص سالمندان.</li>
            <li>ارزیابی اولیه مخاطبان برای انتخاب بهترین برنامه ورزشی و تغذیه‌ای.</li>
          </ul>
          <p>هدف اصلی من از راه‌اندازی این اپلیکیشن، ایجاد بستری جامع برای ارتقای سلامتی و کیفیت زندگی مردم عزیز کشورم و در آینده‌ای نزدیک، ارائه این خدمات به مخاطبان بین‌المللی است. امیدوارم بتوانم تخصص و تجربه خود را در خدمت به مردم قرار دهم و سلامتی همراه با دانش و مهارت برای همه به ارمغان بیاورم.</p>
        </section>
      ),
    },
    {
      id: 17,
      normalPkgId: 18,
      vipPkgId: 19,
      name: "آقای ایوب بسامی",
      img: imagesFileUrl + "coach/bassami-p.png",
      docs: [certificateFileUrl + "bassami1.jpg", certificateFileUrl + "naderi7.jpg"],
      description: (
        <>
          <p>من ایوب بسامی هستم، متولد کرمانشاه و از سال ۱۳۸۹ به صورت حرفه‌ای وارد دنیای بدنسازی شدم. در طول این سال‌ها افتخار کسب عناوین مهمی همچون قهرمانی کاپ آسیا در سال ۲۰۲۲ و اورال جام الماس در سال‌های ۱۳۹۶ و ۱۳۹۷ را داشته‌ام. با بیش از یک دهه تجربه در رشته فیزیک بدنسازی، به آموزش و انتقال دانش به علاقه‌مندان این حوزه علاقه‌مندم. هدفم این است که با روش‌های علمی و برنامه‌های شخصی‌سازی‌شده به افراد کمک کنم تا بهترین نسخه از خودشان شوند. در کنار ورزش حرفه‌ای، تمرکز من روی ایجاد تعادل در زندگی ورزشکاران و ارتقای سلامت جسمانی و ذهنی آن‌هاست.</p>
          <ul className="list-disc mr-5 mt-6">
            <li>مدال طلا و اورال مسابقات جهانی 2024 </li>
            <li>مدال طلا و اورال مسابقات آسیایی 2023</li>
            <li>مدال طلا و اورال کاپ آسیا 2022</li>
            <li>مدال طلا مسابقات جهانی اسپانیا 2022</li>
            <li>مدال طلا مسابقات Diamond Cup (2016 - 2017)</li>
            <li>۷ دوره قهرمان مسابقات کشوری</li>
            <li>نویسنده مقاله بدنسازی در سطح جهانی</li>
            <li>بالای ده سال سابقه مربیگری بدن‌سازی و پرورش اندام</li>
          </ul>
        </>
      ),
    },

    {
      id: 21,
      normalPkgId: 26,
      vipPkgId: 27,
      name: "خانم حسینی",
      img: imagesFileUrl + "coach/hosseini-p.png",
      description: (
        <>
          <p>من بیتا حسینی هستم، مربی فیتنس و بدنسازی با بیش از 12 سال سابقه مربیگری و دارای مدرک بین‌المللی در فیتنس و حرکات اصلاحی. هدف من در این مسیر، ایجاد انگیزه، ترویج فرهنگ سالم زیستی و کمک به داشتن لایف استایلی سالم و زیبا برای همه افراد است.</p>
          <p>اولویت اصلی من در حرفه‌ام، تاکید بر قدرت برتر بدنی و ذهنی و ارتقای آمادگی جسمانی در تمام سنین است. باور دارم که این موارد، همراه با حس خوب و انرژی مثبت، می‌تواند تغییرات بزرگی در کیفیت زندگی افراد ایجاد کند.</p>
          <p>برای دستیابی به این هدف، همواره در حال یادگیری و به‌روزرسانی دانش و مهارت‌های خود هستم. روش‌های من در طراحی تمرینات و برنامه‌های تغذیه، بر اساس شرایط فیزیکی، اقلیمی و حتی ژنتیکی هر فرد تنظیم می‌شوند تا تجربه‌ای کاملاً شخصی‌سازی‌شده و موثر را ارائه کنم.</p>
          <p>پکیج‌های تمرینی من، چه به صورت حضوری و چه آنلاین، به گونه‌ای طراحی می‌شوند که همیشه جذاب و متنوع باشند. این تنوع نه تنها از تمرین‌زدگی جلوگیری می‌کند، بلکه باعث پیشرفت مستمر و ایجاد تغییرات قابل مشاهده در شاگردانم می‌شود.</p>
          <p>برای افرادی که به دنبال تجربه‌ای خاص‌تر هستند، پکیج‌های VIP طراحی کرده‌ام که شامل امتیازات و برنامه‌های جذاب‌تر است و تاثیرگذاری تمرینات را چند برابر می‌کند.</p>
          <p>افرادی که به جمع ارتش فیتنس من می‌پیوندند، سفری هیجان‌انگیز را آغاز می‌کنند که در آن، گام‌به‌گام همراهشان خواهم بود تا به اهدافشان برسند. امیدوارم شما هم به این گروه بپیوندید و همراه من، مسیر داشتن زندگی سالم و بدنی قدرتمند را تجربه کنید.</p>
          <ul className="list-disc mr-5 mt-6">
            <li>دارای مربیگری درجه ۲ بدنسازی و کار با دستگاه از فدراسیون پرورش اندام</li>
            <li>مدرک مربیگری بین المللی WNBF</li>
            <li>مدرک مربیگری بین المللی درجه ۱ از آکادمی اتریش و آلمان IFPE</li>
            <li>گواهینامه تغذیه کاربردی و روش‌های نوین طراحی تمرین</li>
            <li>گواهینامه حرکات اصلاحی و آسیب شناسی</li>
            <li>گواهینامه ارزیابی وضعیت بدنی و حرکات اصلاحی</li>
          </ul>
        </>
      ),
    },
    {
      id: 18,
      normalPkgId: 20,
      vipPkgId: 21,
      name: "خانم تارویردی",
      img: imagesFileUrl + "coach/tarverdi-p.png",
      docs: [certificateFileUrl + "tarvirdi-1.jpg", certificateFileUrl + "tarvirdi-2.jpg", certificateFileUrl + "tarvirdi-3.jpg"],
      description: (
        <>
          <p>متولد ۲۱ تیر ۱۳۶۹ و فارغ‌التحصیل رشته فیزیک، با افتخار ۱۲ سال تجربه در حوزه ورزشی را با خود همراه دارم. به‌عنوان مربی و راهنمای کسانی که سلامتی و زیبایی بدن را جزو اولویت‌های زندگی خود می‌دانند، تلاش می‌کنم تا مسیری هموار برای دستیابی به این اهداف فراهم کنم. این مسیر، نیازمند علم و تجربه کافی است و من با تمام توان، مخاطبانم را با انگیزه و انرژی بیشتر تا رسیدن به مقصد همراهی می‌کنم.</p>
          <p>تمرین اصولی و بدون نگرانی از آسیب‌های احتمالی، یکی از اولویت‌های اصلی من است. آموزش تکنیک‌های صحیح و اجرای درست حرکات ورزشی، مهم‌ترین بخش از کار من محسوب می‌شود.</p>
          <p>
            <span>سوابق من شامل:</span>
            <br />
            <p>دریافت مدارک تخصصی از فدراسیون‌های مرتبط (پرورش اندام و همگانی) ریاست کمیته کار با دستگاه در هیأت آمادگی جسمانی شمیرانات فعالیت در باشگاه‌ها و مجموعه‌های ورزشی معتبر و عالی‌رتبه تهران ورزش برای من همیشه با عشق و تعهد همراه بوده است. سبک زندگی‌ام به‌عنوان یک مربی و ورزشکار، نه تنها حرفه‌ای بلکه منبع الهام من برای تشویق دیگران است. با من می‌توانید تجربه‌ای لذت‌بخش، علمی و ایمن از تمرینات ورزشی داشته باشید. امیدوارم بتوانم همراه مناسبی برای شما در این مسیر باشم.</p>
          </p>
          <ul className="list-disc mr-5 mt-6">
            <li> رئیس کمیته کار با دستگاه هیأت آمادگی جسمانی شمیرانات</li>
            <li>دارای مدارک درجه ۲ و ۳ فدراسیون بدنسازی و پرورش اندام</li>
            <li>مربی درجه ۳ هيات آمادگی جسمانی</li>
            <li>دارای مدرک TRX (تمرینات معلق با وزن بدن)، از هیأت آمادگی جسمانی</li>
            <li>دارای مدرک cxworks (ترکیبی از تمرینات قدرتی و هوازی جهت تقویت عضلات مرکزی)</li>
            <li>۱۱ سال سابقه ورزشی و ۷ سال سابقه مربیگری</li>
          </ul>
        </>
      ),
    },
    {
      id: 20,
      normalPkgId: 24,
      vipPkgId: 25,
      name: "آقای کریمی",
      img: imagesFileUrl + "coach/karimi-p.png",
      docs: [certificateFileUrl + "karimi-1.jpg", certificateFileUrl + "karimi-2.jpg", certificateFileUrl + "karimi-3.jpg"],
      description: (
        <>
          <p>من محمد کریمی زند هستم، مربی حرفه‌ای بدنسازی و تمرینات ورزشی با بیش از 10 سال تجربه در باشگاه‌های مختلف، از جمله باشگاه ورزشی یوجیم. در طول این مدت، با بیش از 300 نفر در اهداف متنوعی مانند کاهش وزن، تقویت عضلات، و افزایش حجم همکاری داشته‌ام و تلاش کرده‌ام بهترین مسیر را برای رسیدن به نتایج مطلوب برای شاگردانم فراهم کنم.</p>
          <p>دارای مدرک تخصصی در تغذیه پیشرفته ورزشی از سازمان معتبر NSCA، مدرک کارشناسی ارشد در فیزیولوژی ورزشی، و دانشجوی دکتری بیومکانیک هستم. این دانش به من این امکان را می‌دهد که برنامه‌های تمرینی و تغذیه‌ای را بر اساس جدیدترین یافته‌های علمی طراحی کنم، به طوری که متناسب با نیازهای فردی ورزشجویان باشد.</p>
          <p>تجربه کاری من شامل همکاری با افراد مختلف، از سالمندان گرفته تا ورزشکاران حرفه‌ای، است. اولویت من همیشه این بوده که با ارائه تمرینات دقیق و شخصی‌سازی‌شده و راهنمایی‌های تغذیه‌ای کاربردی، شاگردانم را در مسیر دستیابی به اهدافشان همراهی کنم.</p>
          <p>با باور به پیشرفت مستمر، همیشه در تلاش هستم که از جدیدترین متدهای علمی و عملی در حوزه ورزش و تغذیه استفاده کنم تا بتوانم عملکرد و سلامت افراد را بهبود بخشم. اگر به دنبال دستیابی به اهداف ورزشی خود هستید، من آماده‌ام تا همراه و راهنمای شما در این مسیر باشم.</p>
          <ul className="list-disc mr-5 mt-6">
            <li>کارشناس ارشد فیزیولوژی ورزشی</li>
            <li>دارای مدرک تغذیه پیشرفته از سازمان NSCA</li>
            <li>دارای مدارک درجه ۳ فدراسیون بدنسازی و پرورش اندام</li>
            <li> سابقه فعالیت 10 ساله</li>
          </ul>
        </>
      ),
    },
    {
      id: 19,
      normalPkgId: 22,
      vipPkgId: 23,
      name: "آقای محسن زاده",
      img: imagesFileUrl + "coach/mohsenzadeh-p.png",
      docs: [certificateFileUrl + "mohsenzadeh-1.jpg", certificateFileUrl + "mohsenzadeh-2.jpg"],
      description: (
        <>
          <p>من مهدی محسن‌زاده هستم، مربی، محقق و مترجم، با مدرک کارشناسی ارشد فیزیولوژی ورزشی از دانشگاه شهید بهشتی. هدف اصلی من در مسیر حرفه‌ای، کمک به توسعه همه‌جانبه قابلیت‌های جسمانی و ارتقای جنبه‌های مختلف سلامتی برای شاگردانم است.</p>
          <p>در برنامه‌های ورزشی که طراحی می‌کنم، همیشه تلاش دارم با استفاده از شیوه‌های نوین تمرینی مانند کراس‌فیت، تجربه‌ای جذاب و لذت‌بخش از تمرین برای ورزشجویان فراهم کنم. این رویکرد نه تنها انگیزه و جذابیت بیشتری به تمرینات می‌دهد، بلکه دستاوردهای سلامتی و ورزشی آن‌ها را به شکل چشم‌گیری افزایش می‌دهد.</p>
          <p>وجه تمایز اصلی من در مربیگری، تاکید بر ارائه برنامه‌هایی به‌روز، دقیق و شخصی‌سازی‌شده است. تمریناتی که با من انجام می‌دهید، مطابق با جدیدترین متدهای علمی دنیا طراحی شده‌اند. این ادعا را می‌توان در چاپ مقالات علمی من در ژورنال‌های معتبر و ترجمه ۸ عنوان کتاب در زمینه‌های روز دنیا مانند کراس‌فیت و موبیلیتی مشاهده کرد.</p>
          <p>هدف من، نه فقط بهبود عملکرد جسمانی شاگردان، بلکه ایجاد تغییرات مثبت و پایدار در سبک زندگی آن‌ها است. امیدوارم بتوانم در این مسیر همراه شما باشم و تجربه‌ای متفاوت و موثر از ورزش و سلامتی برایتان به ارمغان بیاورم.</p>
          <ul className="list-disc mr-5 mt-6">
            <li>مربی عالی بدنساز آکادمی المپیک</li>
            <li> مدرک مربیگری شخصی از آکادمی ملی پزشکی ورزشی آمریکا NASM</li>
            <li>کارشناس ارشد فیزیولوژی ورزشی دانشگاه شهید بهشتی</li>
            <li>مترجم، مولف و ویراستار 8 عنوان کتاب در زمینه علوم ورزشی</li>
            <li>مربی درجه سه فدراسیون بدنسازی</li>
            <li>مدرس دوره های تخصصی موبیلیتی</li>
          </ul>
        </>
      ),
    },
    {
      id: 1,
      normalPkgId: 1,
      vipPkgId: 17,
      name: "خانم دکتر مهدیه نظری",
      img: imagesFileUrl + "coach/nazari-p.jpg",
      docs: [certificateFileUrl + "nazari-1.jpg", certificateFileUrl + "nazari-2.jpg", certificateFileUrl + "nazari-3.jpg", certificateFileUrl + "nazari-4.jpg"],
      description: (
        <>
          <p>من دکتر مهدیه نظری، فیزیولوژیست ورزشی و مدرس فدراسیون، با سال‌ها تجربه در زمینه فیزیولوژی ورزش به بهبود عملکرد ورزشی و پیشگیری از آسیب‌ها تمرکز دارم. به عنوان یک مدرس، هدف من انتقال اصول علمی فیزیولوژی ورزش به ورزشکاران و علاقه‌مندان این حوزه است. معتقدم آگاهی از فرآیندهای بدن در حین تمرین می‌تواند تأثیر چشمگیری بر نتایج ورزشی داشته باشد. همواره در حال به‌روزرسانی دانش خود با جدیدترین تحقیقات و روش‌های نوین در این حوزه هستم. هدف من کمک به افراد برای دستیابی به بالاترین سطح عملکرد ورزشی و ارتقای سلامتی از طریق شیوه‌های علمی و بهینه است</p>
          <ul className="list-disc mr-5 mt-6">
            <li>دکترای تخصصی فیزیولوژی (قلب، عروق و تنفس)</li>
            <li>مربی بدنسازی تیم ملی موی تای</li>
            <li>مربی تیم ملی مچ‌اندازی</li>
            <li>مربی تیم ملی فیتنس چلنج</li>
            <li>مدرس فدراسیون‌های بدنسازی، سه‌گانه و همگانی</li>
            <li>نایب‌رئیس کمیته فیتنس ایران</li>
            <li>رئیس کمیته بانوان فدراسیون بدنسازی"</li>
            <li>رئیس دپارتمان فیزیولوژی وزنه‌های تمرینی فدراسیون</li>
          </ul>
        </>
      ),
    },
    {
      id: 22,
      normalPkgId: 28,
      vipPkgId: 29,
      name: "آقای علی نادری",

      img: imagesFileUrl + "coach/naderi-p.png",
      docs: [certificateFileUrl + "naderi1.jpg", certificateFileUrl + "naderi2.jpg", certificateFileUrl + "naderi3.jpg", certificateFileUrl + "naderi5.jpg", certificateFileUrl + "naderi6.jpg", certificateFileUrl + "naderi7.jpg", certificateFileUrl + "naderi8.jpg", certificateFileUrl + "naderi9.jpg"],
      description: (
        <>
          <p>علی نادری هستم، مهندس، مربی، مدرس، محقق و مترجم با علاقه‌مندی ویژه به حوزه حرکات اصلاحی و بدن‌سازی در رشته‌های مختلف ورزشی.</p>
          <p>به‌عنوان دانشجوی دانشگاه صنعتی شریف، تمرکز من بر بررسی ابعاد مختلف ورزشکاران و رشته‌های ورزشی گوناگون است. برنامه‌های ورزشی و بدن‌سازی را با توجه به نیازهای خاص هر رشته و شرایط ورزشکار ارائه می‌دهم، چرا که باور دارم بدن‌سازی هر رشته ورزشی ویژگی‌ها و الزامات منحصر به خود را دارد. تخصص من در شناخت این تفاوت‌ها و ارائه برنامه‌های تلفیقی مبتنی بر علم و تجربه است.</p>
          <p>علاوه بر این، در زمینه حرکات اصلاحی و بازتوانی آسیب‌های ورزشی، به‌ویژه در فوتبال، فعالیت دارم و تجربه قابل‌توجهی در کمک به مراجعه‌کنندگان برای بهبود عملکرد و بازگشت به ورزش دارم.</p>
          <p>در حوزه علمی نیز به‌عنوان نویسنده مقالات ورزشی در ژورنال‌های معتبر جهانی و مترجم کتاب‌های تخصصی ورزشی فعالیت می‌کنم. همکاری با انتشارات معتبر در زمینه ترجمه آثار به‌روز دنیا بخشی از تلاش من برای به‌روز نگه‌داشتن دانش و ارائه خدمات بهتر است. هدف من ترکیب علم و تجربه برای کمک به ورزشکاران و علاقه‌مندان به دستیابی به عملکرد بهتر و سلامت پایدار است.</p>
          <ul className="list-disc mr-5 mt-6">
            <li>دانشجوی دانشگاه صنعتی شریف</li>
            <li>درجه ۱ فدراسیون پرورش اندام بدنسازی</li>
            <li>مربی حرکات اصلاحی بین المللی FAE & NASM</li>
            <li>درجه ۲ فدراسیون جهانی WNBF</li>
            <li>درجه D فدراسیون فوتبال آسیا AFC</li>
            <li>درجه ۳ فدراسیون پرورش اندام فیتنس</li>
            <li>درجه ۳ فدراسیون پرورش اندام</li>
            <li>بدنسازی رشته های مختلف ورزشی</li>
            <li>درجه ۳ فدراسیون آمادگی جسمانی آمادگی جسمانی عمومی (فیزیکال فیتنس)</li>
            <li>پرسونال ترینیگ فدراسیون آمادگی جسمانی</li>
            <li>مشاور تغذیه و تناسب اندام مجتمع فنی تهران</li>
            <li>نویسنده مقالات متعدد در زمینه بدن‌سازی در سطح جهانی</li>
          </ul>
        </>
      ),
    },
  ];
  let selectdCoach = coachNames.find((el) => el.id === parseInt(id));
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[1130px] max-w-full min-h-[900px]  pt-[20px] mb-[40px] px-[32px] xl:m-auto xl:px-0">
      <Header name={selectdCoach.name} img={selectdCoach.img} />
      <section className="grid grid-cols-1 xl:grid-cols-3 gap-[64px] mt-[200px]">
        <section className="xl:col-span-2">
          <Description name={selectdCoach.name} description={selectdCoach.description} />
          <FoodPackage id={selectdCoach?.id} vipPkgId={selectdCoach?.vipPkgId} normalPkgId={selectdCoach?.normalPkgId} />
          <Video />
          <Gallery />
        </section>
        <section>
          <Rating />
          <Expertise />
          {/* <Activity /> */}
          <Document docs={selectdCoach?.docs} />
          {/* <section className="mt-[32px]">
            <img src={imagesFileUrl + "doctor-profile-package.png"} alt="" />
          </section> */}
        </section>
      </section>
    </section>
  );
}
