//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4744_47991)">
          <path
            d="M0 10.7014C0.05625 10.3545 0.0984375 10.0076 0.173438 9.67013C0.726562 7.21388 2.78906 5.28263 5.28281 4.8795C8.65312 4.33107 11.8172 6.51075 12.5109 9.867C12.7453 11.0061 12.6562 12.1311 12.2719 13.2326C12.2344 13.3451 12.1828 13.3873 12.0609 13.3826C11.5641 13.3733 11.0672 13.3826 10.5703 13.3733C10.4953 13.3733 10.4016 13.3264 10.3547 13.2701C9.38906 12.1451 8.17031 11.6811 6.73125 12.0326C5.26875 12.3842 4.37812 13.3592 4.06406 14.8311C3.99375 15.1592 4.00313 15.5014 3.97969 15.8436C2.82188 15.9654 1.80469 15.7076 1.10625 14.6623C0.496875 13.7576 0.135937 12.7639 0.0375 11.6764C0.0328125 11.6201 0.01875 11.5639 0.0046875 11.5076C0 11.2404 0 10.9686 0 10.7014Z"
            fill={fill}
          />
          <path
            d="M7.25981 18C7.14262 17.9719 7.02075 17.9437 6.90825 17.9156C5.73169 17.5969 4.977 16.5187 5.052 15.2719C5.12231 14.1281 6.0645 13.1437 7.24106 12.989C8.25825 12.8531 9.26137 13.3687 9.76294 14.2875C9.82387 14.3953 9.88481 14.4281 10.0067 14.4281C11.0004 14.4234 11.9942 14.4234 12.9879 14.4281C13.1145 14.4281 13.1801 14.3953 13.2457 14.2781C13.8036 13.2515 14.9942 12.75 16.1145 13.0547C17.2254 13.3594 17.9895 14.3953 17.9614 15.5531C17.9332 16.739 17.0426 17.7609 15.8661 17.9578C15.8286 17.9625 15.7911 17.9812 15.7536 17.9906C15.5567 17.9906 15.3551 17.9906 15.1582 17.9906C15.1442 17.9812 15.1301 17.9672 15.1114 17.9672C14.2536 17.8359 13.6254 17.3812 13.2082 16.6265C13.1801 16.575 13.0817 16.5281 13.0161 16.5281C12.0129 16.5234 11.0051 16.5234 9.99731 16.5234C9.88012 16.5234 9.82387 16.5562 9.76762 16.6594C9.44887 17.25 8.96606 17.6531 8.32387 17.864C8.16919 17.9156 8.00981 17.9484 7.85512 17.9859C7.65825 18 7.46137 18 7.25981 18Z"
            fill={fill}
          />
          <path
            d="M0.759584 6.26244C0.754896 5.189 0.623646 4.12025 0.858021 3.07494C1.25646 1.32181 2.85958 0.0561897 4.65958 0.0374397C5.78927 0.0280647 6.91427 0.0233772 8.04396 0.0374397C9.96583 0.0655647 11.6721 1.60306 11.8549 3.51556C11.9299 4.27963 11.8877 5.05775 11.8971 5.8265C11.8971 5.96713 11.8971 6.10306 11.8971 6.24369C11.8783 6.25775 11.8596 6.27182 11.8361 6.28588C11.8221 6.24838 11.8127 6.20619 11.7846 6.17338C11.194 5.51244 10.5049 4.97806 9.7174 4.57025C9.61896 4.51869 9.57677 4.46244 9.5674 4.34525C9.47833 3.11713 8.44708 2.1515 7.21896 2.13744C6.65177 2.13275 6.08458 2.13744 5.5174 2.13744C4.19083 2.13744 3.18771 3.04681 3.05177 4.364C3.04708 4.42963 2.99552 4.52338 2.94396 4.5515C2.13771 4.97338 1.4299 5.52182 0.825209 6.2015C0.811146 6.22025 0.787709 6.239 0.759584 6.26244Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_4744_47991">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
