import { useState, useContext, useEffect } from "react";
import { useAxiosWithToken } from "../../../hooks";
import api from "../../../api";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const Item = ({ title, value }) => {
  return (
    <>
      <section className="w-full flex flex-col justify-center items-center gap-4 h-[120px] bg-purple rounded-3xl">
        <span className="text-white text-[16px]">{title}</span>
        <span className="text-white text-3xl font-extrabold">{value}</span>
      </section>
    </>
  );
};
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState({});
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getInfo = () => {
    useAxiosWithToken
      .get(api.admin.getDashboardInfo)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getInfo();
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-auto">
      <h1 className="text-[30px] mt-5 text-center font-extrabold">داشبورد پنل مدیریت</h1>
      <section className="h-full flex justify-center items-center mt-10">
        <section className="w-full grid grid-cols-1 xl:grid-cols-2 gap-4 ">
          <Item title="تعداد کل کاربران" value={data?.userCount} />
          <Item title="تعداد پکیچ های خریداری شده" value={data?.requestProgramCount} />
          <Item title="تعداد درخواست های پکیج ورزشی" value={data?.requestSportCount} />
          <Item title="تعداد درخواست های پکیج غذایی" value={data?.requestMedicalCount} />
          <Item title="برنامه های ورزشی نهایی شده" value={data?.sportProgramFinishedCount} />
          <Item title="برنامه های غذایی نهایی شده" value={data?.medicalProgramFinishedCount} />
          <Item title="درخواست های در صف برنامه ورزشی" value={data?.sportProgramInQueueCount} />
          <Item title="درخواست های در صف برنامه غذایی" value={data?.medicalProgramInQueueCount} />
        </section>
      </section>
    </section>
  );
}
