import CheckedBox from "./CheckedBox";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ title, items, checkBoxHandleChange }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className={`w-full flex flex-col xl:justify-start border p-6 rounded-[15px] border-[#DDCEEE] `}>
      <section>
        <h3 className="text-[#3E4151] text-xs xl:text-[20px] leading-[12px] xl:leading-5 font-semibold">{title}</h3>
      </section>
      {items.map((item, index) => (
        <CheckedBox key={index} onChange={(e) => checkBoxHandleChange(e)} value={item.id} title={item.persianName} />
      ))}
    </section>
  );
}
