import React from "react";
import "./style.css";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ children, outlined, disabled, light, className, loading, secondary, onClick, type, height, padding, ...rest }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getColor = () => {
    if (outlined) {
      if (secondary) {
        return "border border-[#F02484] hover:border-[#F67CB5] hover:bg-[#F67CB5] text-white bg-[#F02484]";
      } else if (light) {
        return "";
      } else {
        return "border border-purple  text-purple hover:bg-[#DDCEEE] bg-none ";
      }
    } else {
      if (secondary) {
        return "border border-[#F02484] hover:border-[#F67CB5] hover:bg-[#F67CB5] text-white bg-[#F02484]";
      } else if (light) {
        return " hover:bg-[#FFFFFFB2] bg-white text-purple";
      } else {
        return "bg-purple hover:bg-[#7639BA] border-purple text-white ";
      }
    }
  };
  const getLoaderColor = () => {
    if (outlined) {
      if (secondary) {
        return "orangeLoader";
      }
      if (light) {
        return "grayLoader";
      } else {
        return "purpleLoader";
      }
    } else {
      return "whiteLoader";
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <button type={type === "submit" ? "submit" : type === "button" ? "button" : "button"} disabled={disabled} {...rest} onClick={onClick} className={`relative flex ${height ? height : "h-12"}   items-center justify-center rounded-[10px]   ${getColor()} ${padding ? padding : "px-[35px]  "}  transition-all duration-500 disabled:opacity-30 ${className}`}>
      {loading ? (
        <section className="flex justify-center items-center h-full  w-10 ">
          <div className={`btnLoader ${getLoaderColor()} `}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </section>
      ) : (
        children
      )}
    </button>
  );
}
