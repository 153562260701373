import React, { Fragment } from 'react'
import PDFDownloader from '../PdfDownloader'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ rowData }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            {rowData.personalSportProgramDetails && <PDFDownloader data={rowData} />}
            <hr className='my-4 border-[whitesmoke]' />
            <section className="w-full min-h-[100px] rounded-lg">
                <section className="grid grid-cols-5 py-2 text-lg">
                    <section className="w-full font-bold">شناسه تمرین</section>
                    <section className="w-full font-bold">سیستم تمرینی</section>
                    <section className="w-full font-bold">نام تمرین</section>
                    <section className="w-full font-bold">نام عضله</section>
                    <section className="w-full font-bold">جزییات</section>
                </section>
                {rowData?.personalSportProgramDetails?.map((item, index) => (
                    <>
                        <section className="grid grid-cols-5 py-2 font-semibold text-md text-purple">
                            <Fragment key={index}>
                                <span>{item.id}</span>
                                <span>{item.trainingSystem}</span>
                                <span>{item.exerciseName}</span>
                                <span>{item.muscleName}</span>
                            </Fragment>

                        </section>
                        <section className="w-full bg-[#fef8ff] min-h-[100px] rounded-lg">
                            <section className="grid grid-cols-5 font-light py-2 text-md">
                                <span>تعداد تکرار</span>
                                <span>استراحت</span>
                                <span>tempo</span>
                                <span>توان به درصد</span>
                                <span>وزن به کیلوگرم</span>
                            </section>
                            {item?.sets?.map((item, index) => (
                                <section key={index} className="grid grid-cols-5 py-2 font-light text-md text-purple">
                                    <span>{item.setRepeat}</span>
                                    <span>{item.rest}</span>
                                    <span>{item.tempo}</span>
                                    <span>{item.tavan}</span>
                                    <span>{item.weight}</span>
                                </section>
                            ))}
                            <hr />
                            <section className="flex justify-start px-[10px] mt-2 text-purple">
                                <span>توضیحات:</span>
                                <span className="font-semibold mr-1">{item?.description}</span>
                            </section>
                        </section>
                    </>
                ))}
            </section>
        </>
    )
}
