import CheckBoxGroup from "./components/CheckBoxGroup";
import { useAxiosWithToken } from "../../../../../hooks";
import { ComponentLoading } from "../../../../../components/common";
import { useEffect, useState } from "react";
import api from "../../../../../api";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveTab, setSpecialSicknessList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getData = () => {
    /*   setLoading(true) */
    useAxiosWithToken
      .get(api.disease.findAllList)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {});
  };
  const checkBoxHandleChange = (e) => {
    let arr = [];
    arr = [...answers];
    if (e.checked) {
      arr.push(e.value);
    } else if (e.checked === false) {
      arr = arr.filter((el) => el !== e.value);
    }

    setAnswers(arr);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    let arr = [];
    answers.forEach((item) => {
      arr.push({
        diseaseDetailId: item,
      });
    });
    setSpecialSicknessList(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);
  //
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-[90%] w-[1360px] m-auto mt-[20px] mb-[100px]">
      <h2 className="text-center text-[#3E4151] xl:text-black text-[20px] xl:text-[48px] font-bold leading-[21px] xl:leading-[74px]">آیا بیماری خاصی داری؟</h2>
      <section className=" xl:w-full w-[90%] mx-auto flex flex-col gap-12 justify-center mt-12  items-center xl:h-auto ">
        {/*  <h3 className="text-[#666666] text-xs xl:text-[18px] font-semibold xl:font-medium leading-[12px] xl:leading-5 ">
          چند روز و چند ساعت در هفته امکان ورزش کردن دارید؟
        </h3> */}
        <section className="grid gap-5 w-full xl:w-full xl:grid-cols-2">
          <ComponentLoading loading={loading} />
          {data.map((item, index) => (
            <CheckBoxGroup checkBoxHandleChange={checkBoxHandleChange} key={index} items={item.diseaseDetails} title={item.persianName} />
          ))}
        </section>
        <button
          onClick={() => {
            setActiveTab(2);
          }}
          className="xl:w-[427px] w-full bg-[#5521B4] xl:text-[20px] rounded-xl -tracking-[2%] leading-[30.2px] font-bold text-white h-[50px] xl:h-[56px]"
        >
          مرحله بعد
        </button>
      </section>
    </section>
  );
}
