import { baseFileUrl } from "../../../../config";
import { Button } from "../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`xl:hidden block rounded-[50px] mt-[30px] xl:mt-[120px] h-[698px] w-full relative `}>
        <img src={baseFileUrl + "/images/section3-bg-mobile.png"} className="absolute top-0 w-full h-[698px] " alt="" />
        <img src={baseFileUrl + "/images/bg-section4-mobile.png"} className="absolute top-0 w-full h-[588px] mix-blend-overlay opacity-[.52]" alt="" />
        <img src={baseFileUrl + "/images/pic-section4-mobile.png"} className="absolute bottom-0 w-full mix-blend-luminosity" alt="" />
        <section className="relative w-[85%] px-[10px] mx-auto pt-[193px]">
          <section className="w-full absolute  rounded-[50px] flex flex-col justify-start  items-center z-50">
            <h4 className="text-[30px] font-semibold text-white">دوره های ورزشی</h4>
            <span className="text-[14px] font-normal text-white mt-6 text-center"> پکیج های بادینس با توجه به سطح آمادگی و هدف شما توسط مربیان حرفه ای ما طراحی شده </span>
            <section className="h-[44px]  w-[95%]  flex justify-center">
              <Button light={true} className=" w-[138px] h-[44px] rounded-full font-semibold   mt-[48px]">
                به زودی
              </Button>
            </section>
          </section>
        </section>
      </section>
      <section className="hidden w-[95%] mx-auto  xl:block rounded-[50px] mt-[100px] h-[550px] relative p-1">
        <img src={baseFileUrl + "/images/section4.png"} className="w-full" alt="" />
        <section className="w-[100%]  rounded-[50px] absolute flex flex-col justify-start mt-32 items-center top-0 left-0 z-1">
          <h4 className="text-[55px] font-semibold text-white">دوره های ورزشی</h4>
          <span className="text-[14px] font-normal text-white mt-2">پکیج های بادینس با توجه به سطح آمادگی و هدف شما توسط مربیان حرفه ای ما طراحی شده </span>
          <section className="h-[44px] flex justify-between">
            <Button light={true} className="w-[250px] transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px] h-[60px] text-xl rounded-full font-bold mt-10">
              به زودی
            </Button>
            {/*  <button className="bg-purple w-[141px] h-[44px] rounded-full font-semibold  border text-white mt-10">
            انتخاب مربی
          </button> */}
          </section>
        </section>
      </section>
    </>
  );
}
