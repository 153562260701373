import React from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,
  badge,
  title,
  currentFilter,
  filter,
  setActiveFilter,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section
      className="cursor-pointer hover:translate-y-[-5px] transition-all flex flex-col justify-center items-center"
      onClick={() => setActiveFilter(filter)}
    >
      <section
        className={`relative flex justify-center items-center border w-[65px] h-[65px]  xl:w-[84px] xl:h-[84px] rounded-[10px] ${
          filter === currentFilter
            ? "shadow-[0px_10.08px_21.84px_1px_#5408A947] border-[5px] border-purple"
            : "border-[#DDCEEE]"
        }`}
      >
        <span className="flex justify-center items-center text-white w-[30px] h-[30px] bg-purple rounded-full absolute top-[-10px] right-[-10px]">
          {badge ? badge : 0}
        </span>
        <img className="w-[35px] xl:w-[45px]" src={src} alt="" />
      </section>
      <span className="block font-semibold text-purple mt-[24px] text-[11px] xl:text-sm text-center">
        {title}
      </span>
    </section>
  );
}
