import { useState } from "react";
import Info from "./components/Info";
import ExerciseProgram from "./components/ExerciseProgram";
import ExerciseList from "./components/ExerciseList";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[753px] h-[80px] flex justify-between items-center rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-4  xl:px-[86px]">
        <button onClick={() => setActiveTab(1)} className={`xl:w-[200px] w-[123px] text-[12px] xl:text-lg h-10 xl:h-[48px] rounded-full  ${activeTab === 1 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold  `}>
          اطلاعات تکمیلی
        </button>
        <button onClick={() => setActiveTab(2)} className={`xl:w-[200px] w-[146px] text-[12px] xl:text-lg h-10 xl:h-[48px] rounded-full  ${activeTab === 2 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold `}>
          تنظیم برنامه تمرینی
        </button>
        <button onClick={() => setActiveTab(3)} className={`xl:w-[200px] w-[146px] text-[12px] xl:text-lg h-10 xl:h-[48px] rounded-full  ${activeTab === 3 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold  `}>
          لیست تمرینات
        </button>
      </section>
      {activeTab === 1 && <Info />}
      {activeTab === 2 && <ExerciseProgram />}
      {activeTab === 3 && <ExerciseList />}
    </>
  );
}
