import React from "react";
import { svgFileUrl } from "../../../../../config";
import FoodListPdf from "../../../../../components/PDF/FoodListPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";


const PDFDownloader = ({ data, fileName = "document.pdf" }) => (
    <div>

        <PDFDownloadLink document={<FoodListPdf data={data} />} fileName={fileName}>
            {({ loading }) =>
                loading ? (
                    "در حال ساخت PDF..."
                ) : (
                    <button className="flex justify-center items-center gap-2 w-[140px] p-2 font-semibold  h-[40px] mr-auto bg-whitesmoke bg-[#fff4f4]  text-red-500 rounded-full">
                        <span> دانلود PDF</span>
                        <img className="h-full" src={svgFileUrl + "download.svg"} alt="Download" />
                    </button>
                )
            }
        </PDFDownloadLink>

    </div>
);

export default PDFDownloader;