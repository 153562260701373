//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ color, imgUrl, title, value }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className={` xl:col-span-2 h-auto xl:h-[200px] pb-8 xl:pb-0 items-center flex flex-col  overflow-hidden w-full xl:w-[121px]  border border-solid border-[#E6E8E9] rounded-[18px] relative`}>
      <section className="flex justify-center">
        <section className={`w-[141px] bg-white  mt-[-50px] h-[140px] rounded-full absolute  z-40`}></section>
        <section style={{ backgroundColor: color }} className={`w-[141px]  mt-[-40px] h-[140px] rounded-full absolute  opacity-10`}></section>
        <img alt="" src={imgUrl} className="w-[48px] h-[48px] z-50 mt-[24px] " />
      </section>

      <span className="mt-[35px] xl:text-base text-[13px] " style={{ color: color }}>
        {title}
      </span>
      <span className="mt-[10px] xl:text-[20px] font-semibold" style={{ color: color }}>
        {value}
      </span>
    </section>
  );
}
