import React from "react";
import { baseFileUrl, imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-[50px] xl:mb-[150px] mx-auto h-auto xl:h-[680px] bg-[linear-gradient(296.81deg,_#F02484_-60.12%,_#5408A9_32.13%,_#4CC9EC_132.28%)]">
      <section className="w-[1440px] max-w-full h-full m-auto">
        <section className="grid grid-cols-1 xl:grid-cols-2  h-full">
          <section>
            <section className="relative xl:w-[430px] h-[380px] flex justify-center items-center">
              <img src={baseFileUrl + "/shape-circle.png"} alt="" className="absolute top-0 z-10  " />
              <img src={imagesFileUrl + "coach/imani.png"} alt="" />
            </section>
            <section className="px-[40px]">
              <h3 className="xl:leading-[62px] leading-[34.1px] text-[22px] text-white  xl:text-[40px] font-bold">دکتر حسین ایمانی</h3>
              <p className="text-white">متخصص تغذیه بالینی و رژیم درمانی</p>
              <p className="text-white">عضو هیئت علمی و دانشیار دانشگاه علوم پزشکی تهران</p>
              <p className="xl:leading-[21.7px] mt-6 xl:mt-[30px] font-light leading-[21.1px] text-justify text-white text-sm ">پزشکان تیم ما با تخصص و تجربه فراوان، متعهد به ارائه بهترین خدمات درمانی و مشاوره‌ای برای حفظ و ارتقای سلامت شما هستند.</p>
            </section>
          </section>
          <section className="grid grid-cols-1 xl:grid-cols-3 gap-3 py-[30px]  items-center">
            <section className="flex flex-col items-center">
              <img src={imagesFileUrl + "coach/sadeghi1.png"} alt="" />
              <span className="text-[20px] font-bold text-white mt-[17px]">خانم سارا صادقی</span>
              <span className="text-[15px] font-normal text-white">متخصص تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <img src={imagesFileUrl + "coach/mohajerani1.png"} alt="" />
              <span className="text-[20px] font-bold text-white mt-[17px]"> خانم ملیکه مهاجرانی</span>
              <span className="text-[15px] font-normal text-white">متخصص تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <img src={imagesFileUrl + "coach/lajevardi1.png"} alt="" />
              <span className="text-[20px] font-bold text-white mt-[17px]">آقای ابوالفضل لاجوردی</span>
              <span className="text-[15px] font-normal text-white">متخصص تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <img src={imagesFileUrl + "coach/mansouri1.png"} alt="" />
              <span className="text-[20px] font-bold text-white mt-[17px]">خانم منصوری</span>
              <span className="text-[15px] font-normal text-white">متخصص تغذیه</span>
            </section>
            <section className="flex flex-col items-center">
              <img src={imagesFileUrl + "coach/shirzad1.png"} alt="" />
              <span className="text-[20px] font-bold text-white mt-[17px]">خانم شیرزاد</span>
              <span className="text-[15px] font-normal text-white">متخصص تغذیه</span>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
