import { BlobProvider } from "@react-pdf/renderer";
import BodyNessPdf from "../../../../../../../../../components/PDF/ImaniPdf";
const MyPDFPage = ({ data }) => {
  return (
    <BlobProvider document={<BodyNessPdf data={data} />}>
      {({ url, loading }) =>
        loading ? (
          <button disabled>در حال بارگزاری</button>
        ) : (
          <a href={url} download="example.pdf" className={`w-[100px] m-auto flex justify-center items-center  h-[30px] bg-purple  text-white rounded-full`}>
            دانلود PDF
          </a>
        )
      }
    </BlobProvider>
  );
};

export default MyPDFPage;
