import { useState, useEffect } from "react";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ index, item, finalPackageList, setFinalPackageList, packageId }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [value, setValue] = useState("");
  const [unit, setUnit] = useState("GRAM");
  const typeOptions = [
    { value: "GRAM", label: "گرم" },
    { value: "TABLESPOON", label: "قاشق غذاخوری" },
    { value: "DESSERTSPOON", label: "قاشق مربا خوری" },
    { value: "TEASPOON", label: "قاشق چای‌خوری" },
    { value: "LADLE", label: "ملاقه" },
    { value: "MATCHBOX", label: "قوطی کبریت" },
    { value: "CUP", label: "فنجان" },
    { value: "HALF_CUP", label: "نیم‌فنجان" },
    { value: "SMALL_BOWL", label: "کاسه کوچک" },
  ];
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────
  /* const handleChange = (value) => {
    setValue(value);
    let arr = [...finalPackageList];
    arr.forEach((element, index) => {
      if (element?.packageId === packageId) {
        element?.dairyListWithConsumedInputDTOS?.forEach((element1, index1) => {
          if (element1?.dairyId === item?.dairyId) {
            arr[index].dairyListWithConsumedInputDTOS[index1].consumedGram = value;
          }
        });
      }
    });
    setFinalPackageList(arr);
  }; */
  useEffect(() => {
    let arr = [...finalPackageList];
    arr.forEach((element, index) => {
      if (element?.packageId === packageId) {
        element?.dairyListWithConsumedInputDTOS?.forEach((element1, index1) => {
          if (element1?.dairyId === item?.dairyId) {
            arr[index].dairyListWithConsumedInputDTOS[index1].consumedGram = value;
            arr[index].dairyListWithConsumedInputDTOS[index1].unit = unit;
          }
        });
      }
    });
    setFinalPackageList(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, unit]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="grid grid-cols-1 xl:grid-cols-6 xl:gap-12 gap-[10px] mt-[25px] items-center  border-b py-2 border-slate-200">
      <section className="flex w-full justify-between col-span-4">
        <span className="block w-[10%]">{index + 1}</span>
        <span className="block text-purple w-[70%] font-semibold">{item?.mainFoodDescription}</span>
        <span className="block w-[20%] xl:text-start text-end">
          {item?.energyKcal}کالری <span className="text-[10px] text-purple">(به ازای صد گرم)</span>
        </span>
      </section>
      <section className="flex xl:col-span-2 justify-between items-center gap-1">
        <input
          type="text"
          className="w-[100px] p-2 rounded-md border border-slate-300"
          value={value}
          onChange={(e) => {
            let value = e.target.value.replace(/\D/g, "");
            setValue(value);
          }}
        />
        <select onChange={(e) => setUnit(e.target.value)} className="ml-1 cursor-pointer border-none text-center w-[250px] focus:border-transparent focus:ring-0">
          {typeOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </section>
    </section>
  );
}
