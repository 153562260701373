import { useState } from "react";
import { imagesFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { Link, useNavigate } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ id }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className={`flex-col bg-[#f7f3ff]  border-[#5521B40D] flex  relative  transition-all hover:shadow-lg hover:shadow-[#DDCEEE] hover:translate-y-[-10px]   overflow-hidden border  p-6  rounded-[20px] border-solid `}>
      <section>
        <section className="flex items-center gap-2">
          <section className="w-[56px] h-[56px] bg-white flex justify-center items-center rounded-md">
            <img src={imagesFileUrl + "icons/meal3.svg"} alt="" />
          </section>
          <h3 className="leading-[34.1px]  text-[22px] font-bold">رژیم افزایش وزن</h3>
        </section>
        <section className="my-6 flex text-sm items-start gap-[6px]">
          <span className="text-[#5521B4] bg-white tracking-tighter px-[6px] py-2 font-bold text-xs rounded-[150px]">پکیج یک ماهه</span>
          <span className="text-[#5521B4] bg-white px-[6px] py-2 tracking-tighter font-bold text-xs rounded-full">پکیج شش ماهه</span>
          <span className="text-[#5521B4] bg-white px-[6px] py-2 tracking-tighter font-bold text-xs rounded-[150px]">پکیج یک ساله</span>
        </section>
        <section className="font-bold text-[#191919]">
          <span className="text-[24px] leading-[37.2px] mb-5">350,000</span>
          تومان
        </section>
        <section className="flex justify-start w-full">
          <Link to={"/shopping-cart/" + id}>
            <Button outlined className="rounded-full transition-all hover:shadow-lg hover:shadow-[#A6A9BD] hover:translate-y-[-4px] mt-2 h-[40px]">
              خرید پکیچ
            </Button>
          </Link>
        </section>
      </section>
    </section>
  );
}
