import { Fragment, useEffect, useState } from "react";
import { ComponentLoading, Modal } from "../../../../../../../components/common";
import TableRow from "../TableRow";
import MealDetailModal from '../../../../../MealDetailModal'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ loading, data, reload }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [modal, setModal] = useState(false)
  const [rowData, setRowData] = useState()
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const showModal = (rowData) => {
    setModal(true)
    setRowData(rowData)
  }
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log(rowData)
  }, [rowData])
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full m-auto mt-10 text-center text-[14px] relative">
      <section className="grid grid-cols-1 xl:grid-cols-4 gap-1">
        <section className="w-full">شناسه</section>
        <section className="w-full">نام پکیچ</section>
        <section className="w-full">توضیحات</section>
        <section className="w-full">جزییات</section>
      </section>
      <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
        <ComponentLoading loading={loading} />
        {data?.map((item, index) => (
          <Fragment key={index}>
            <TableRow reloadTable={reload} showModal={showModal} rowData={item?.foodProgramPackages[0]} />
          </Fragment>
        ))}
      </section>
      <Modal width={1200} open={modal} onClose={() => setModal(false)}>
        <MealDetailModal rowData={rowData} />
      </Modal>
    </section>
  );
}
