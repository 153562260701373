import { useState, useEffect, Fragment } from "react";
import { ComponentLoading, NoRecord } from "../../../components/common";
import TableRow from "./components/TableRow";
import { useAxiosWithToken } from "../../../hooks";

import api from "../../../api";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ reload, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getExerciseList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.coach.getCoachExerciseList)
      .then((res) => {
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getExerciseList();
  }, [reload]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] px-1 xl:px-[34px] py-[44px] shadow-md">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">برنامه های ورزشی</span>
      </section>
      <section className="w-full m-auto mt-10 text-center text-[14px] relative">
        <section className="grid grid-cols-1 xl:grid-cols-2 gap-1">
          <section className="w-full">شناسه تمرین</section>
          <section className="w-full">جزییات تمرین</section>
        </section>
        <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
          <ComponentLoading loading={loading} />
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <Fragment key={index}>
                <TableRow reloadTable={reloadTable} rowData={item} />
              </Fragment>
            ))
          ) : (
            <NoRecord />
          )}
        </section>
      </section>
    </section>
  );
}
