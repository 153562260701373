import { useState, useEffect } from "react";
import Select from "react-select";
import { svgFileUrl } from "../../config";
import FoodBox from "./components/FoodBox";

import api from "../../api";
import { useGlobalContext } from "../../context/GlobalContext";
import { Pagination } from "antd";
import { useAxios } from "../../hooks";
import { Button } from "../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const { showLoading, hideLoading } = useGlobalContext();
  const options = [
    { value: "BEFORE", label: "قبل" },
    { value: "AFTER", label: "بعد" },
    { value: "BREAKFAST", label: "صبحانه" },
    { value: "LUNCH", label: "ناهار" },
    { value: "DINNER", label: "شام" },
  ];
  const optionsCallory = [
    { value: 1, label: "کمترین کالری" },
    { value: 2, label: "بیشترین کالری" },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [foodStep, setFoodStep] = useState();
  const [recipeList, setRecipeList] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [calloryValue, setCalloryValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getRecipe = (currentPage) => {
    showLoading();
    /*    useAxios
      .get(`${api.recipe.searchRecipe}?language=PERSIAN&calOrderBy=${calloryValue ? calloryValue?.value : ""}&pageSize=12&pageNo=${currentPage - 1}&foodStep=${foodStep ? foodStep?.value : ""}`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((res) => {
        hideLoading();
        setRecipeList(res.data.elements);
        setTotalElements(res.data.totalElements);
      })
      .catch((e) => {
        hideLoading();
      }); */
    let params = {
      language: "PERSIAN",
      calOrderBy: calloryValue?.value,
      pageSize: 12,
      pageNo: currentPage - 1,
      foodStep: foodStep?.value,
    };
    useAxios
      .get(api.recipe.searchRecipe, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        hideLoading();
        setRecipeList(res.data.elements);
        setTotalElements(res.data.totalElements);
      })
      .catch((e) => {
        hideLoading();
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getRecipe(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-[100px] xl:mb-12">
      <h2 className="w-[85%] mx-auto font-bold text-[22px] xl:text-[32px]  text-center pt-[44px] xl:pt-[70px]">سالم ترین و آسان ترین دستورهای غذایی را پیدا کنید</h2>
      <section className="grid grid-cols-2 xl:flex xl:justify-between  gap-x-[23px] xl:gap-3 content-center px-[30px] py-[20px] xl:px-12 w-[85%] h-auto mx-auto xl:w-[1016px] xl:h-[117px] rounded-[30px] xl:rounded-[186px] bg-gradient-to-r from-[#E4E9FE] to-[#DEBFFA] m-auto mt-14 xl:mt-12 mb-[30px] xl:mb-10">
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">وعده غذایی</span>
          <Select options={options} isSearchable={false} onChange={(value) => setFoodStep(value)} className="mt-1 text-[12px] xl:w-[300px]" placeholder="انتخاب کنید" />
        </section>
        <section>
          <span className="font-bold xl:font-semibold text-[14px] xl:text-[16px]">میزان کالری</span>
          <Select options={optionsCallory} isSearchable={false} className="mt-1 text-[12px] xl:w-[300px]" placeholder="انتخاب کنید" onChange={(e) => setCalloryValue(e)} />
        </section>
        <section className="flex xl:col-span-1 col-span-2 xl:justify-end justify-center">
          <Button
            onClick={() => {
              getRecipe(1);
              setCurrentPage(1);
            }}
            className="bg-purple transition-all hover:shadow-lg hover:shadow-[#A6A9BD] hover:translate-y-[-4px]   rounded-[150px] text-white w-[160px] h-[44px] mt-6 flex justify-center items-center"
          >
            <img src={svgFileUrl + "search.svg"} alt="" />
            <span className="mr-2">جستجو</span>
          </Button>
        </section>
      </section>
      <section className="w-[85%] mx-auto xl:w-[1133px] max-w-full m-auto relative">
        <section className="flex flex-col xl:flex-row xl:justify-between pr-3 md-pr-0">
          <span className="order-first xl:order-last font-normal mb-[24px] xl:mb-0 text-md">{totalElements} رژیم غذایی</span>
        </section>
        <section className="mt-[100px] grid xs:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-x-11 gap-y-[100px]">
          {recipeList.map((item, index) => (
            <>
              <FoodBox id={item.id} calories={item.calories} carbohydrate={item.carbohydrate} protein={item.protein} fat={item.fat} duration={item.duration} title={item.foodName} image={item.photoUrl} />
            </>
          ))}
        </section>
        <Pagination className="ltr text-center mt-5 sans" defaultCurrent={1} current={currentPage} pageSize={12} onChange={(value) => setCurrentPage(value)} showSizeChanger={false} total={totalElements} />
      </section>
    </section>
  );
}
