import { useState } from "react";
import { baseFileUrl } from "../../../../config";
import { numberWithCommas } from "../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [detailShow, setDetailShow] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  // ─── Functions ──────────────────────────────────────────────────────────────────
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:grid flex justify-between xl:grid-cols-6 gap-1 mt-[12px] border border-[#E8E7EB] h-auto p-4 xl:p-0 xl:h-[50px] items-center rounded-[12px] cursor-pointer font-normal  transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section>{rowData?.mobileNumber}</section>
        <section>{rowData?.namePackaging}</section>

        <section>{numberWithCommas(Math.trunc(rowData?.totalAmount))}</section>
        <section>{Math.trunc(rowData?.discountPercent)}</section>
        <section>
          <button className="text-purple flex justify-center items-center w-full" onClick={() => setDetailShow(!detailShow)}>
            <img className={`w-[20px] ${detailShow ? "rotate-[-90deg]" : "rotate-[90deg]"}`} src={baseFileUrl + "/arrowRight.png"} alt="" />
            نمایش
          </button>
        </section>
      </section>
      {detailShow && <section className="w-full bg-indigo-100 py-2 min-h-auto rounded-lg">{rowData?.requestProgramStatusFa}</section>}
    </>
  );
}
