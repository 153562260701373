//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setFormData, index, setSetsList, setsList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const changeSetsList = (value, objTitle) => {
    let arr = [...setsList];
    arr[index][objTitle] = value;
    setSetsList(arr);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-2 xl:grid-cols-5 gap-3 mt-[33px]">
        <section>
          <label className="mb-2 text-md block">تکرار</label>
          {/*  <input
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (value <= 30) {
                changeSetsList(parseInt(e.target.value), "setRepeat");
              } else if (value > 30) {
                changeSetsList(parseInt(30), "setRepeat");
              } else if (value.length === 0) {
                changeSetsList(0, "setRepeat");
              }
            }}
            min={0}
            max={30}
            value={setsList[index].setRepeat}
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
          {/*   <input
            type="text" // تغییر به text به جای number
            onChange={(e) => {
              const value = e.target.value;

              // فقط اعداد و کاراکتر - مجاز باشد
              const cleanedValue = value.replace(/[^0-9-]/g, "");

              // جلوگیری از وجود دو dash پشت سر هم
              const noDoubleDash = cleanedValue.replace(/--+/g, "-");

              // حذف dash از ابتدا یا انتها
              const finalValue = noDoubleDash.replace(/^-+|-+$/g, "");

              // مقدار نهایی را در state ذخیره کن
              changeSetsList(finalValue, "setRepeat");
            }}
            value={setsList[index].setRepeat}
            placeholder="مثال: 3-5-7"
            className="border w-full border-[#EAEAEA] rounded-[10px]"
          /> */}
          <input
            type="text"
            value={setsList[index].setRepeat}
            onChange={(e) => {
              const value = e.target.value;

              // پذیرش فقط عدد و dash
              const cleanedValue = value.replace(/[^0-9-]/g, "");

              changeSetsList(cleanedValue, "setRepeat");
            }}
            placeholder="مثال: 3-5-7"
            className="border w-full border-[#EAEAEA] rounded-[10px] p-2"
          />
        </section>
        <section>
          <label className="mb-2 text-md block">tempo</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "tempo")} min={0} max={30} value={setsList[index].tempo} className="p-2 border w-full border-[#EAEAEA] rounded-[10px]" />
        </section>

        <section>
          <label className="mb-2 text-md block">زمان استراحت (ثانیه)</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "rest")} min={0} value={setsList[index].rest} className="p-2 border w-full border-[#EAEAEA] rounded-[10px]" />
        </section>
        <section>
          <label className="mb-2 text-md block">توان به درصد</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "tavan")} min={0} value={setsList[index].tavan} className="p-2 border w-full border-[#EAEAEA] rounded-[10px]" />
        </section>
        <section>
          <label className="mb-2 text-md block">وزن به کیلوگرم</label>
          <input type="number" onChange={(e) => changeSetsList(parseInt(e.target.value), "weight")} min={0} value={setsList[index].weight} className="p-2 border w-full border-[#EAEAEA] rounded-[10px]" />
        </section>
      </section>
    </>
  );
}
