//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

import { Fragment } from "react";

export default function Index({ data }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full  py-[27px] px-[16px] items-center xl:h-auto border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
      <section className="flex flex-wrap    gap-[10px] xl:gap-5 text-[13px] xl:text-base ">
        <span className="w-[109px] xl:w-[130px] text-[13px] xl:text-base flex justify-center text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4">بیماری های خاص</span>
        {data?.answerOutputDTOs && data?.answerOutputDTOs?.map((item, index) => <Fragment key={index}>{item?.diseaseDetail ? <span className="flex justify-center items-center rounded-[12px] bg-[#F1E8FC] text-[#5408A9] xl:h-[39px] py-3 xl:py-[15px] px-2 xl:px-[6px]">{item?.diseaseDetail?.persianName}</span> : ""}</Fragment>)}
      </section>
    </section>
  );
}
