//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4744_47963)">
        <path d="M0 8.91084C0.121875 8.63897 0.3375 8.54053 0.632813 8.54053C6.19688 8.54521 11.7656 8.54521 17.3297 8.54053C17.6906 8.54053 17.9859 8.6999 17.9672 9.19678C17.8875 11.2218 17.1094 12.9327 15.6844 14.3577C15.0656 14.9765 15.0609 14.9671 14.1938 14.9671C10.5141 14.9671 6.83906 14.9671 3.15938 14.9718C3.02344 14.9718 2.91563 14.939 2.8125 14.8499C1.34063 13.5983 0.398438 12.0468 0.084375 10.1296C0.0515625 9.93272 0.028125 9.74053 0 9.54365C0 9.33272 0 9.12178 0 8.91084Z" fill={fill} />
        <path
          d="M0 3.15956C0.028125 3.04237 0.05625 2.92518 0.0890625 2.808C0.571875 1.05956 2.71406 0.398622 4.10156 1.5705C4.19531 1.65018 4.26094 1.66425 4.37344 1.61269C5.31563 1.17206 6.44063 1.37362 7.17188 2.11425C7.90781 2.85956 8.11406 3.97518 7.66875 4.91737C7.62188 5.0205 7.62187 5.08144 7.69687 5.17519C8.2125 5.81269 8.38125 6.53925 8.2125 7.3455C8.18906 7.46737 8.1375 7.50956 8.01562 7.50019C7.75781 7.48612 7.47656 7.55644 7.25156 7.46737C7.03594 7.383 6.88125 7.13925 6.69844 6.96581C6.29531 6.57206 5.89687 6.183 5.50313 5.77987C5.44688 5.72362 5.40469 5.6205 5.40469 5.54081C5.39531 4.9455 5.4 4.35018 5.4 3.75019C5.4 3.52518 5.30156 3.35643 5.09531 3.258C4.90781 3.17362 4.72969 3.19706 4.56563 3.31893C4.42031 3.43144 4.36406 3.58612 4.36406 3.76425C4.36406 4.03612 4.36406 4.31269 4.36406 4.63612C4.28906 4.56581 4.24687 4.52831 4.20469 4.48143C3.68906 3.96581 3.17344 3.4455 2.65312 2.93456C2.34375 2.62987 1.86563 2.75643 1.76719 3.15956C1.71563 3.36581 1.79531 3.53456 1.94063 3.67987C2.45156 4.18612 2.95781 4.69706 3.46875 5.20331C3.51094 5.2455 3.55313 5.29237 3.62344 5.37206C3.3 5.37206 3.01875 5.36737 2.73281 5.37206C2.38594 5.38143 2.15625 5.64394 2.20312 5.97206C2.24062 6.23456 2.46094 6.408 2.775 6.408C3.35156 6.41268 3.93281 6.40331 4.50937 6.41268C4.60312 6.41268 4.71094 6.46425 4.77656 6.52518C5.05781 6.79237 5.325 7.06893 5.59688 7.3455C5.62969 7.383 5.6625 7.4205 5.71406 7.47675C5.62969 7.48144 5.57344 7.48612 5.51719 7.48612C3.95625 7.48612 2.39531 7.48612 0.834375 7.49081C0.69375 7.49081 0.628125 7.44394 0.58125 7.31737C0.323437 6.64237 0.3375 5.98143 0.6375 5.32518C0.684375 5.22206 0.679688 5.16581 0.604687 5.08144C0.295312 4.73456 0.103125 4.32206 0.0328125 3.85331C0.028125 3.8205 0.0140625 3.78768 0 3.75487C0 3.558 0 3.35644 0 3.15956Z"
          fill={fill}
        />
        <path
          d="M16.0535 3.95647C16.5551 3.89084 17.0426 3.89553 17.5207 4.02209C17.8395 4.10647 18.0176 4.37366 17.9473 4.65959C17.8723 4.95959 17.591 5.11897 17.2629 5.03928C16.8457 4.94084 16.4285 4.92678 16.016 5.04866C15.9926 5.05334 15.9738 5.06272 15.9926 5.05803C16.0957 5.35803 16.241 5.64866 16.2879 5.94866C16.3816 6.52991 16.166 7.02678 15.7254 7.42053C15.6832 7.45803 15.6129 7.48616 15.5566 7.48616C13.6441 7.49084 11.7316 7.48616 9.81914 7.48616C9.80508 7.48616 9.78633 7.47678 9.74414 7.46741C9.79102 7.40647 9.82852 7.35022 9.8707 7.30334C10.7004 6.30022 11.5488 5.30647 12.3645 4.28928C13.0348 3.45022 14.1457 3.37991 14.8629 4.00334C14.877 4.01272 14.891 4.01741 14.9238 4.03616C15.0551 3.67522 15.0504 3.31428 15.0035 2.94866C14.9941 2.85491 14.966 2.76584 14.9473 2.67209C14.891 2.37209 15.0551 2.10491 15.3316 2.03928C15.6082 1.97366 15.8848 2.13772 15.9645 2.42834C16.091 2.87834 16.1051 3.33772 16.0535 3.80178C16.0488 3.83459 16.0441 3.87209 16.0395 3.90491C16.0395 3.92366 16.0441 3.93303 16.0535 3.95647Z"
          fill={fill}
        />
        <path d="M8.98325 16.0217C11.1161 16.0217 13.2536 16.0217 15.3864 16.0217C15.7004 16.0217 15.8832 16.1342 15.9676 16.3733C16.0707 16.6733 15.8879 16.9967 15.5786 17.0577C15.4989 17.0717 15.4145 17.0764 15.3348 17.0764C11.102 17.0764 6.86918 17.0764 2.63168 17.0764C2.27543 17.0764 2.08793 16.9686 1.99887 16.7202C1.89106 16.4155 2.08325 16.0827 2.40668 16.0358C2.48168 16.0264 2.55668 16.0264 2.63637 16.0264C4.75043 16.0217 6.86918 16.0217 8.98325 16.0217Z" fill={fill} />
        <path d="M8.16797 1.63607C9.68203 0.417321 12.5555 0.984508 13.0805 2.58295C12.8227 2.70482 12.5414 2.7892 12.3164 2.95326C12.0352 3.15482 11.768 3.3892 11.543 3.6517C10.8305 4.48607 10.1367 5.3392 9.43359 6.18295C9.40078 6.22045 9.36328 6.25795 9.33516 6.29545C9.23672 5.98607 9.17578 5.65795 9.03984 5.37201C8.88984 5.05795 8.83828 4.7767 8.89922 4.41576C9.07734 3.41732 8.82891 2.50326 8.21484 1.69701C8.19609 1.68295 8.18672 1.6642 8.16797 1.63607Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_4744_47963">
          <rect width="18" height="18" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}
