import React from "react";
import Section3 from "./components/Section3";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section4 from "./components/Section4";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="md:w-[1360px] w-[90%] mx-auto">
      <Section1 />
      <section className="xl:w-[1124px]  w-[90%] mx-auto mb-[60px] md:mb-[150px] mt-[60px] md:mt-[106px] ">
        <h2 className="text-[#191919] text-center font-bold text-[30px] md:leading-[49.6px] md:text-[40px] ">خدمات ما</h2>
        <section className=" overflow-x-scroll xl:overflow-x-visible h-auto w-full">
          <Section2 />
        </section>
      </section>
      <section className="xl:w-[1124px]  w-[90%] mx-auto  ] ">
        <Section4 />
      </section>
      <section className="xl:w-[1124px]  w-[90%] mx-auto  mb-[60px] md:mb-[150px] mt-[60px] md:mt-[106px] ">
        <Section3 />
      </section>
    </section>
  );
}
