import Table from "../../../components/common/Table";
import api from "../../../api";
import { useEffect, useState } from "react";
import Button from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";
import ModalContent from "./ModalContent";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const cols = [
    { title: "شناسه", field: "id" },
    { title: "نام", field: "foodName" },
    { title: "کالری", field: "calories" },
    { title: "پروتئین", field: "protein" },
    { title: "کربوهیدرات", field: "carbohydrate" },
    { title: "چربی", field: "fat" },
  ];
  const actions = [{ type: "delete" }];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [reload, setReload] = useState(false);

  const [open, setOpen] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const onCloseBtn = () => {
    setOpen(false);
  };
  const reloadTable = () => {
    setReload(!reload);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <h1 className="font-bold text-xl"> تعریف مکمل</h1>

      <Table
        api={api.admin.getRecipe}
        apiDel={api.admin.deleteRecipe}
        reload={reload}
        actions={actions}
        cols={cols}
      />
    </>
  );
}
