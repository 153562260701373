import Table from "../../../components/common/Table";
import api from "../../../api";
import { useState } from "react";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const cols = [
    { title: "شناسه", field: "id" },
    { title: "نام", field: "firstName" },
    { title: "نام خانوادگی", field: "lastName" },
    { title: "شماره موبایل", field: "mobileNumber" },
    { title: "ایمیل", field: "email" },
    { title: "توضیحات", field: "description" },
  ];

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [reload, setReload] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <h1 className="font-bold text-xl">تماس با ما</h1>
      <Table api={api.admin.getContactUsList} reload={reload} cols={cols} />
    </>
  );
}
