import { Fragment, useEffect, useState } from "react";
import Filter from "./components/Filter";
import api from "../../../../../../../api";
import { useSearchParams } from "react-router-dom";
import SetForm from "./components/SetForm";
import { AsyncSelect, Button, Select } from "../../../../../../../components/common";
import { useAxiosWithToken } from "../../../../../../../hooks";
import { notify } from "../../../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setMovementList, exerciseId, setExerciseId }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  const [setCount, setSetCount] = useState();
  const [setsList, setSetsList] = useState([]);
  const [resetSets, setResetSets] = useState(true);
  const [addExerciseLoading, setAddExerciseLoading] = useState(false);

  const [exerciseObj, setExerciseObj] = useState({
    /*  requestId: parseInt(searchParams.get("userId")),
    username: searchParams.get("mobileNumber"), */
    trainingSystem: "",
    muscleId: null,
    exerciseName: "",
    description: "",
    monthEnum: "FIRST",
    weekEnum: "FIRST",
    dayEnum: "FIRST",
  });

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const setFormData = (obj) => {
    setExerciseObj({ ...exerciseObj, ...obj });
  };
  const generateSets = (count) => {
    let arr = [];
    for (var i = 0; i < count; i++) {
      arr.push({
        id: i + 1,
        setRepeat: "",
        tempo: 0,
        rest: 0,
        tavan: 0,
        weight: 0,
      });
    }
    setSetsList(arr);
  };
  const saveExDtl = () => {
    setLoading(true);
    let params = {
      completeDescription: exerciseObj?.description,
      personalSportProgramDetail: {
        ...exerciseObj,
        id: exerciseId,
        sets: setsList,
        setCount: setCount,
      },
    };
    useAxiosWithToken
      .post(api.personalProgram.addPersonalSportProgramDtl + exerciseId, params)
      .then((res) => {
        notify.Success("با موفقیت ذخیره شد");
        setLoading(false);
        setResetSets(false);
        setMovementList(res?.data?.personalSportProgramDetails)
        setTimeout(() => {
          ResetFields();
        }, 300);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const ResetFields = () => {
    setResetSets(true);
    setSetsList([]);
    setSetCount(0);
    setFormData({
      trainingSystem: "",
      muscleId: null,
      exerciseName: "",
      description: "",
    });
  };
  const createExercise = () => {
    setAddExerciseLoading(true);
    let params = {
      requestId: parseInt(searchParams.get("userId")),
      username: searchParams.get("mobileNumber"),
    };
    useAxiosWithToken
      .post(api.personalProgram.addPersonalSportProgram, params)
      .then((res) => {
        setAddExerciseLoading(false);
        setExerciseId(res?.data?.id);
      })
      .catch((err) => {
        setAddExerciseLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    generateSets(setCount);
  }, [setCount]);


  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mt-[20px] border border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]">
        <Filter setExerciseId={setExerciseId} exerciseId={exerciseId} addExerciseLoading={addExerciseLoading} setFormData={setFormData} createExercise={createExercise} exerciseObj={exerciseObj} />
        {exerciseId && resetSets && (
          <>
            <section className="w-full  py-[27px] px-[16px] items-center  border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
              <section className="grid grid-cols-1 xl:grid-cols-3 gap-3 mt-[33px]">
                <section>
                  <label className="mb-2 text-md block">سیستم تمرینی</label>
                  <AsyncSelect resElements={true} authentication={true} setState={(e) => setFormData({ trainingSystem: e.value })} api={api.exercise.getExerciseSystem + "?pageNumber=0&pageSize=200"} optionTitle="persianName" optionValue="persianName" title="سیستم تمرینی" />
                </section>
                <section>
                  <label className="mb-2 text-md block">دسته بندی عضلات</label>
                  <AsyncSelect resElements={true} authentication={true} setState={(e) => setFormData({ muscleId: e.value })} api={api.exercise.getMuscles + "?pageNumber=0&pageSize=200"} optionTitle="persianName" optionValue="id" title="دسته بندی عضلات" />
                </section>
                <section>
                  <label className="mb-2 text-md block">نام حرکت</label>
                  <AsyncSelect condition={true} conditionValue={exerciseObj?.muscleId} resElements={true} authentication={true} setState={(e) => setFormData({ exerciseName: e.value })} api={api.exercise.getExercise + `?muscleId=${exerciseObj?.muscleId}&pageNumber=0&pageSize=100`} optionTitle="persianTitle" optionValue="persianTitle" title="نام حرکت" />
                </section>
              </section>
              <section className="grid grid-cols-1 xl:grid-cols-3 gap-3">
                <section>
                  <label className="mb-2 mt-4 text-md block">تعداد ست</label>
                  <Select
                    setState={(e) => setSetCount(e.value)}
                    title="تعداد ست"
                    options={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                      { label: 10, value: 10 },
                    ]}
                  />
                </section>
              </section>
            </section>
            {setsList.map((item, index) => (
              <Fragment key={index}>
                <SetForm index={index} setsList={setsList} setSetsList={setSetsList} />
              </Fragment>
            ))}
            <section className="col-span-2 flex items-center h-[157px] border mt-[30px] border-[#E6E8E9] relative rounded-[10px]">
              <span className="w-[72px] text-[#3E4151] font-bold absolute top-[-12px] bg-white right-4">توضیحات</span>
              <textarea className="resize-none p-3 py-5 text-[14px] text-[#3E4151] border-none w-[98%] h-[99%] m-auto" placeholder="توضیح خود را بنویسید" onChange={(e) => setFormData({ description: e.target.value })}></textarea>
            </section>
            <Button loading={loading} onClick={saveExDtl} className="bg-purple m-auto mt-[35px] text-white w-full h-[40px] rounded-md text-sm flex justify-center gap-1 items-center">
              ذخیره حرکت
            </Button>
          </>
        )}
      </section>
    </>

  );
}
