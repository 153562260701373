import { useState } from "react";
import { imagesFileUrl, svgFileUrl } from "../../../../config";
import moment from "moment-jalaali";
import MobileMenu from "./components/MobileMenu";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [menuOpen, setMenuOpen] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full  mx-auto h-[54px] mt-10 flex justify-between items-center">
        <section></section>
        {/*  <section className="w-[135px]">
          <section className="w-full flex justify-between items-center">
            <section className="text-[14px] font-bold hiText">وقت بخیر، {basicInfo.firstName}</section>
            <img src={baseFileUrl + "/images/dashboard/hi.png"} alt="" />
          </section>
          <span className="text-[12px] font-normal text-[#333333]">بریم که باهم شروع کنیم</span>
        </section> */}
        {/*  <section className="w-[432px] flex justify-between">
      <section className="w-[368px] relative">
        <input
          placeholder="جستجو"
          type="text"
          className="w-full h-[40px] pr-[45px] rounded-[172px] shadow-md"
        />
        <img
          src={baseFileUrl + '/images/dashboard/search.svg'}
          className="absolute top-3 right-5"
          alt=""
        />
      </section>
      <section className="w-[44px] h-[44px] bg-white shadow-lg rounded-full relative flex justify-center items-center cursor-pointer">
        <section className="w-[14px] h-[14px] bg-white flex justify-center rounded-full items-center absolute top-0 right-0">
          <span className="block w-[10px] h-[10px] rounded-full bg-[#DC1B1B]"></span>
        </section>
        <img src={baseFileUrl+"/images/dashboard/ring.svg"} alt=''/>
      </section>
    </section> */}
        <section className="w-[122px] flex justify-center ">
          <span className="text-purple font-extrabold">{moment().format("jYYYY/jMM/jDD")}</span>
          <img src={svgFileUrl + "calender.svg"} className="w-[35px] h-[20px] mr-1" alt="" />
        </section>
      </section>
    </>
  );
}
