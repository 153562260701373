import React, { useState, useContext, useEffect } from 'react'

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ rowData }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className="w-full min-h-[70px] rounded-lg">
            <section className="grid grid-cols-1 xl:grid-cols-7 py-2 text-xs text-center">
                <section className="w-full font-bold">شناسه</section>
                <section className="w-full font-bold">نام غذا</section>
                <section className="w-full font-bold">مقدار</section>
                <section className="w-full font-bold">کالری</section>
                <section className="w-full font-bold">پروتئین</section>
                <section className="w-full font-bold">چربی</section>
                <section className="w-full font-bold">کربوهیدرات</section>
            </section>
            <section className="grid grid-cols-1 xl:grid-cols-7 py-2 font-semibold text-[13px] text-center text-purple">
                {rowData?.foodProgramPackageDetails.map((item, index) => (<React.Fragment key={index}>
                    <span>{item?.id}</span>
                    <span>{item?.mainFoodDescription}</span>
                    <span>
                        {Math.trunc(item?.consumedMeasure)} {item?.consumedMeasureFa}
                    </span>
                    <span>{Math.trunc(item?.energyKcal)}</span>
                    <span>{Math.trunc(item?.proteinG)}</span>
                    <span>{Math.trunc(item?.totalFatG)}</span>
                    <span>{Math.trunc(item?.carbohydrateG)}</span>
                </React.Fragment>))}

            </section>
            <hr />
            <section className="grid grid-cols-1 xl:grid-cols-7 text-center py-2 font-semibold  text-black">
                <span></span>
                <span></span>
                <span>
                    <span>جمع کل</span>
                </span>
                <span>{Math.trunc(rowData?.totalCalorie)}</span>
                <span>{Math.trunc(rowData?.totalProtein)}</span>
                <span>{Math.trunc(rowData?.totalFat)}</span>
                <span>{Math.trunc(rowData?.totalCarbohydrate)}</span>
            </section>
            <hr />
            <section className="text-darkPink text-center py-2">{rowData?.description}</section>
        </section>
    )
}
