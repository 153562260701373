import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { imagesFileUrl, svgFileUrl } from "../../../config";
import MobileMenu from "./components/MobileMenu";
import DesktopMenu from "./components/DesktopMenu";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
function Index({ mode }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClass, setMenuClass] = useState(mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : "");
  /*   useEffect(() => {
    console.log(scrollYPosition);
  }, [scrollYPosition]); */

  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <DesktopMenu menuClass={menuClass} setMenuClass={setMenuClass} mode={mode} />
      <section className={`grid grid-cols-3 px-[18px] fixed xl:hidden items-center w-full left-0 top-0 h-[64px] transition-all duration-500 z-[1000]`} style={{ backgroundColor: menuClass }}>
        <img onClick={() => setMenuOpen(!menuOpen)} src={svgFileUrl + "menuWhite.svg"} className="mobileMenuBtn cursor-pointer" alt="" />
        <Link to="/">
          <img className=" h-[60px]" src={imagesFileUrl + "logo-new.png"} alt="" />
        </Link>
        <MobileMenu open={menuOpen} setOpen={setMenuOpen} />
      </section>
    </>
  );
}

export default React.memo(Index);
