/* import Section1 from "./components/Section1";
import Section2 from "./components/Section2"; */
import Section3 from "./components/Section3";
// import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/section7";
import Section8 from "./components/Section8";
import Section10 from "./components/Section10";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[100px]">
      {/* <Section1 /> */}
      {/* <Section2 /> */}
      <Section3 />
      {/*<Section4 />*/}
      <Section5 />
      <Section6 />
      <Section7 />
      {/* <Section10 /> */}
      <Section8 />
    </section>
  );
}
