import { useState, useEffect } from "react";
import { AsyncSelect, ComponentLoading } from "../../../../../../../../../../../components/common";
import api from "../../../../../../../../../../../api";
import { useAxiosWithToken } from "../../../../../../../../../../../hooks";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setDesc, onClose }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [listLoading, setListLoading] = useState(false);
  const [foodCategory, setFoodCategory] = useState("");
  const [descriptionList, setDescriptionList] = useState([]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getDescriptionList = (id) => {
    setListLoading(true);
    useAxiosWithToken
      .get(api.doctor.getMedicalDescriptionList + "?medicalDescriptionCategoryId=" + id)
      .then((res) => {
        setDescriptionList(res.data);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (foodCategory?.value) {
      getDescriptionList(foodCategory?.value);
    }
  }, [foodCategory]);
  useEffect(() => {
    console.log(descriptionList);
  }, [descriptionList]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="">
        <section>
          <label>دسته بندی</label>
          <AsyncSelect className="mt-2" resElements={true} authentication={true} api={api.doctor.getMedicalDescriptionCategory} optionTitle="description" optionValue="id" state={foodCategory} setState={setFoodCategory} />
        </section>
        <section className="min-h-[300px] p-2 border rounded-[10px] border-slate-300 mt-[20px]">
          <section>
            <section className="h-[230px] overflow-y-scroll relative">
              <ComponentLoading loading={listLoading} />
              {descriptionList.length === 0 && !listLoading && <span className="text-[18px] font-bold text-red-500 block text-center p-3">رکوردی یافت نشد</span>}
              {descriptionList.length > 0 &&
                descriptionList.map((item, Index) => (
                  <section
                    onClick={() => {
                      setDesc(item.description);
                      onClose();
                    }}
                    key={Index}
                    className="font-normal flex justify-between text-[14px] p-2 cursor-pointer hover:bg-slate-200 transition-all"
                  >
                    <span className="text-purple">
                      {Index + 1}- {item.description}
                    </span>
                  </section>
                ))}
            </section>
          </section>
        </section>

        <section className="flex justify-between items-center mt-3">
          {/* <section className="font-bold text-sm text-purple">موارد انتخاب شده: {selectedFoodList.length}</section>
          <Button loading={loading} disabled={selectedFoodList.length < 1} onClick={saveList} className="h-[40px] w-[150px]">
            <img src={svgFileUrl + "plus-white.svg"} alt="" />
            <span>افزوردن</span>
          </Button> */}
        </section>
      </section>
    </>
  );
}
