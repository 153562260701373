//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeTab, setActiveTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-[80px] mb-8 flex justify-between items-center xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-8 xl:px-[86px]">
        <button onClick={() => setActiveTab("BREAKFAST")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "BREAKFAST" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          صبحانه
        </button>
        <button onClick={() => setActiveTab("LUNCH")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "LUNCH" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          ناهار
        </button>
        <button onClick={() => setActiveTab("DINNER")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "DINNER" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          شام
        </button>
        <button onClick={() => setActiveTab("SNACK_BEFORE_LUNCH")} className={`w-[200px] h-[48px] rounded-full ${activeTab === "SNACK_BEFORE_LUNCH" ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-sm xl:text-xl`}>
          میان وعده
        </button>
      </section>
    </>
  );
}
