import { imagesFileUrl } from "../../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveTab, activeTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {/*      <section className="w-[1132px] xl:gap-0 gap-[40px] h-[92px] mt-[40px]  flex justify-between">
        <section className="w-[900px] m-auto max-w-full flex justify-center gap-[60px] items-center">
          <button onClick={() => setActiveTab(4)} className={`w-[210px] ${activeTab === 4 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
            <img alt="" src={imagesFileUrl + `icons/${activeTab === 4 ? `tools-1-w` : `tools-1`}.svg`} />
            <span className="font-bold"> برنامه ریز بادینس</span>
          </button>
          <button onClick={() => setActiveTab(3)} className={`w-[210px] ${activeTab === 3 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
            <img alt="" src={imagesFileUrl + `icons/${activeTab === 3 ? `tools-2-w` : `tools-2`}.svg`} />

            <span className="font-bold">شاخص LBM</span>
          </button>
          <button onClick={() => setActiveTab(2)} className={`w-[210px] ${activeTab === 2 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
            <img alt="" src={imagesFileUrl + `icons/${activeTab === 2 ? `tools-3-w` : `tools-3`}.svg`} />

            <span className="font-bold"> شاخص توده بدنی</span>
          </button>
          <button onClick={() => setActiveTab(1)} className={`w-[210px] ${activeTab === 1 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
            <img alt="" src={imagesFileUrl + `icons/${activeTab === 1 ? `tools-4-w` : `tools-4`}.svg`} />

            <span className="font-bold ">آب شمار</span>
          </button>
        </section>
      </section> */}
      <section className=" mt-[40px] grid grid-cols-2 gap-2 xl:gap-8 xl:grid-cols-4 text-center xl:px-[100px]">
        <button onClick={() => setActiveTab(4)} className={`w-full  ${activeTab === 4 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
          <img alt="" src={imagesFileUrl + `icons/${activeTab === 4 ? `tools-1-w` : `tools-1`}.svg`} />
          <span className="font-bold"> برنامه ریز بادینس</span>
        </button>
        <button onClick={() => setActiveTab(3)} className={` w-full ${activeTab === 3 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
          <img alt="" src={imagesFileUrl + `icons/${activeTab === 3 ? `tools-2-w` : `tools-2`}.svg`} />

          <span className="font-bold">شاخص LBM</span>
        </button>
        <button onClick={() => setActiveTab(2)} className={`] w-full ${activeTab === 2 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
          <img alt="" src={imagesFileUrl + `icons/${activeTab === 2 ? `tools-3-w` : `tools-3`}.svg`} />

          <span className="font-bold"> شاخص توده بدنی</span>
        </button>
        <button onClick={() => setActiveTab(1)} className={` w-full ${activeTab === 1 && `toolsBg text-white`} h-[60px] bg-white rounded-lg flex justify-center items-center gap-2 text-[14px] shadow-[0px_2.53px_16.86px_-6.75px_#0000004D]`}>
          <img alt="" src={imagesFileUrl + `icons/${activeTab === 1 ? `tools-4-w` : `tools-4`}.svg`} />

          <span className="font-bold ">آب شمار</span>
        </button>
      </section>
    </>
  );
}
