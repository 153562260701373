import { Link, useLocation } from "react-router-dom";
import { imagesFileUrl } from "../../../../config";
import MenuItem from "./components/MenuItem";
import Dashboard from "./svg/Dashboard";
import Profile from "./svg/Profile";
import Target from "./svg/Target";
import Recipe from "./svg/Recipe";
import Recipe2 from "./svg/Recipe2";
import Ticket from "./svg/Ticket";
import MealPlan from "./svg/MealPlan";
import ExercisePlan from "./svg/ExercisePlan";
import Exit from "./svg/Exit";
import { isAdmin, isCoach, isDoctor } from "../../../../helper";
import { Fragment } from "react";
import MobileMenu from "./components/MobileMenu";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const location = useLocation();
  const menuItems = [
    {
      title: "داشبورد",
      link: "/dashboard",
      active: location.pathname === "/dashboard",
      icon: Dashboard,
    },
    {
      title: "پروفایل",
      link: "/dashboard/basicInfo",
      active: location.pathname.substring(11) === "basicInfo",
      icon: Profile,
    },
    {
      title: "رسپی",
      link: "/dashboard/recipe",
      active: location.pathname.substring(11, 17) === "recipe",
      icon: Recipe2,
    },
    {
      title: "نسخ پزشکی",
      link: "/dashboard/medical-prescription",
      active: location.pathname.substring(11) === "medical-prescription",
      icon: Recipe,
    },
    {
      title: "مشاهده برنامه ورزشی",
      icon: ExercisePlan,
      active: location.pathname.substring(11) === "exercise-list",
      link: "/dashboard/exercise-list",
    },

    {
      title: "مشاهده برنامه غذایی",
      icon: MealPlan,
      link: "/dashboard/food-program-list",
      active: location.pathname.substring(11) === "food-program-list",
    },
    {
      title: "خرید برنامه ورزشی",
      link: "/exercise-plan",
      icon: ExercisePlan,
    },
    {
      title: "خرید برنامه غذایی",
      link: "/meal-plan",
      icon: MealPlan,
    },
    {
      title: "تراکنش ها",
      link: "/dashboard/transaction-list",
      active: location.pathname.substring(11) === "transaction-list",
      icon: MealPlan,
    },
    {
      title: "ارسال تیکت",
      link: "/dashboard/ticket",
      active: location.pathname.substring(11) === "ticket",
      icon: Ticket,
    },
    { title: "بازگشت به سایت", link: "/", icon: Target },
    { title: "خروج", mode: "logOut", icon: Exit },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <MobileMenu />
      <aside className="w-[199px] hidden xl:block">
        <Link to="/">
          <img className="w-[100px] m-auto" src={imagesFileUrl + "logoWhite.png"} alt="" />
        </Link>
        <nav className="mt-2">
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <MenuItem title={item.title} link={item.link} active={item.active} Icon={item.icon} mode={item.mode} />
            </Fragment>
          ))}
          {/*  <section className="mt-2 flex flex-col gap-2">
            <Button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_men.pdf")} className="rounded-full">
              دریافت پلنر آقایان
            </Button>
            <Button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_women.pdf")} className="rounded-full">
              دریافت پلنر بانوان
            </Button>
          </section> */}
          {isDoctor() && (
            <Link className="p-2 rounded-md block text-white pr-4 bg-purple mt-5" to="/doctor-dashboard/without-plan-user">
              داشبورد دکتر
            </Link>
          )}
          {isAdmin() && (
            <Link className="p-2 rounded-md block text-white pr-4 mt-2 bg-purple" to="/admin">
              پنل مدیریت
            </Link>
          )}
          {isCoach() && (
            <Link className="p-2 rounded-md block text-white pr-4 bg-purple mt-2" to="/coach-dashboard/without-plan-user">
              داشبورد مربی
            </Link>
          )}
          {/* {isDoctor() && <MenuItem title="داشبورد دکتر" link={"/doctor-dashboard/food-package"} active={location.pathname === "/doctor-dashboard/food-package"} Icon={Dashboard} />}
          {isCoach() && <MenuItem title="داشبورد مربی" link={"/coach-dashboard/additional-info"} active={location.pathname === "/coach-dashboard/additional-info"} Icon={Dashboard} />} */}
        </nav>

        <section className="w-full mt-20 relative">
          <img src={imagesFileUrl + "dashboard/app.png"} alt="" />
          <section className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center">
            <section className="text-center">
              <span className="block text-white text-[22px] font-normal"> دانلود پلنر</span>
              {/*  <span className="block text-white text-[22px] font-bold">اپلیکیشن</span> */}
            </section>
            <section className="w-[105px] h-[105px] bg-white rounded-[6px] p-1 mt-4">
              <img src={imagesFileUrl + "logoWhite.png"} alt="" />
            </section>
            <button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_men.pdf")} className="w-[120px] h-[32px] text-sm font-bold text-[#191919] mt-6 cursor-pointer rounded-full bg-gradient-to-r from-[#FAFF00] to-[#FF6523]">
              دریافت پلنر آقایان
            </button>
            <button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_women.pdf")} className="w-[120px] h-[32px] text-sm font-bold text-[#191919] mt-6 cursor-pointer rounded-full bg-gradient-to-r from-[#FAFF00] to-[#FF6523]">
              دریافت پلنر بانوان
            </button>
          </section>
        </section>
      </aside>
    </>
  );
}
