import { useEffect, useState } from "react";
import Item from "./components/Item";
import { imagesFileUrl } from "../../../../config";
import { Button } from "../../../../components/common";
import MobilePackage from "./components/MobilePackage";
import { Link } from "react-router-dom";
/* import { useAxiosWithToken } from "../../../../hooks";
import api from "../../../../api";
import { useNavigate } from "react-router-dom"; */

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
/* const Item = ({ children }) => {
  return <span className="bg-[#F1E8FC] px-[14px] text-[14px] font-normal py-[12px] h-[33px] text-purple rounded-full flex justify-center items-center">{children}</span>;
}; */
export default function Index({ normalPkgId, vipPkgId, id }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {}, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="hidden xl:flex items-start gap-[16px] mt-[64px]">
        <section className="border group/normal border-[#E6E8E9] rounded-2xl py-[36px] px-[28px] cursor-pointer overflow-hidden h-auto transition-all duration-200  hover:shadow-[0px_8px_16px_-6px_#5408A933]">
          <section className="flex justify-start">
            <span className="bg-[#F9F5FE] text-purple font-bold flex justify-center items-center w-[130px] h-[42px] rounded-full "> خرید بسته عادی</span>
          </section>
          <section className="mt-[40px]">
            <Item type="checked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر (چربی‌سوزی، افزایش حجم عضلات و ..." />
            <Item type="checked" title="ارائه تمرین‌های عمومی و پایه" />
            <Item type="unchecked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر، مانند چربی‌سوزی و عضله‌سازی." />
            <Item type="unchecked" title="گزارش‌های پیشرفت منظم:  تحلیل پیشرفت کاربر در تمرینات و ارائه بازخوردهای شخصی‌سازی‌شده به طور ماهانه" />
            <Item type="unchecked" title="پشتیبانی اختصاصی : ارتباط مستقیم با مربیان  از طریق چت آنلاین" />
            <Item type="unchecked" title="امکان سفارشی‌سازی تمرینات: کاربران می‌توانند به‌راحتی برنامه‌ها را بر اساس نیازهای خود تغییر دهند" />
            <Item type="unchecked" title="امکان ثبت‌نام در چالش‌های خاص: چالش‌های فصلی یا مناسبتی " />
            <Item type="unchecked" title="ایونت های ورزشی : امکان حضور در ایونت های ورزشی با حضور مربی " />
            <Item type="unchecked" title="سمینار های آنلاین  : شرکت در سمینار های آموزشی و رفع اشکال" />
            <Item type="unchecked" title="ثبت و مشاهده رکوردها: مشاهده دستاوردهای پیشرفت در هر بخش با استفاده از بادینس پلنر" />
          </section>
          <section className="flex w-full h-[60px] justify-center items-center gap-2">
            <span className="text-[32px] font-bold">1,200,000</span>
            <span className="text-[16px] text-[#A6A9BD] font-normal">تومان</span>
          </section>
          <Link to={`/shopping-cart/${normalPkgId}`}>
            <Button className="w-full  rounded-full h-[40px] mt-[47px] transition-all duration-200">خرید بسته عادی</Button>
          </Link>
        </section>
        <section className="border group/vip border-[#E6E8E9] overflow-hidden  rounded-2xl py-[36px] px-[28px] cursor-pointer h-auto transition-all duration-2000 hover:shadow-[0px_8px_16px_-6px_#5408A933]">
          <section className="flex justify-start">
            <span className="bg-[#FEF4F9] text-[#F02484] flex justify-center items-center font-bold w-[210px] gap-2 h-[42px] rounded-full ">
              <img className="w-[21px] h-[21px]" src={imagesFileUrl + "icons/vip-badge.png"} alt="" />
              خرید بسته 3 ماهه ویژه
            </span>
          </section>
          <section className="mt-[40px]">
            <Item type="checked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر (چربی‌سوزی، افزایش حجم عضلات و ..." />
            <Item type="checked" title="ارائه تمرین‌های عمومی و پایه" />
            <Item type="checked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر، مانند چربی‌سوزی و عضله‌سازی." />
            <Item type="checked" title="گزارش‌های پیشرفت منظم:  تحلیل پیشرفت کاربر در تمرینات و ارائه بازخوردهای شخصی‌سازی‌شده به طور ماهانه" />
            <Item type="checked" title="پشتیبانی اختصاصی : ارتباط مستقیم با مربیان  از طریق چت آنلاین" />
            <Item type="checked" title="امکان سفارشی‌سازی تمرینات: کاربران می‌توانند به‌راحتی برنامه‌ها را بر اساس نیازهای خود تغییر دهند" />
            <Item type="checked" title="امکان ثبت‌نام در چالش‌های خاص: چالش‌های فصلی یا مناسبتی " />
            <Item type="checked" title="ایونت های ورزشی : امکان حضور در ایونت های ورزشی با حضور مربی " />
            <Item type="checked" title="سمینار های آنلاین  : شرکت در سمینار های آموزشی و رفع اشکال" />
            <Item type="checked" title="ثبت و مشاهده رکوردها: مشاهده دستاوردهای پیشرفت در هر بخش با استفاده از بادینس پلنر" />
          </section>
          <section className="flex w-full h-[60px] justify-center items-center gap-2">
            <span className="text-[32px] font-bold">4,000,000</span>
            <span className="text-[16px] text-[#A6A9BD] font-normal">تومان</span>
          </section>
          <Link to={`/shopping-cart/${vipPkgId}`}>
            <Button className="w-full  rounded-full h-[40px] mt-[47px] transition-all duration-200">خرید بسته VIP</Button>
          </Link>
        </section>
      </section>
      <MobilePackage normalPkgId={normalPkgId} vipPkgId={vipPkgId} />
    </>
  );
}
