import { Link } from "react-router-dom";
import Close from "../../../../../assets/images/basicInfo/close.png";
import ArrowLeft from "../../../../../assets/images/basicInfo/arrowLeft.png";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ activeStep, stepPic, setStep }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="flex w-full xl:w-[1000px] h-[50px] max-w-full items-center justify-around bg-[#fcfafe] rounded-[24px] m-auto relative">
      <Link to="/">
        <img className="w-[30px] h-[30px]" src={Close} alt="" />
      </Link>
      <section className="flex gap-2 justify-between w-[190px] xl:w-[570px] ltr h-[3px] xl:h-[7px]">
        <span className={`block w-[40%] xl:w-full ${activeStep > 0 ? `bg-purple` : "bg-slate-200 "}  h-full rounded-md`}></span>
        <span className={`block w-[40%] xl:w-full ${activeStep > 1 ? `bg-purple` : "bg-slate-200 "}  h-full rounded-md`}></span>
        <span className={`block w-[40%] xl:w-full ${activeStep > 2 ? `bg-purple` : "bg-slate-200 "}  h-full rounded-md`}></span>
        <span className={`block w-[40%] xl:w-full ${activeStep > 3 ? `bg-purple` : "bg-slate-200 "}  h-full rounded-md`}></span>
      </section>
      {activeStep === 3 ? <img src={ArrowLeft} alt="" className="arrow cursor-pointer w-[30px] h-[30px] xl:w-[48px] xl:h-[48px]" onClick={() => setStep(activeStep - 1)} /> : <span className="block w-[30px] h-[30px] xl:w-[48px] xl:h-[48px]"></span>}
    </section>
  );
}
