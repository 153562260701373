import { useState, useContext, useEffect } from "react";
import { svgFileUrl } from "../../config";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../../components/common";
import { useStartLink } from "../../hooks";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const type = searchParams.get("questionType");
  const [startLink] = useStartLink();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full pb-10 xl:h-[600px] bg-[#F6F6FB] flex flex-col items-center justify-center  ">
        <section className="w-[718px] max-w-[95%] m-auto mt-[50px] pb-[50px] bg-white rounded-[24px] px-8">
          <img src={svgFileUrl + "callbackCheck.svg"} className="m-auto mt-[32px]" alt="" />
          <span className={`block mt-8 text-xl font-semibold text-center text-[#1CAE81]`}>خرید برنامه {type === "1" ? "ورزشی" : type === "2" ? "پزشکی" : ""} شما با موفقیت انجام شد</span>
          {type === "1" && (
            <>
              <p className="border-t-[2px] font-bold border-[#b9bbc0] mt-[32px] pt-8 border-dashed text-center block"> مربی پس از 72 ساعت با بررسی اطلاعات شخصی شما، برنامه را ثبت می کند.</p>
              <p className="mt-[20px] text-purple  font-bold text-center block"> کاربر عزیز شما نیز می توانید بعد از 72 ساعت برنامه ورزشی خود را با مراجعه به بخش داشبورد مشاهده نمایید. </p>
            </>
          )}
          {type === "2" && (
            <>
              <p className="border-t-[2px] font-bold border-[#b9bbc0] mt-[32px] pt-8 border-dashed text-center block"> متخصصان تغذیه ما پس از 72 ساعت با بررسی اطلاعات شخصی شما، برنامه را ثبت می کنند.</p>
              <p className="mt-[20px] text-purple  font-bold text-center block"> کاربر عزیز شما نیز می توانید بعد از 72 ساعت برنامه غذایی خود را با مراجعه به بخش داشبورد مشاهده نمایید. </p>
            </>
          )}
          <Link to={startLink}>
            <Button className="m-auto mt-[80px]">بزن بریم</Button>
          </Link>
        </section>
      </section>
    </>
  );
}
