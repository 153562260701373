import React from "react";
import { imagesFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full h-auto py-5 xl:py-[44px] xl:px-[40px] xl:mt-[40px] xl:mb-[100px] mx-auto rounded-[10px] bg-[#F9F5FE]">
        <section className=" w-[95%] xl:w-[1053px] h-auto pt-4 xl:py-[61px] xl:px-[32px] mx-auto bg-[#FFFFFF] pb-[45px] shadow-[0px_6px_14px_0px_#0000000f] rounded-[10px] ">
          <section className="w-[90%] xl:w-full h-auto xl:h-[241px] mx-auto text-[#191919]">
            <h2 className="text-[18px] leading-[27.9px] font-bold ">بادینس پلنر (PLANER)</h2>
            <h3 className="leading-[24.8px] font-bold mt-[18px]">مزایای برنامه ریز تمرین چیست؟</h3>
            <p className="leading-[24.8px] font-normal text-justify">
              تناسب اندام نیاز به برنامه ریزی ، پایبندی ، استمرار ، ثبت و پایش تغییرات از روز اول تا رسیدن به اهدف شما داره. پلنر به شما در ایجاد خلاقیت بیشتر کمک می کنه. بسیاری از کارشناسان تناسب اندام توصیه می کنن که یک برنامه ایجاد کنی، تا بتونی پیشرفتی رو که انجام دادی ببینی، و به آرامی به اهداف خود برسی.
              <br />
              پیشنهاد میکنیم یک دوره 6 تا رسیدن به اهدافتون برنامه ریزی کنید. دلیلشم اینه که نه اونقدر کوتاهه که نشه انجامش داد و نه اونقدر بلند مدته که شما رو نسبت به هدفی که داری خسته کنه. پلنر به شما کمک میکنه تا نسخه ای کامل تر از خودت بسازی.
            </p>
          </section>
          <span className="block px-[14px] font-bold text-[18px] mt-[8px]">از اینجا میتونی برنامه‌ریز مناسب خودت رو دانلود کنی:</span>
          <section className="flex flex-col px-10 xl:px-0 xl:flex-row mt-[20px] justify-start gap-5 w-full">
            <Button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_men.pdf")} className="rounded-full">
              دریافت پلنر آقایان
            </Button>
            <Button onClick={() => window.open("https://cc.bodyness.ir/constanct-content/planners/planner_women.pdf")} className="rounded-full">
              دریافت پلنر بانوان
            </Button>
          </section>
          <section className="w-[95%] xl:w-full h-auto xl:h-[1068px] mt-8 mx-auto flex flex-col gap-8 xl:justify-between">
            <section className="w-full h-auto xl:h-[180px] rounded-t-[30px] xl:rounded-l-[30px] bg-[linear-gradient(355.97deg,_#BEA6FF00_3.42%,_#BEA6FF63_65.37%)] xl:bg-[linear-gradient(270deg,_#BEA6FF00_5.49%,_#BEA6FF63_58.51%)] flex flex-col xl:flex-row gap-[42px] xl:gap-[150px] items-end xl:justify-end xl:items-center overflow-hidden">
              <section className="w-[95%] xl:w-[547px] mx-auto h-auto xl:h-[74px] pb-[52px] leading-[24.8px] text-[#191919] order-2 xl:-order-none">
                <h3 className=" font-bold ">تاریخچه اندازه گیری</h3>
                <p className="font-normal">این تاریخچه کمک میکنه تا میزان پیشرفت خودت رو برای هر ماه و نهایتا ماه ششم نسبت به ماه اول بسنجی.</p>
              </section>
              <img src={imagesFileUrl + "bg-planner5.png"} className="w-[250px] h-[180.2px] mix-blend-color-burn placeholder-opacity-80" alt="" />
            </section>
            <section className="w-full h-auto xl:h-[188px] rounded-t-[30px] xl:rounded-r-[30px] bg-[linear-gradient(355.97deg,_#BEA6FF00_2.08%,_#BEA6FF63_89.63%)] xl:bg-[linear-gradient(90deg,_#BEA6FF00_5.49%,_#BEA6FF63_58.51%)] flex flex-col xl:flex-row gap-[25px] xl:gap-[153px] items-start xl:justify-start xl:items-center overflow-hidden">
              <img src={imagesFileUrl + "bg-planner6.png"} className="w-[278px] h-[188px] mix-blend-color-burn" alt="" />
              <section className="w-[95%] xl:w-[492px] mx-auto h-auto xl:h-[99px] pb-10 leading-[24.8px] text-[#191919]">
                <h3 className=" font-bold ">ثبت رکورد وزنه ها</h3>
                <p className="font-normal text-justify">اضافه بار تدریجی در تمرینات بسیار فاکتور مهمی هست و شما می‌تونی میزان وزنه خودت رو در بخش های مختلف پلنر ثبت کنی و میزان پیشرفتت رو پایش کنی.</p>
              </section>
            </section>
            <section className="w-full h-auto xl:h-[196px] rounded-[30px] bg-[#5408A9] overflow-hidden relative">
              <section className="w-[90%] xl:w-[593px] h-auto xl:h-[74px] my-[137px] xl:my-[61px] mx-auto leading-[37px] text-center text-[20px] text-[#FFFFFF]">
                <h3 className=" font-bold ">هدف گذاری کوتاه مدت ، میان مدت و بلند مدت</h3>
                <p className="font-normal">حتما برای برنامه ورزشی و رژیم غذایت هدف گذاری کن تا به نتیجه برسی</p>
              </section>
              <img src={imagesFileUrl + "bg-planner7.png"} className="w-[45%] xl:w-[183.12px] xl:h-[169.06px] absolute bottom-9 xl:bottom-1 right-[33px] xl:right-[45.93px] mix-blend-plus-lighter opacity-90" alt="" />
              <img alt="" src={imagesFileUrl + "bg-planner8.png"} className="w-[90%] xl:w-[551.86px] xl:h-[65.95px] absolute top-[108px] xl:top-[102.88px] right-4 xl:right-[244.89px] rotate-[-120deg] xl:rotate-[-174deg]" />
              <img alt="" src={imagesFileUrl + "bg-planner9.png"} className="w-[40%] xl:w-[162px] xl:h-[141px] absolute top-[34px] xl:top-[50px] right-[157px] xl:right-[768.83px] mix-blend-plus-lighter opacity-65" />
            </section>
            <section className="w-full h-auto xl:h-[180px] rounded-t-[30px] xl:rounded-l-[30px] bg-[linear-gradient(355.97deg,_#BEA6FF00_2.08%,_#BEA6FF63_89.63%)] xl:bg-[linear-gradient(270deg,_#BEA6FF00_5.49%,_#BEA6FF63_58.51%)] flex flex-col xl:flex-row gap-[23px] xl:gap-[277px] items-end xl:justify-end xl:items-center overflow-hidden">
              <section className="w-[95%] xl:w-[403px] mx-auto h-auto xl:h-[74px] pb-[52px] leading-[24.8px] text-[#191919]  order-2 xl:-order-none">
                <h3 className=" font-bold ">ثبت مکمل های ورزشی و غذایی</h3>
                <p className="font-normal">در این بخش می تونی میزان و ساعت مصرف مکمل هارو یادداشت کنی</p>
              </section>
              <img src={imagesFileUrl + "bg-planner10.png"} className="w-[267px] h-[180px]" alt="" />
            </section>
            <section className="w-full h-auto xl:h-[188px] rounded-t-[30px] xl:rounded-r-[30px] bg-[linear-gradient(355.97deg,_#BEA6FF00_2.08%,_#BEA6FF63_89.63%)] xl:bg-[linear-gradient(90deg,_#BEA6FF00_5.49%,_#BEA6FF63_58.51%)] flex flex-col xl:flex-row gap-[21px] xl:gap-[177px] items-start xl:justify-start xl:items-center overflow-hidden">
              <img alt="" src={imagesFileUrl + "bg-planner11.png"} className="w-[254px] h-[188px]" />
              <section className="w-[95%] xl:w-[523px] mx-auto h-auto xl:h-[92px] pb-[52px] leading-[24.8px] text-[#191919]">
                <h3 className=" font-bold ">ثبت نقاط ضعف و قدرت</h3>
                <p className="font-normal">ثبت نقاط ضعف و قدرت هم به شما و هم به مربی شما کمک زیادی خواهد کرد تا برنامه های ماه های آینده دقیق تر و حرفه ای تر نسبت به خصوصیات شما تنظیم بشه.</p>
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
}

// bg-gradient-to-l from-[#d663e21f] via-[#efc3f41f] to-[#ffffff1f]
