import LooseWeight from "./components/LooseWeight";
import CalorieCounter from "./components/CalorieCounter";
import Child from "./components/Child";
import GainWeight from "./components/GainWeight";
import Pregnant from "./components/Pregnant";
import Sportsman from "./components/Sportsman";
import Maintain from "./components/Maintain";
import Baby from "./components/Baby";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:mb-[80px] my-[60px] h-auto xl:mt-[150px] ">
      <h3 className="text-center leading-[34.1px] xl:leading-[49.6px] text-[#191919] font-bold text-[22px] xl:text-[32px]">رژیم‌های تناسب اندام</h3>
      <p className="text-center xl:block hidden leading-[24.8px] mt-8 text-[#3E4151] font-bold text-[16px]">با استفاده از ورزش‌های تناسب اندام، به بهترین نتایج در تناسب اندام دست یابید.</p>
      <section className="grid xl:grid-cols-3 mt-8 xl:mt-[64px] w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <LooseWeight id={3} />
        <CalorieCounter id={4} />
        <GainWeight id={6} />
      </section>
      <section className="grid xl:grid-cols-4 mt-6  w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Baby id={10} />
        <Pregnant id={7} />
      </section>
      <section className="grid xl:grid-cols-3 mt-6 w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Sportsman id={8} />
        <Maintain id={9} />
        <Child id={5} />
      </section>
    </section>
  );
}
