//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

import { imagesFileUrl } from "../../../../../config";

export default function Index({ type, title }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <section className="flex gap-[12px] mt-[20px]">
            {type === "checked" && <img className="w-[24px] h-[24px]" src={imagesFileUrl + "icons/pkg-check.png"} alt="" />}
            {type === "unchecked" && <img className="w-[24px] h-[24px]" src={imagesFileUrl + "icons/pkg-remove.png"} alt="" />}
            <span className="text-[14px] font-normal">{title}</span>
        </section>
    )
}
