import { useState, useEffect } from "react";
import { ComponentLoading } from "../../../components/common";
import TableRow from "./components/TableRow";
import { useAxiosWithToken } from "../../../hooks";
import api from "../../../api";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [versionList, setVersionList] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getVersionList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.transaction.getTransaction + "?pageNo=0&pageSize=1000")
      .then((res) => {
        setLoading(false);
        setVersionList(res.data?.elements);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getVersionList();
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">لیست تراکنش ها</span>
      </section>
      <section className="xl:w-[700px] m-auto mt-10 text-center text-[14px] relative">
        <section className="hidden xl:grid grid-cols-6 gap-1">
          <section className="w-full">شماره درخواست</section>
          <section className="w-full">یوزر درخواست کننده</section>
          <section className="w-full">نام پکیج</section>
          <section className="w-full"> مبلغ (ریال)</section>
          <section className="w-full">درصد تخفیف</section>
          <section className="w-full">وضعیت سفارش</section>
        </section>
        <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
          <ComponentLoading loading={loading} />
          {versionList.map((item, index) => (
            <TableRow rowData={item} />
          ))}
        </section>
      </section>
    </section>
  );
}
