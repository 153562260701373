import { useEffect, useState } from "react";
import { Button, TextBox } from "../../components/common";
import { useAxios } from "../../hooks";
import api from "../../api";
import { notify, numberWithCommas } from "../../helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuthStore } from "../../store/auth/auth";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  let { id } = useParams();
  const navigation = useNavigate();
  const updateReturnUrl = useAuthStore((state) => state.updateReturnUrl);
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [discountCode, setDiscountCode] = useState();
  // eslint-disable-next-line no-unused-vars
  const [amount, setAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [packageType, setPackageType] = useState();
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState();
  const [referralloading, setReferralloading] = useState(false);
  const [discountloading, setDiscountloading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const isValidRefferalCode = () => {
    setReferralloading(true);
    let params = {
      packageInfoId: parseInt(id),
      referralCode: referralCode,
    };
    useAxios
      .post(api.referral.isValidReferralCode, params)
      .then((res) => {
        setReferralloading(false);
        notify.Success("با موفقیت اعمال شد");
      })
      .catch((err) => {
        setReferralloading(false);
        notify.Error("کد معرف اشتباه است");
      });
  };
  const getDiscount = () => {
    let params = {
      packageInfoId: parseInt(id),
      discountCode: discountCode,
    };
    setDiscountloading(true);
    useAxios
      .post(api.discount.getDiscount, params)
      .then((res) => {
        setDiscountloading(false);
        let myAmount = parseInt(amount);
        let discountPercent = parseInt(res.data.discountPercent);
        let _discountAmount;
        _discountAmount = (myAmount * discountPercent) / 100;
        setDiscountAmount(_discountAmount);
        setTotalAmount(myAmount - _discountAmount);
        notify.Success("با موفقیت اعمال شد");
      })
      .catch((err) => {
        setDiscountloading(false);
        notify.Error("کد تخفیف اشتباه است");
      });
  };
  const requestPayment = () => {
    setLoading(true);
    let params = {
      requestProgramInputDto: {
        requestProgramType: packageType,
        referralCode: referralCode,
        discountCode: discountCode,
        packageInfoId: parseInt(id),
      },
    };
    useAxios
      .post(api.payment.request, params, { headers: { authorization: localStorage.token } })
      .then((res) => {
        setLoading(false);
        window.location.href = res?.data?.url;
      })
      .catch((err) => {
        if (err.status === 401) {
          updateReturnUrl(window.location.pathname + window.location.search);
          navigation("/login");
        }
        setLoading(false);
      });
  };
  const getPackageInfo = () => {
    useAxios
      .get(api.packageInfo.getPackageInfo + "?packageInfoId=" + id)
      .then((res) => {
        setAmount(res?.data[0].amount);
        setTotalAmount(res?.data[0].amount);
        setPackageType(res?.data[0].packageTypeEnum);
      })
      .catch((err) => {});
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getPackageInfo();
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="h-auto py-[100px]">
      <section className="text-center text-[25px] mb-[50px] font-bold">نمایش پیش فاکتور</section>
      <section className="w-[1000px]  grid grid-cols-1 xl:grid-cols-3 max-w-[95%] border m-auto px-5 py-5 border-slate-300 rounded-xl min-h-[300px]">
        <section className="p-5 h-full flex flex-col  gap-3">
          <label className="mt-6">کد تخفیف:</label>
          <section className="flex justify-between gap-3 items-center">
            <TextBox onChange={(e) => setDiscountCode(e.target.value)} className="w-[138px] xl:w-full" />
            <Button disabled={!discountCode} onClick={getDiscount} loading={discountloading} className=" h-[40px] text-[16px]">
              اعمال
            </Button>
          </section>
          <label className="">کد معرف:</label>
          <section className="flex justify-between gap-3 items-center">
            <TextBox onChange={(e) => setReferralCode(e.target.value)} className="w-[138px] xl:w-full" />
            <Button disabled={!referralCode} onClick={isValidRefferalCode} loading={referralloading} className=" h-[40px] text-[16px]">
              اعمال
            </Button>
          </section>
        </section>
        <section className="h-full col-span-2 bg-slate-50 rounded-md p-4  flex flex-col justify-center">
          <section className="flex text-purple font-bold text-[20px] justify-between p-5 px-[20%]">
            <span></span>
            <span>{searchParams.get("name")}</span>
          </section>
          <section className="flex justify-between p-5 px-[20%]">
            <label className="font-semibold">جمع کل:</label>
            <span className="font-semibold">{numberWithCommas(amount)} ریال</span>
          </section>
          <section className="flex justify-between p-5 px-[20%]">
            <label className="font-semibold">تخفیف:</label>
            <span className="font-semibold">{numberWithCommas(discountAmount)} ریال</span>
          </section>
          <section className="h-[1px] w-[80%] m-auto my-[20px] bg-purple"></section>
          <section className="flex justify-between p-5 px-[20%]">
            <label className="font-bold text-[18px] text-purple">مبلغ قابل پرداخت:</label>
            <span className="font-bold text-[20px] text-purple">{numberWithCommas(totalAmount)} ریال</span>
          </section>
          <section className="px-[20%]">
            <Button loading={loading} onClick={requestPayment} className="w-[200px] mr-auto font-medium mt-[40px]">
              پرداخت
            </Button>
          </section>
        </section>
      </section>
    </section>
  );
}
