import moment from "moment-jalaali";
import api from "../../../../api";
import { useEffect, useRef } from "react";
import { baseFileUrl, svgFileUrl } from "../../../../config";
import ProfileItemBox from "./components/ProfileItemBox";
import ProfileItemBoxBig from "./components/ProfileItemBoxBig";
import Loading from "../../../../components/common/componentLoading";
import { useBasicInfoStore } from "../../../../store/dashboard/basicInfo";
import { useAxiosWithToken } from "../../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const userInputFile = useRef();
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const updateBasicInfo = useBasicInfoStore((state) => state.updateBasicInfo);
  const basicInfoLoading = useBasicInfoStore((state) => state.basicInfoLoading);
  const showBasicInfoLoading = useBasicInfoStore((state) => state.showBasicInfoLoading);
  const hideBasicInfoLoading = useBasicInfoStore((state) => state.hideBasicInfoLoading);
  const reloadBasicInfoState = useBasicInfoStore((state) => state.reloadBasicInfoState);

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getUserInfo = () => {
    showBasicInfoLoading();
    useAxiosWithToken
      .get(api.user.getUserInfo)
      .then((res) => {
        hideBasicInfoLoading();
        updateBasicInfo(res.data);
        localStorage.firstName = res?.data?.firstName;
      })
      .catch((e) => {
        hideBasicInfoLoading(false);
      });
  };
  const createImage = (file) => {
    /*  setLoading(true) */
    const formData = new FormData();
    formData.append("image", file);
    useAxiosWithToken
      .put(api.user.uploadImage, formData)
      .then((res) => {
        getUserInfo();
        /*setLoading(false) */
      })
      .catch((err) => {
        /*setLoading(false) */
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadBasicInfoState]);
  //
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[654px] bg-white rounded-2xl p-[4px] shadow-lg relative overflow-hidden">
      <Loading loading={basicInfoLoading} />
      <section className="w-full h-full bg-[#F5F7F8] rounded-2xl overflow-hidden relative">
        <img src={baseFileUrl + "/images/dashboard/bg.png"} className="w-full z-0 relative" alt="" />
        <section className="w-[246px] h-[180px] m-auto mt-[-85px] z-50  relative flex flex-col justify-center items-center">
          <section onClick={() => userInputFile?.current?.click()} className="w-[120px] h-[120px] group border-[3px] border-[white] cursor-pointer relative rounded-full overflow-hidden">
            <section className="absolute bg-[#4220578f] flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-500 top-0 left-0 z-50 w-full h-full">
              <img className="w-[40px] h-[40px] " src={svgFileUrl + "upload-image.svg"} alt="" />
            </section>
            <img className="w-full rounded-full" src={basicInfo?.profileImageUrl ? basicInfo?.profileImageUrl : `${baseFileUrl}${basicInfo?.gender === "MALE" ? `/images/icons/man-avatar.png` : `/images/icons/woman-avatar.png`}`} /* src={`${baseFileUrl}${basicInfo?.gender === "MALE" ? `/images/icons/man-avatar.png` : `/images/icons/woman-avatar.png`}`} */ alt="" />
            <input
              type="file"
              name="photo"
              ref={userInputFile}
              id="upload-photo"
              className="hidden"
              onChange={(e) => {
                createImage(e.target.files[0]);
              }}
            />
          </section>

          <span className="block mt-[10px] font-semibold text-textGray">{basicInfo?.firstName + " " + basicInfo?.lastName}</span>
          <section className="flex items-center justify-between w-[49px] h-[20px]">
            <img src={baseFileUrl + "/images/dashboard/location.png"} alt="" />
            <span className="block text-[12px] font-normal text-textGray">ایران</span>
          </section>
        </section>
        <section className="w-[267px] h-[408px] m-auto mt-3">
          <span className="font-bold text-textGray">مشخصات فردی</span>
          <ProfileItemBox icon1={baseFileUrl + "/images/svg/age.svg"} title1="سن شما" value1={basicInfo?.age} icon2={baseFileUrl + "/images/svg/weight.svg"} title2="وزن شما" value2={basicInfo?.kgWeight} />
          <ProfileItemBox icon1={baseFileUrl + "/images/svg/height1.svg"} title1="قد شما" value1={basicInfo?.cmHeight} icon2={baseFileUrl + "/images/svg/bmi.svg"} title2="BMI" value2={Math.round(basicInfo?.bmi)} />
          {/*  <span className="block font-bold text-textGray mt-8">
            مشخصات فردی
          </span> */}
          <ProfileItemBoxBig icon1={baseFileUrl + "/images/svg/weight.svg"} title1="وزن هدف شما" value1={basicInfo?.goalKgWeight} icon2={baseFileUrl + "/images/svg/calender.svg"} title2="تاریخ شروع شما" value2={moment(basicInfo?.createdOn).format("jYYYY/jM/jD")} />
        </section>
      </section>
    </section>
  );
}
