import { useState, useEffect } from "react";
import { ComponentLoading, Modal } from "../../../../../../../components/common";
import TableRow from "./components/TableRow";
import { useAxiosWithToken } from "../../../../../../../hooks";
import api from "../../../../../../../api";
import { useSearchParams } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ reload, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("userId");
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [versionList, setVersionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState();
  const [detailModal, setDetailModal] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getVersionList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.doctor.getMedicalPrescriptionList + "?requestId=" + requestId)
      .then((res) => {
        setLoading(false);
        setVersionList(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const showModal = (rowData) => {
    setRowData(rowData);
    setDetailModal(true);
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getVersionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px]">
      <h2 className="mt-[40px] text-[30px] text-center"> نسخه های در انتظار تایید</h2>
      <section className="w-full m-auto mt-10 text-center text-[14px] relative">
        <section className="hidden xl:grid grid-cols-6 gap-1">
          <section className="w-full">شماره نسخه</section>
          <section className="w-full">تاریخ ثبت نسخه</section>
          <section className="w-full">جزییات نسخه</section>
          <section className="w-full">دانلود PDF</section>
          <section className="w-full col-span-2">تایید نهایی / حذف</section>
        </section>
        <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
          <ComponentLoading loading={loading} />
          {versionList.map((item, index) => (
            <TableRow showModal={showModal} reloadTable={reloadTable} rowData={item} />
          ))}
        </section>
      </section>
      <Modal width={800} open={detailModal} onClose={() => setDetailModal(false)}>
        <section className="w-full  min-h-[100px] rounded-lg">
          <section className="grid grid-cols-4 py-2 text-xs">
            <span>نام دارو</span>
            <span>نوع دارو</span>
            <span>تعداد</span>
            <span>توضیحات</span>
          </section>
          {rowData?.medicalPrescriptionDetails?.map((item, index) => (
            <section key={index} className="grid grid-cols-4 py-2 font-semibold text-xs text-purple">
              <span>{item.drugName}</span>
              <span>{item.drugType}</span>
              <span>{item.count}</span>
              <span>{item.description}</span>
            </section>
          ))}
          <hr />
          <section className="flex justify-start mt-2 text-purple">
            <span>توضیحات:</span>
            <span className="font-semibold">{rowData?.description}</span>
          </section>
        </section>
      </Modal>
    </section>
  );
}
