import React from "react";
import { baseFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { useStartLink } from "../../../../hooks";
import { Button } from "../../../../components/common";
import { scrollTo } from "../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [startLink] = useStartLink();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {/* ---------------------------------Mobile------------------------ */}
      <section className="w-full h-auto xl:hidden relative bg-heroGr  overflow-hidden">
        <section className="w-[90%] h-full m-auto flex flex-col mt-[77px]">
          <img src={baseFileUrl + "/images/light.png"} alt="" className="absolute top-0 left-0 " />
          <section className="relative">
            <section className="flex w-[152px] h-[54px] pl-[20px] glass rounded-lg absolute top-[5%] right-[5%]">
              <span className=" flex items-center text-white p-2  text-xs font-semibold">تمرینات اختصاصی و دوره های ویژه</span>
              <section className="w-[55px] h-[55px] p-3 absolute bg-purple border border-silver rounded-lg top-[-15px] left-[-35px]">
                <img src={baseFileUrl + "/images/exercise/ex1.png"} className="w-full" alt="" />
              </section>
            </section>
            <section className="flex justify-between w-[127px] h-[47px] glass rounded-lg  absolute right-[8%] top-[80%] ">
              <span className=" flex items-center text-white text-xs font-semibold p-2">پلنر برنامه ریزی</span>
              <img src={baseFileUrl + "/images/planner.png"} className="w-[38]  mt-[4px] ml-[5.73px]" alt="" />
            </section>
            <section className="flex justify-between w-[109px] h-[50px] rounded-lg glass absolute left-[13%] top-[85%] ">
              <span className=" flex items-center text-white p-1 text-xs font-semibold">برنامه غذایی مناسب</span>
              <img src={baseFileUrl + "/images/section2salad.png"} className="w-[50px] h-[50px] mt-[5px] ml-[-21px]" alt="" />
            </section>
            <section className="flex justify-between w-[125px] h-[44px] p-1 glass rounded-lg absolute left-[0] top-[10%] ">
              <span className=" flex items-center  text-white p-1  text-xs font-semibold">برنامه ورزشی منظم</span>
              <img src={baseFileUrl + "/images/icons/ketelball-home.png"} className="w-[28.85px] h-[31.96px] " alt="" />
            </section>
            <img src={baseFileUrl + "/images/hero.png"} className="w-full  max-w-full mt-[70px] " alt="" />
          </section>
          <img src={baseFileUrl + "/images/damble.png"} className="absolute w-[289.55px] h-[255.28px] right-[-50px] top-[300px] opacity-20" alt="" />
          <section className="w-[90%] mt-[10px] mb-[96.59px] mx-auto flex flex-col items-center justify-center  text-white z-10 top-0 right-0">
            <h3 className="font-semibold text-[30px]">برای زندگیت</h3>
            <h3 className="font-semibold text-[30px]">حرکت کن</h3>
            <p className="font-normal text-center leading-[24.95px] mt-[5px]">رژیم های منعطف، تمرینات علمی و آموزش های تخصصی برای سبک زندگی سالم تر، در بادینس</p>
            <section className="mt-2">
              <Link to={startLink}>
                <Button light={true} className="w-[200px] h-[44px] mt-4 font-semibold   rounded-full">
                  مشاهده برنامه ها
                </Button>
              </Link>
              {/*  <button className="w-[125.04px] h-[31.95px] border-[1.47px] font-bold text-xs border-solid border-white rounded-full mr-[22.02px]">دانلود اپلیکیشن</button> */}
            </section>
          </section>
        </section>
      </section>
      {/* -----------------------Desktop------------------------ */}
      <section className="hidden xl:block h-[680px] relative bg-heroGr overflow-hidden">
        <section className="container h-full m-auto flex items-center pt-[80px]">
          <img src={baseFileUrl + "/images/light.png"} alt="" className="absolute top-0 " />
          <section className="grid w-[90%] mx-auto grid-cols-2">
            <section className="w-[480px] flex items-center justify-center  text-white z-10 top-0 right-0">
              <section>
                <h3 className="font-semibold text-[50px]">برای زندگیت</h3>
                <h3 className="font-semibold text-[50px]"> حرکت کن !</h3>
                <p className="text-[22px] font-normal mt-5">رژیم های منعطف، تمرینات علمی و آموزش های تخصصی برای سبک زندگی سالم تر، در بادینس</p>
                <section className="mt-10">
                  <Link to={startLink}>
                    <Button light={true} className="w-[250px]   transition-all  hover:translate-y-[-5px] h-[60px] border-2 font-bold text-xl border-solid rounded-full">
                      مشاهده برنامه ها
                    </Button>
                  </Link>

                  {/* <button className="w-[170px] h-[44px] border-2 font-semibold text-base border-solid border-white rounded-full mr-9">دانلود اپلیکیشن</button> */}
                </section>
              </section>
            </section>
            <section className="relative z-10">
              <section onClick={() => scrollTo(1200)} className="flex justify-center items-center cursor-pointer  w-[211px] h-[59px] p-4 pr-10 glass rounded-2xl absolute top-[40%] ">
                <span className="cursor-pointer text-white text-base font-semibold">تمرینات اختصاصی و دوره های ویژه</span>
                <section className="w-[54px] h-[54px] p-3 absolute bg-purple border border-silver rounded-lg top-[-15px] right-[-20px]">
                  <img src={baseFileUrl + "/images/exercise/ex1.png"} className="w-[31px] " alt="" />
                </section>
              </section>
              <section onClick={() => scrollTo(2500)} className="flex cursor-pointer justify-between w-[222px] h-[57px] rounded-2xl glass absolute right-[13%] top-[80%] ">
                <span className=" flex items-center text-white p-2 text-base font-semibold mr-3">پلنر برنامه ریزی</span>
                <img src={baseFileUrl + "/images/planner.png"} className="w-[80px] h-[80px] rotate-[-10deg] mt-[-10px] ml-2" alt="" />
              </section>
              <section onClick={() => scrollTo(500)} className="flex justify-between cursor-pointer w-[179px] h-[60px] glass rounded-2xl absolute left-[13%] top-[73%] ">
                <span className="flex items-center w-[95px] h-[30px] text-white p-1 mt-4 mr-2 text-base font-semibold">برنامه غذایی مناسب</span>
                <img src={baseFileUrl + "/images/section2salad.png"} className="w-[72px] h-[90px] ml-[-10px] block mt-2" alt="" />
              </section>
              <section onClick={() => scrollTo(500)} className="flex justify-between w-[179px] h-[68px] glass pl-[50px] cursor-pointer rounded-2xl absolute left-[-27px] top-[15%]">
                <span className=" flex items-center w-full h-[30px] text-white p-1 mt-5 mr-2 text-base font-semibold">برنامه ورزشی منظم</span>
                <img src={baseFileUrl + "/images/icons/ketelball-home.png"} className="w-[80px]  absolute top-[-40px] left-[-10px] ml-3 mt-2" alt="" />
              </section>
              <img src={baseFileUrl + "/images/hero.png"} className="w-[730px] max-w-full" alt="" />
            </section>
          </section>
          <img src={baseFileUrl + "/images/damble.png"} className="absolute bottom-[-100px] opacity-20" alt="" />
        </section>
      </section>
    </>
  );
}
