import { useState } from "react";
import AddExerciseProgram from "./components/AddExerciseProgram";
import MovementList from "./components/MovementList";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [movementList, setMovementList] = useState([]);
  const [exerciseId, setExerciseId] = useState(null);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="h-auto mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
        <AddExerciseProgram exerciseId={exerciseId} setExerciseId={setExerciseId} setMovementList={setMovementList} />
      </section>
      {exerciseId && (
        <section className="h-auto mt-[40px] py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
          <MovementList movementList={movementList} />
        </section>
      )}
    </>
  );
}
