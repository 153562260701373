import { useEffect, useRef } from "react";
import "./style.css";

function Index({
  focus,
  className,
  onChange,
  value,
  type,
  onKeyUp,
  shape,
  placeholder,
  style,
  disabled,
  borderRadius,
}) {
  const txt = useRef();
  useEffect(() => {
    if (focus) {
      txt.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <input
        ref={txt}
        disabled={disabled}
        style={style}
        type={type}
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        placeholder={placeholder}
        className={`${className} border p-2 ${
          borderRadius ? borderRadius : "rounded-md "
        } border-slate-300 min-h-[40px] w-full  ${
          shape === "round" && "round"
        }`}
      />
    </>
  );
}

export default Index;
