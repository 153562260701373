import { Link } from "react-router-dom";
import { ProfilePicture } from '../../../../components/common'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:h-[300px] h-auto rounded-[16px] border mt-[40px] border-[#E6E8E9] shadow-md">
      {/*  <section className="border-[2px] w-[85px] h-[85px] mt-[-44px] bg-white  rounded-full shadow-md m-auto border-[#E6E8E9]">
            </section> */}
      <section className="w-[100px] h-[100px] rounded-full mt-[-50px] overflow-hidden m-auto">
        <ProfilePicture imageUrl={rowData?.user?.profileImageUrl} gender={rowData?.user?.gender} />
      </section>
      <span className="font-semibold block text-center text-sm mt-[10px]">{`${rowData?.user?.firstName} ${rowData?.user?.lastName}`}</span>
      <span className="font-bold block text-center mt-[5px] text-purple">{`${rowData?.namePackaging}`}</span>
      <section className="w-[95%] xl:w-[203px] relative flex gap-y-6 justify-between items-center h-auto xl:h-[73px] mt-[32px]  m-auto">
        <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
          <img alt="" src="/images/svg/agePink.svg" />
          <span className="text-[12px] text-[#F02484] font-semibold">سن</span>
          <span className="text-[12px] text-[#F02484] font-semibold">{rowData?.user?.age}</span>
        </section>
        <span className="w-[1px] h-[31px] block  bg-[#E6E8E9]"></span>
        <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
          <img alt="" src="/images/svg/weight.svg" />
          <span className="text-[12px] text-purple font-semibold">BMI</span>
          <span className="text-[12px] text-purple font-semibold">{Math.trunc(rowData?.user?.bmi)}</span>
        </section>
        <span className="w-[1px] h-[31px] block bg-[#E6E8E9]"></span>
        <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
          <img alt="" src="/images/svg/weightOrange.svg" />
          <span className="text-[12px] text-[#FA6900] font-semibold">وزن</span>
          <span className="text-[12px] text-[#FA6900] font-semibold">{rowData?.user?.kgWeight}</span>
        </section>
        <span className="w-[1px] h-[31px] block bg-[#E6E8E9]"></span>
        <section className="h-[73px] w-[21px] flex flex-col justify-between items-center">
          <img alt="" src="/images/svg/height1.svg" />
          <span className="text-[12px] text-purple font-semibold">قد</span>
          <span className="text-[12px] text-purple font-semibold">{Math.trunc(rowData?.user?.cmHeight)}</span>
        </section>
      </section>
      <Link to={`/coach-dashboard/additional-info?userId=${rowData.id}&mobileNumber=${rowData.mobileNumber}&profileImage=${rowData?.user?.profileImageUrl}`}>
        <button className="bg-purple text-white w-[80%] mb-6 xl:mb-0 xl:w-[117px] h-[40px] rounded-full m-auto block mt-[15px]">ثبت برنامه</button>
      </Link>
    </section>
  );
}
