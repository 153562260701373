import { baseFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";
import { Link } from "react-router-dom";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ src, display, price, title, id }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className={`${display} cursor-pointer group w-full h-auto transition-all flex bg-white items-center gap-2 xl:gap-4 py-[14px] rounded-[10px] xl:rounded-[20px] border-solid `}>
      <img src={baseFileUrl + src} alt="" className="xl:w-[132px] w-[25%] mr-2 h-auto" />
      <section className="flex flex-col w-full px-[14px]">
        <h3 className={`leading-[27.9px] text-[#3E4151] text-sm xl:text-[18px] font-bold`}>{title}</h3>
        <section className="w-full xl:w-[324px]  mt-1 text-xs xl:text-sm relative text-[#3E4151]">
          <span className="xl:text-[18px] text-sm font-bold text-[#191919] leading-[27.9px] ml-1 ">{price}</span>
          تومان
          <img src={baseFileUrl + "/Rate-star.png"} alt="" className="absolute top-0 left-0 w-[49px] h-[20px]" />
        </section>
        <section className="flex mt-2 items-start  gap-[6px] transition-all duration-500 ">
          <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-[150px]">ویدیوکال</span>
          <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">چت</span>
          {/*  <span className="text-[#5521B4] bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">تمرین</span>
            <span className="text-[#5521B4] hidden xl:block bg-[#F7F9FE] px-3 py-2 font-bold text-sm rounded-full">تغذیه</span> */}
        </section>
        <section className="w-full flex justify-end">
          <Link to={"/shopping-cart/" + id}>
            <Button className="text-[#5521B4] mt-[12px] bg-[#F7F9FE] w-full xl:w-[192px] h-[30px] font-bold text-sm rounded-[150px]">دریافت رژیم</Button>
          </Link>
        </section>
      </section>
    </section>
  );
}
