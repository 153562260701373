import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

// ثبت فونت
Font.register({
  family: "iransans",
  src: "/fonts/vazir/Vazir.ttf", // مسیر باید به درستی در پوشه public قرار داشته باشد
});

const PDFTable = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "iransans",
      position: "relative",
    },
    wrapper: {
      width: "100%",
      marginTop: 10,
    },
    title: {
      fontSize: 15,
      textAlign: "right",
    },
    box: {
      borderWidth: 1,
      borderColor: "silver",
      padding: 10,
      borderRadius: 10,
      marginTop: 10,
    },
    header: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    headerBox: {
      justifyContent: "center",
      alignItems: "center",
    },
    headerTitle: {
      fontSize: 10,
    },
    headerValue: {
      fontSize: 12,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#ccc",
      borderRadius: 10,
      marginTop: 10,
    },
    tableRow: {
      flexDirection: "row-reverse",
    },
    tableCol: {
      width: "25%",
      padding: 5,
      textAlign: "center",
    },
    tableCell: {
      fontSize: 10,
      textAlign: "center",
      direction: "rtl",
    },
    watermark: {
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
    },
    watermarkImage: {
      width: "50%",
      opacity: 0.1,
    },
    descBox: {
      padding: 10,
      alignItems: "center",
      flexDirection: "row-reverse",
      gap: 5,
    },
  });

  try {
    return (
      <Document>
        <Page break wrap={true} size="A4" style={styles.page}>
          <View style={styles.watermark}>
            <Image alt="" style={styles.watermarkImage} src="/images/watermark.png" />
          </View>
          <View style={styles.wrapper}>
            {data?.personalSportProgramDetails?.map((item, index) => (
              <View wrap={false} style={styles.box} key={index}>
                <View style={styles.header}>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>سیستم تمرینی</Text>
                    <Text style={styles.headerValue}>{item?.trainingSystem}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>نام حرکت</Text>
                    <Text style={styles.headerValue}>{item?.exerciseName}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>نام عضله</Text>
                    <Text style={styles.headerValue}>{item?.muscleName}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>تعداد ست</Text>
                    <Text style={styles.headerValue}>{item?.setCount}</Text>
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ست ها</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>تعداد تکرار</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>استراحت</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>tempo</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>توان به درصد</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>وزن به کیلوگرم</Text>
                    </View>
                  </View>
                  {item?.sets?.map((row, idx) => (
                    <View style={styles.tableRow} key={idx}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>ست {idx + 1}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.setRepeat}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.rest} ثانیه</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.tempo}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.tavan}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.weight}</Text>
                      </View>
                    </View>
                  ))}
                  <View style={styles.descBox}>
                    <Text style={{ fontSize: 10 }}>:توضیحات</Text>
                    <Text style={{ fontSize: 10 }}>{item?.description}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  } catch (error) {
    console.error("خطا در تولید PDF:", error.message);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>خطایی رخ داده است. لطفاً داده‌ها را بررسی کنید.</Text>
        </Page>
      </Document>
    );
  }
};

export default PDFTable;
