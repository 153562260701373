import React, { Fragment } from "react";
import RowDetail from "./components/RowDetail";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ movementList }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full  min-h-[70px] rounded-lg">
        <section className="grid grid-cols-5 py-2 text-xs text-center">
          <section className="w-full text-[14px] font-bold">شناسه تمرین</section>
          <section className="w-full text-[14px] font-bold">سیستم تمرینی</section>
          <section className="w-full text-[14px] font-bold">نام تمرین</section>
          <section className="w-full text-[14px] font-bold">نام عضله</section>
          <section className="w-full text-[14px] font-bold">جزییات</section>
        </section>
        {movementList.map((item, index) => (
          <Fragment key={index}>
            <RowDetail item={item} />
          </Fragment>
        ))}
      </section>
    </>
  );
}
