//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────

import { isAdminDoctor } from "../../../../helper";

//
export default function Index({ activeTab, setActiveTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:w-full h-[80px] flex justify-between items-center rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-4  xl:px-[86px]">
      <button onClick={() => setActiveTab(1)} className={`xl:w-[250px]  w-auto px-[5px] h-10 xl:h-[48px] rounded-full ${activeTab === 1 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs  xl:text-lg`}>
        افراد بدون برنامه
      </button>
      <button onClick={() => setActiveTab(4)} className={`xl:w-[250px]  w-auto px-[5px] h-10 xl:h-[48px] rounded-full ${activeTab === 4 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs  xl:text-lg`}>
        برنامه های رزرو شده
      </button>
      <button onClick={() => setActiveTab(2)} className={`xl:w-[250px]  w-auto px-[5px] h-10 xl:h-[48px] rounded-full ${activeTab === 2 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs xl:text-lg`}>
        برنامه های رد شده
      </button>
      {isAdminDoctor() && (
        <button onClick={() => setActiveTab(3)} className={`xl:w-[250px] px-[5px]  w-auto h-10 xl:h-[48px] rounded-full ${activeTab === 3 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs xl:text-lg`}>
          برنامه های در انتظار تایید
        </button>
      )}
    </section>
  );
}
