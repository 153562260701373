import Dashboard from "../../.../../../svg//Dashboard";
import Sport from "../../../svg//Sport";
import Target from "../../../svg//Target";
import Recipe from "../../../svg//Recipe";
import Ticket from "../../../svg//Ticket";
import Exit from "../../../svg//Exit";
import { Link, useNavigate } from "react-router-dom";
import { svgFileUrl } from "../../../../../../../config";
import { isAdmin, isCoach, isDoctor } from "../../../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export const MenuItem = ({ text, Icon, link }) => {
  return (
    <Link to={link}>
      <section className="w-[90px] dashboardMenuBg h-[90px] rounded-full p-1 flex flex-col gap-2 justify-center items-center ">
        {Icon && <Icon fill="white" />}
        <span className="text-white font-bold text-[12px] flex flex-wrap justify-center items-center text-center w-full">{text}</span>
      </section>
    </Link>
  );
};
export default function Index({ open, setOpen }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const logOut = () => {
    localStorage.clear();
    navigation("/login");
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`w-full h-full glass overflow-auto pb-[60px] fixed flex flex-col justify-center items-center ease-in-out  transition-all duration-500 left-0 z-[2000] ${open ? `bottom-0` : `bottom-[-100%]`}`}>
        <section className="absolute top-4 left-0 flex justify-end px-5 w-full ">
          <button className="text-purple text-2xl font-bold" onClick={() => setOpen(false)}>
            <img src={svgFileUrl + "close.svg"} className="w-[30px]" alt="" />
          </button>
        </section>
        <section className="h-auto grid grid-cols-3 gap-8 w-full px-4">
          <MenuItem text="افراد بدون برنامه" link="/doctor-dashboard/without-plan-user" Icon={Dashboard} />
          <MenuItem text="پکیج ها" link="/doctor-dashboard/food-package" Icon={Dashboard} />
          <MenuItem text="بازگشت به داشبورد" link="/dashboard" Icon={Dashboard} />
          <MenuItem text="یازگشت به سایت" link="/" Icon={Target} />
          <button onClick={logOut} className="w-[90px]  h-[90px]  rounded-full p-1 flex flex-col gap-2 justify-center items-center dashboardMenuBg">
            <Exit fill="white" />
            <span className="text-white font-bold text-[14px] flex flex-wrap justify-center items-center text-center w-full">خروج</span>
          </button>
        </section>
        <section className="flex w-full justify-between items-center absolute bottom-0 p-2">
          {isDoctor() && (
            <Link className="rounded-md p-2  text-white  bg-purple" to="/doctor-dashboard/without-plan-user">
              داشبورد دکتر
            </Link>
          )}
          {isAdmin() && (
            <Link className="rounded-md p-2 text-white  bg-purple" to="/admin">
              پنل مدیریت
            </Link>
          )}
          {isCoach() && (
            <Link className="rounded-md p-2 text-white  bg-purple" to="/coach-dashboard/without-plan-user">
              داشبورد مربی
            </Link>
          )}
        </section>

      </section>
    </>
  );
}
