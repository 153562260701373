import React from "react";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ border, bg1, bg2, src, title1, title2, color }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section style={{ borderColor: border }} className={`w-[90%]  py-[20px] mx-auto xl:w-[254px] gap-[14px] xl:gap-[21px] flex xl:flex-row flex-col justify-center xl:justify-between items-center h-auto xl:h-[88px] border border-solid xl:py-[16px] xl:pr-[24px] xl:pl-[20px] rounded-[18px] `}>
        <section style={{ backgroundColor: bg1 }} className="w-[59px] h-[59px] xl:w-[70px] xl:h-[70px] rounded-full flex items-center justify-center ">
          <section style={{ backgroundColor: bg2 }} className="w-[70%]  h-[70%] xl:w-[56px] xl:h-[56px] rounded-full   flex items-center justify-center z-10">
            <img alt="" src={src} className="w-[50%] h-auto xl:w-[25.71px] xl:h-[26.78px] z-30 " />
          </section>
        </section>
        <section className="w-[95%] mx-auto text-center flex flex-col xl:w-[126px]  items-center">
          <h2 className="text-[12px] xl:text-base text-[#3E4151] mb-2">{title1}</h2>
          <span style={{ color: color }} className="text-[12px] xl:text-[18px]  font-bold">
            {title2}
          </span>
        </section>
      </section>
    </>
  );
}
