import { useRef, useState } from "react";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
import { baseFileUrl } from "../../../../../config";
import { Button } from "../../../../../components/common";
import { notify } from "../../../../../helper";
import { NotifyMessage } from "../../../../../enums";
import { useSearchParams } from "react-router-dom";
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const userInputFile = useRef();

  const [searchParams] = useSearchParams();
  const requestProgramId = searchParams.get("requestProgramId");
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [fileList, setFileList] = useState([]);
  const [blobList, setBlobList] = useState([]);
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const createImage = (files) => {
    /*  setLoading(true) */
    if (files.length > 0) {
      const formData = new FormData();
      let myArr = [];
      for (let i = 0; i < files.length; i++) {
        let fileSize = files[i].size / 1024;
        if (fileSize > 2000) {
          notify.Error("سایز فایل نباید بیشتر از 2 مگابایت باشد");
        } else {
          formData.append("images", files[i]);
          myArr.push(URL.createObjectURL(files[i]));
        }
      }
      setFileList(formData);
      setBlobList(myArr);
    }
  };
  const uploadImage = () => {
    setLoading(true);
    useAxiosWithToken
      .post(api.question.documentAdd + `?medicalAndSportDocumentType=SPORT&requestProgramId=${requestProgramId}`, fileList)
      .then((res) => {
        setLoading(false);

        notify.Success(NotifyMessage.SUCCESS_ACTION);
        /*  navigation("/dashboard"); */
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[600px] max-w-full m-auto my-8 flex flex-col justify-center items-center h-auto p-3 xl:h-[400px] rounded-lg border border-dashed border-purple">
      <img src={baseFileUrl + "/images/icons/Image.svg"} className="w-[29.73px] xl:w-[70px] h-[44.75px] inline-block" alt="" />
      <span className="text-sm xl:text-[20px] font-medium leading-6 mt-2 xl:mt-5">آپلود مدارک ورزشی</span>
      <span className="text-[#9CA3AF] font-medium text-xs xl:text-[16px] leading-5 mt-2 xl:mt-4"> در صورتی که تمایل به ارسال عکس از اندام خود برای مربی دارید آپلود نمایید.</span>
      <span onClick={() => userInputFile.current.click()} className="text-[#3B82F6] cursor-pointer tracking-[0.5%] font-semibold text-sm xl:text-[16px] leading-6 mt-2 xl:mt-4">
        افزودن
      </span>
      <input
        type="file"
        name="photo"
        ref={userInputFile}
        id="upload-photo"
        className="hidden"
        multiple
        onChange={(e) => {
          createImage(e.target.files);
        }}
      />
      <section className="flex flex-wrap h-auto min-h-[100px] p-3 w-[95%] mt-4 border border-slate-200 rounded-lg gap-1 justify-center items-center">
        {fileList.length === 0 && <section className="w-full text-center text-gray">فایلی موجود نیست</section>}
        {blobList.map((item) => (
          <img src={item} onClick={() => window.open(item)} className="w-[100px] rounded-[5px] cursor-pointer h-[100px]" alt="" />
        ))}
      </section>

      <Button disabled={blobList.length < 1} loading={loading} onClick={uploadImage} className="xl:w-[427px] mt-[20px] w-full bg-[#5521B4] xl:text-[20px] rounded-xl -tracking-[2%] leading-[30.2px] font-bold text-white h-[50px] xl:h-[56px]">
        آپلود فایل
      </Button>
    </section>
  );
}
