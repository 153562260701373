import React from "react";
import { imagesFileUrl } from "../../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="mb-[19.11px] flex justify-center items-center gap-[8.99px]">
        <span className="text-white bg-[#7639BA] rounded-full h-[29.98px] w-[29.98px]  flex justify-center items-center">3</span>
        <span className="text-[22px] font-bold">وسیله ورزشی خودتو انتخاب کن</span>
      </section>
      <section className="w-[265px] flex flex-wrap justify-center gap-[20px] h-[240px]  m-auto">
        <section className="w-[118px] h-[110px] flex flex-col justify-center items-center bg-white rounded-[14px] shadow-md py-[16px] px-[24px]">
          <img alt="" src={imagesFileUrl + "icons/ball.png"} />
          <span className="mt-[14px] text-[14px] block font-bold">توپ</span>
        </section>
        <section className="w-[118px] h-[110px] flex flex-col justify-center items-center bg-white rounded-[14px] shadow-md py-[16px] px-[24px]">
          <img alt="" src={imagesFileUrl + "icons/damble1.png"} />
          <span className="mt-[14px] text-[14px] block font-bold">دمبل</span>
        </section>
        <section className="w-[118px] h-[110px] flex flex-col justify-center items-center bg-white rounded-[14px] shadow-md py-[16px] px-[24px]">
          <img alt="" src={imagesFileUrl + "icons/ketelball2.png"} />
          <span className="mt-[14px] text-[14px] block font-bold">کتل بال</span>
        </section>
        <section className="w-[118px] h-[110px] flex flex-col justify-center items-center bg-white rounded-[14px] shadow-md py-[16px] px-[24px]">
          <img alt="" src={imagesFileUrl + "icons/bycycle.png"} />
          <span className="mt-[14px] text-[14px] block font-bold">دوچرخه</span>
        </section>
      </section>
      <section className="w-full text-center">
        <span className="font-bold text-[14px] text-center block mt-[29px]">ماهیچه‌هات رو تقویت کن</span>
      </section>
    </>
  );
}
