import { useEffect } from "react";
import Header from "./components/Header";
import UserInfo from "./components/UserInfo";
import SpecialSickness from "./components/SpecialSickness";
import SpecialSicknessDtl from "./components/SpecialSicknessDtl";
import { ComponentLoading } from "../../../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ data, loading }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {}, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="min-h-[400px] h-auto mt-[40px] relative py-[32px] px-[26px] bg-white rounded-[16px] shadow-md">
      <ComponentLoading loading={loading} />
      <Header data={data} />
      <UserInfo data={data?.userOutputDTO} />
      <SpecialSickness data={data} />
      <SpecialSicknessDtl data={data} />
    </section>
  );
}
