import { useState } from "react";
import api from "../../../../api";
import Loading from "../../../../components/common/componentLoading";
import Collapse from "./Collapse";
import SearchBox from "../components/SearchBox";
import Modal from "../../../../components/common/Modal";
import Button from "../../../../components/common/Button";
import { useDietStore } from "../../../../store/dashboard/diet";
import { useAxiosWithToken } from "../../../../hooks";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ foodCatList, loading, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const reloadDietAllInfo = useDietStore((state) => state.reloadDietAllInfo);
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  const day = dietInfo?.day;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [searchState, setSearchState] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [gram, setGram] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  /* const [state, setState] = useState(); */
  /*   const [foodCatList, setFoodCatList] = useState([]);
  const [loading, setLoading] = useState(false); */
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const openModal = (data) => {
    setModalOpen(true);
    setRowData(data);
  };
  const closeModal = () => {
    setGram("");
    setModalOpen(false);
    setRowData({});
  };
  const addFood = () => {
    setActionLoading(true);
    let params = {
      typeOfMealEnum: type,
      dairyId: rowData.id,
      consumedGram: parseInt(gram),
      monthEnum: month,
      weekEnum: week,
      dayEnum: day,
    };
    useAxiosWithToken
      .put(api.personalProgram.addDairyToPersonalProgram, params)
      .then((res) => {
        setActionLoading(false);
        closeModal();
        reloadDietAllInfo();
      })
      .catch((err) => {
        setActionLoading(false);
        closeModal();
      });
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid w-full mx-auto xl:grid-cols-1 gap-4 xl:gap-2 mt-6">
        <section className=" w-full mx-auto">
          <section className="w-full h-[40px] relative">
            {/* <input type="text" className="w-full xl:w-full h-[40px] mx-auto border border-[#E6E8E9] rounded-full pr-11" placeholder="جستجو" /> */}
            <SearchBox state={searchState} setState={(value) => setSearchState(value)} apiUrl={api.foodsNutrition.searchFoodsNutrition} setValidation={(value) => console.log(value)} required={true} queryStringName="name" openModal={openModal} optionTitle="mainFoodDescription" optionValue="id" title={"جستجو"} />
            {/* <img src={baseFileUrl + "/images/dashboard/search.svg"} className="absolute top-[11px] right-5" alt="" /> */}
          </section>
        </section>
        <section className="diet w-full relative">
          <Loading loading={loading} />
          <section className="grid grid-cols-1 xl:grid-cols-2 gap-1">
            {foodCatList.map((item, index) => (
              <Collapse type={type} item={item} index={index} />
            ))}
          </section>
        </section>
      </section>
      <Modal width={600} open={modalOpen} title="افزودن" onClose={closeModal}>
        <section className="flex items-center justify-between mt-4">
          <span className="font-bold text-sm text-purple">{rowData?.mainFoodDescription}</span>
          <span className="font-normal text-sm">{"به ازای صد گرم - " + rowData?.energyKcal + " کالری"}</span>
        </section>
        <section className="mt-5 relative">
          <span className="absolute left-5 top-[10px] text-sm">گرم</span>
          <input
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, "");
              setGram(value);
            }}
            maxLength={20}
            value={gram}
            type="text"
            className="w-full xl:w-full h-[40px] mx-auto border border-[#E6E8E9] text-sm rounded-full pr-3 pl-12"
            placeholder="مقدار مصرف خود را به گرم وارد کنید"
          />
        </section>
        <section className="ltr">
          <Button disabled={!(gram > 0)} onClick={addFood} loading={actionLoading} type="primary" round className="w-[100px]  bg-purple text-white rounded-full font-normal text-sm mr-auto mt-4">
            ذخیره
          </Button>
        </section>
      </Modal>
    </>
  );
}
