import { useState } from "react";

import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeSection, setActiveSection] = useState(3);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="xl:w-[1117px] w-[1124px] mx-auto flex justify-between mt-8 relative h-auto xl:h-auto">
        <section>
          <Item
            activeSection={activeSection}
            index={1}
            src="/images/icons/icon.png"
            title="آنالیز ترکیبات بدن
با دستگاه"
          />
        </section>
        <section>
          <Item title="ارزیابی ناهنجاری‌های قامتی" activeSection={activeSection} index={2} src="/images/icons/icon2.png" />
        </section>

        <section>
          <Item
            title="مشاوره
با متخصصان ما"
            activeSection={activeSection}
            index={3}
            src="/images/icons/icon3.png"
          />
        </section>
        <section>
          <Item title=" ارائه برنامه غذایی اختصاصی" activeSection={activeSection} index={4} src="/images/icons/icon4.png" />
        </section>
        <section>
          <Item title="ارائه برنامه ورزشی اختصاصی" activeSection={activeSection} index={5} src="/images/icons/icon5.png" />
        </section>
      </section>
    </>
  );
}
