import React, { useEffect } from "react";
import "./style.css";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./components/SideBar";
import Header from "./components/Header";
import { ViewChanger } from "../../components/common";
import { isAdminDoctor, isDoctor } from "../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!isDoctor() && !isAdminDoctor()) {
      navigation("/dashboard");
    }
  }, [navigation]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section id="dashBox" className="fixed w-full h-full overflow-auto  bg-[#f5f7f8] pb-[90px] xl:pb-0">
      <section className="w-[95%] xl:w-[1320px] mt-2 m-auto flex justify-between flex-wrap relative">
        <SideBar />
        <section className="w-[100%] xl:w-[1100px]">
          <Header />
          <main className="mt-1 w-[100%]">
            <Outlet />
          </main>
        </section>
      </section>
      {/*   <ViewChanger /> */}
    </section>
  );
}
