//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

import { svgFileUrl } from "../../../../../../config";

export default function Index({ data, showDeleteModal }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {data.map((item, index) => (
        <section className="bg-slate-100 mb-[4px]  p-2 mt-[20px] rounded-md hover:bg-slate-200 transition-all flex gap-4 justify-between items-center cursor-pointer relative">
          <section>
            <span className="text-[18px] font-bold text-purple">{item?.namePackage}</span>
            <section className="flex flex-col gap-1 items-start">
              <span className="text-[12px] w-[100px] mt-2">لیست غذاها:</span>
              <section className="flex">
                {item?.foodProgramPackageDetailOutputDTO?.map((item1, index1) => (
                  <section>
                    {index1 > 0 && <span>/</span>}
                    <span className="text-[12px]" key={index1}>
                      {item1?.foodNutritionValuesOutputDto?.mainFoodDescription}
                    </span>
                  </section>
                ))}
              </section>
            </section>
          </section>

          <button className="  bg-white rounded-full shadow-lg p-2" onClick={() => showDeleteModal(item.id)}>
            <img src={svgFileUrl + "trash.svg"} alt="" className="hidden xl:block" />
          </button>
        </section>
      ))}
    </>
  );
}
