import { useState, useEffect } from "react";
import { ComponentLoading, NoRecord } from "../../../../../components/common";
import { imagesFileUrl } from "../../../../../config";
import Item from "../Item";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { Pagination } from "antd";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getData = (currentPage) => {
    setLoading(true);
    useAxiosWithToken
      .get(api.request.getAllRequest + `?requestProgramType=MEDICINE&requestProgramStatus=TAKEN_AND_NEED_CHANGE&pageNo=${currentPage - 1}&pageSize=8`)
      .then((res) => {
        setLoading(false);
        setData(res.data.elements);
        setTotalElements(res.data.totalElements);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[14px] pb-10 relative bg-white rounded-[16px] shadow-md">
      <ComponentLoading loading={loading} />
      {data.length === 0 && <NoRecord />}
      <section className="grid grid-cols-1 p-[20px] xl:grid-cols-4 gap-4">
        {data.map((item, index) => (
          <Item rowData={item} image={imagesFileUrl + "/dashboard/Avatar.png"} />
        ))}
      </section>
      {data.length > 0 && <Pagination className="ltr text-center mt-5 sans" defaultCurrent={1} current={currentPage} pageSize={8} onChange={(value) => setCurrentPage(value)} showSizeChanger={false} total={totalElements} />}
      {/* <section className="text-center">
          <button className="w-[149px] h-[40px] border border-purple mt-[31px] mb-[32px] rounded-full ">مشاهده بیشتر</button>
        </section> */}
    </section>
  );
}
