import React from 'react';

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ fill }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.03125 1.90605H1.65625C1.39716 1.90605 1.1875 2.11571 1.1875 2.3748C1.1875 2.6339 1.39716 2.84355 1.65625 2.84355H4.03125C4.29034 2.84355 4.5 2.6339 4.5 2.3748C4.5 2.11571 4.29034 1.90605 4.03125 1.90605ZM4.03125 5.65605H1.65625C1.39716 5.65605 1.1875 5.86571 1.1875 6.1248C1.1875 6.3839 1.39716 6.59355 1.65625 6.59355H4.03125C4.29034 6.59355 4.5 6.3839 4.5 6.1248C4.5 5.86571 4.29034 5.65605 4.03125 5.65605ZM3.09375 3.78105H0.71875C0.459656 3.78105 0.25 3.99071 0.25 4.2498C0.25 4.5089 0.459656 4.71855 0.71875 4.71855H3.09375C3.35284 4.71855 3.5625 4.5089 3.5625 4.2498C3.5625 3.99071 3.35284 3.78105 3.09375 3.78105ZM15.3125 6.1248H12.4688V4.2498C12.4688 3.42649 11.4616 2.99299 10.8681 3.58724L7.11841 7.33696C6.75219 7.70318 6.75219 8.29643 7.11841 8.66265L9.26806 10.8123L6.64966 13.4307C6.28344 13.7969 6.28344 14.3902 6.64966 14.7564C7.01584 15.1226 7.60913 15.1226 7.97534 14.7564L11.2566 11.4751C11.6228 11.1089 11.6228 10.5157 11.2566 10.1495L9.10694 7.9998L10.5938 6.51299V7.0623C10.5938 7.58002 11.0135 7.9998 11.5312 7.9998H15.3125C15.8302 7.9998 16.25 7.58002 16.25 7.0623C16.25 6.54458 15.8302 6.1248 15.3125 6.1248ZM9.00438 1.36043C8.63175 1.11277 8.13737 1.16177 7.8215 1.47761L5.24337 4.05574C4.87716 4.42196 4.87716 5.01521 5.24337 5.38143C5.60959 5.74765 6.20288 5.74761 6.56909 5.3814L8.60384 3.34665L9.28928 3.8404L10.2051 2.92458C10.3583 2.7714 10.539 2.65249 10.7366 2.55965L9.00438 1.36043Z" fill={fill}/>
    <path d="M6.45557 9.32647C6.21804 9.08894 6.05948 8.79659 5.97588 8.48047L1.02466 13.4317C0.658445 13.7979 0.658445 14.3912 1.02466 14.7574C1.39085 15.1236 1.98413 15.1236 2.35035 14.7574L7.11841 9.98931L6.45557 9.32647Z" fill={fill}/>
    <path d="M12.9375 3.3125C13.7142 3.3125 14.3438 2.6829 14.3438 1.90625C14.3438 1.1296 13.7142 0.5 12.9375 0.5C12.1608 0.5 11.5312 1.1296 11.5312 1.90625C11.5312 2.6829 12.1608 3.3125 12.9375 3.3125Z" fill={fill}/>
    </svg>
  );
}
