import { useState, useEffect } from "react";
import { useDietStore } from "../../../../../../../../../store/dashboard/diet";
import { useAxiosWithToken } from "../../../../../../../../../hooks";
import api from "../../../../../../../../../api";
import PDFDownload from "../PDFDownload";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const day = dietInfo?.day;
  const month = dietInfo?.month;
  const week = dietInfo?.week;
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  /*  const [programList, setProgramList] = useState([]); */
  // ─── Functions ──────────────────────────────────────────────────────────────────
  /*  const handleOnChange = (e) => {
    let programs = [...programList];
    if (e.target.checked) {
      programs.push(e.target.value);
      setProgramList(programs);
      // console.log(programList);
    } else if (e.target.checked === false) {
      setProgramList(programs.filter((el) => el.id !== e.target.value.id));
    }
  }; */
  const getPlanExer = function () {
    setLoading(true);
    useAxiosWithToken
      .get(api.sportPlan.getSportPlan + `?monthEnum=${month}&weekEnum=${week}&dayEnum=${day}`)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => setLoading(false));
  };
  useEffect(() => {
    getPlanExer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    console.log(loading);
  }, [loading]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="flex justify-between mt-[50px]">
        <h3 className="font-bold"> برنامه پیشنهادی مربی </h3>
        {data.length > 0 && (
          <section className="flex gap-2">
            {/* <button
              onClick={() => {
                notify.Success("عملیات با موفقیت انجام شد");
              }}
              className={` w-[118px] h-[30px]  border border-[#F3509D] hover:text-white hover:bg-[#F3509D]   rounded-[100px] flex justify-center items-center text-[#F3509D]  text-sm`}
            >
              انجام شد
            </button> */}
            <PDFDownload data={data} />
          </section>
        )}
      </section>
      {data?.map((item, index) => (
        <section className="rounded-lg bg-white shadow-lg w-full min-h-10 mt-5 p-3">
          <section className="flex justify-start gap-2">
            <span className="font-bold min-w-[60px]">تمرین {index + 1}</span>
            <section className="text-right w-[300px]">
              نام حرکت: <span className="font-semibold">{item?.exerciseName}</span>
            </section>
            <section className="text-right">
              سیستم تمرینی: <span className="font-semibold">{item?.trainingSystem}</span>
            </section>
          </section>
          <section className="bg-[#fdf9ff] mt-1 py-2">
            <section className="grid grid-cols-1 xl:grid-cols-4 text-center text-sm">
              <section>شماره</section>
              <section>تعداد تکرار</section>
              {/*  <section> تکرار دستی</section> */}
              <section>استراحت</section>

              <section>tempo</section>
            </section>
            {item?.sets?.map((item, index) => (
              <section className="grid grid-cols-1 xl:grid-cols-4 text-center text-sm">
                <section>ست {item?.id}</section>
                <section>{item?.setRepeat}</section>
                {/*   <section>{item?.repeatHandle}</section> */}
                <section>{item?.rest}</section>

                <section>{item?.tempo}</section>
              </section>
            ))}
            <hr className="border-silver my-2" />
            <section className="px-2 text-sm font-light">
              توان به درصد: <span className="font-medium">{item.tavan}</span>
            </section>
            <section className="px-2 text-sm font-light">
              وزن به کیلوگرم:<span className="font-medium">{item.weight}</span>
            </section>
            <section className="px-2 text-sm font-light">
              توضیحات: <span className="font-medium"> {item.description}</span>
            </section>
          </section>
        </section>
      ))}
    </>
  );
}
