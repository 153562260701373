import { BlobProvider } from "@react-pdf/renderer";
import FoodListPdf from "../../../../../components/PDF/FoodListPdf";
const MyPDFPage = ({ data }) => {
  return (
    <BlobProvider document={<FoodListPdf data={data} />}>
      {({ url, loading }) =>
        loading ? (
          <button disabled>در حال بارگزاری</button>
        ) : (
          <a href={url} download="example.pdf" className={`h-[40px] w-[100px] flex justify-center items-center bg-purple py-3 text-white rounded-lg mr-auto`}>
            دانلود PDF
          </a>
        )
      }
    </BlobProvider>
  );
};

export default MyPDFPage;
