import { useState } from "react";
import ExerciseList from "./components/ExerciseList";
import { Button } from "../../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  const [reload, setReload] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const Reload = () => {
    setReload(!reload);
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className=" h-auto mt-[40px] py-8 px-4 xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
      <ExerciseList reloadTable={Reload} reload={reload} />
    </section>
  );
}
