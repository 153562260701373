import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAxiosWithToken } from "../../../../hooks";
import api from "../../../../api";
import Select from "../../../../components/common/inputs/Select";
import Textbox from "../../../../components/common/inputs/Textbox";
import Button from "../../../../components/common/Button";
import { notify } from "../../../../helper";
import { NotifyMessage } from "../../../../enums";
import { AsyncSelect } from "../../../../components/common";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ onCloseBtn, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [loading, setLoading] = useState(false);
  const [persianName, setPersianName] = useState("");

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const onSubmit = (data) => {
    let params = {
      persianName: data.persianName,
    };
    setLoading(true);

    useAxiosWithToken
      .post(api.admin.addClassification, params)
      .then((res) => {
        setLoading(false);
        reloadTable();
        onCloseBtn();

        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <section className="grid  gap-4 mt-4">
        <section>
          <span> نام تمرین</span>

          <Controller
            control={control}
            name="persianName"
            rules={{
              required: " نام تمرین اجباری است",
            }}
            render={({ field: { onChange, value } }) => (
              <Textbox className="p-0" onChange={onChange} value={value} />
            )}
          />
          {errors.persianName && (
            <span className="text-[red] font-normal">
              {errors.persianName.message}
            </span>
          )}
        </section>

        <section className="  justify-self-end">
          <Button
            type="submit"
            loading={loading}
            className="w-[170px] h-[48px]  bg-[#5408A9] leading-[26px] rounded-[5px] text-white"
          >
            ثبت
          </Button>
        </section>
      </section>
    </form>
  );
}
