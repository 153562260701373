import { baseFileUrl } from "../../../../../config";

// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({
  src,

  activeSection,
  index,
  title,
}) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className={`${activeSection === index ? "border-[#F9A7CE] shadow-[0px_20.5px_44.42px_-11.96px_#5408A947] " : "border-[#BB9CDD]"} w-[200px] flex flex-col items-center px-6 pt-2 justify-center gap-[38px] xl:mt-8 h-full border-[1.38px]    rounded-[20px] border-solid `}>
        <section className={`${activeSection === index ? "bg-[#FEEAF3]" : "bg-[#F1E8FC]"} bg-opacity-40 flex justify-center items-center w-[76px] h-[76px] px-1 rounded-full`}>
          <section className={`${activeSection === index ? "bg-[#FEEAF3]" : "bg-[#F1E8FC]"} flex justify-center items-center  rounded-full`}>
            <img src={baseFileUrl + src} alt="" className="" />
          </section>
        </section>
        <h3 className={`${activeSection === index ? "text-[#F02484]  " : "text-[#5408A9]"} leading-[24.8px] text-center font-bold`}>{title}</h3>
      </section>
    </>
  );
}
