import { useState, useEffect, Fragment } from "react";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import MealTypeList from "./components/MealTypeList";

/* import { useBasicInfoStore } from "../../../../../store/dashboard/basicInfo"; */
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, ConfirmModal } from "../../../../../components/common";
import { notify } from "../../../../../helper";
import { NotifyMessage } from "../../../../../enums";
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ reload, reloadTable, totalAction }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  /*   const basicInfo = useBasicInfoStore((state) => state.basicInfo); */
  const [searchParams] = useSearchParams();
  const mobileNumber = searchParams.get("mobileNumber");
  const requestId = searchParams.get("userId");
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dietTabActive, setDietTabActive] = useState(1);
  const [actionLoading, setActionLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getExerciseList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.foodProgramPackage.userPackageList + `?requestId=${requestId}&username=${mobileNumber}`)
      .then((res) => {
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const deleteFood = () => {
    setDeleteLoading(true);
    useAxiosWithToken
      .delete(api.doctor.foodProgramDelete + data?.personalFoodProgramId)
      .then((res) => {
        setDeleteLoading(false);
        getExerciseList();
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setDeleteModal(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };
  const approve = () => {
    setActionLoading(true);
    useAxiosWithToken
      .put(api.coach.editForApprove + "?requestProgramStatus=TAKEN_AND_WAITING&personalFoodProgramId=" + data?.personalFoodProgramId + "&id=" + requestId)
      .then((res) => {
        setActionLoading(false);
        setApproveModal(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setTimeout(() => {
          navigation("/doctor-dashboard/without-plan-user");
        }, 2000);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getExerciseList();
  }, [reload]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] px-1 xl:px-[34px] py-[44px] shadow-md mt-4">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">برنامه های غذایی</span>
      </section>
      {/* <section className="my-2">{data && basicInfo.firstName && <DownloadPdf data={data} basicInfo={basicInfo} />}</section> */}
      <section className="w-[800px] min-h-[60px] mt-5 h-auto mb-8 flex flex-wrap justify-between items-center rounded-md xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] p-2">
        <button onClick={() => setDietTabActive(1)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 1 && `bg-[#F1E8FC]`}`}>
          صبحانه
        </button>
        <button onClick={() => setDietTabActive(2)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 2 && `bg-[#F1E8FC]`}`}>
          میان وعده
        </button>
        <button onClick={() => setDietTabActive(3)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 3 && `bg-[#F1E8FC]`}`}>
          ناهار
        </button>

        <button onClick={() => setDietTabActive(4)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 4 && `bg-[#F1E8FC]`}`}>
          شام
        </button>
      </section>
      {dietTabActive === 1 && <MealTypeList reload={getExerciseList} loading={loading} data={data?.breakfasts} reloadTable={reloadTable} />}
      {dietTabActive === 2 && <MealTypeList reload={getExerciseList} loading={loading} data={data?.snackBeforeLunches} reloadTable={reloadTable} />}
      {dietTabActive === 3 && <MealTypeList reload={getExerciseList} loading={loading} data={data?.lunches} reloadTable={reloadTable} />}
      {dietTabActive === 4 && <MealTypeList reload={getExerciseList} loading={loading} data={data?.dinners} reloadTable={reloadTable} />}
      {data?.personalFoodProgramId && totalAction && (
        <section className="flex justify-start gap-2 mt-3 items-center">
          <Button onClick={() => setApproveModal(true)} className="">
            تایید پرونده پزشکی
          </Button>
          <Button loading={deleteLoading} onClick={() => setDeleteModal(true)} className=" bg-red-500">
            حذف کل برنامه ها
          </Button>
        </section>
      )}
      {data?.rejectDescription && (
        <section className="flex gap-2 text-white bg-red-500 mt-4 p-2 rounded-lg">
          <span>دلیل رد برنامه:</span>
          <span className="">{data?.rejectDescription}</span>
        </section>
      )}
      <ConfirmModal successFunction={approve} actionLoading={actionLoading} title="تایید پرونده پزشکی" openModal={approveModal} setOpenModal={setApproveModal} question="آیا مایلید پرونده مورد نظر تایید شود؟" />
      <ConfirmModal successFunction={deleteFood} actionLoading={deleteLoading} title="حذف پرونده پزشکی" openModal={deleteModal} setOpenModal={setDeleteModal} question="آیا مایلید پرونده مورد نظر حذف شود؟" />
    </section>
  );
}
