import { baseFileUrl } from "../../../../../../../../../config";
import { Fragment, useState } from "react";
import PDFDownload from "../PDFDownload";
import { Button } from "../../../../../../../../../components/common";
import api from "../../../../../../../../../api";
import { notify } from "../../../../../../../../../helper";
import { NotifyMessage } from "../../../../../../../../../enums";
import { useAxiosWithToken } from "../../../../../../../../../hooks";
import Level2Dtl from "./components/Level2Dtl";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [detailShow, setDetailShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  /*  useEffect(() => {
    if (!detailShow) {
      setDetail2Show(false);
    }
  }, [detailShow]); */
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const approveExercise = (id) => {
    setLoading(true);
    useAxiosWithToken
      .put(api.coach.approveCoachExercise + id)
      .then((res) => {
        setLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        reloadTable();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const rejectExercise = (id) => {
    setRemoveLoading(true);
    useAxiosWithToken
      .delete(api.coach.removeCoachExercise + id)
      .then((res) => {
        setRemoveLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        reloadTable();
      })
      .catch((err) => {
        setRemoveLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-1 xl:grid-cols-5 gap-1 px-2 py-4 mt-[12px] border border-[#E8E7EB] h-auto   items-center rounded-[12px] cursor-pointer font-normal transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section className="flex justify-center">
          <PDFDownload data={rowData} />
        </section>
        <section className="flex justify-center  items-center gap-2">
          <button onClick={() => setDetailShow(!detailShow)}>
            <img className={`w-[20px] ${!detailShow ? "rotate-90" : "rotate-[-90deg]"}`} src={baseFileUrl + "/arrowRight.png"} alt="" />
          </button>
        </section>
        <section className="flex gap-2 col-span-2 justify-center">
          {/*  <Button loading={loading} onClick={() => approveExercise(rowData.id)} className="w-[140px] h-[30px] bg-green-600 text-white rounded-full">
            تایید نهایی
          </Button> */}
          <Button loading={removeLoading} onClick={() => rejectExercise(rowData.id)} className="w-[120px] h-[30px] bg-red-500 text-white rounded-full">
            حذف
          </Button>
        </section>
      </section>
      {detailShow && (
        <>
          <section className="w-full bg-slate-100 min-h-[70px] rounded-lg">
            <section className="grid grid-cols-5 py-2 text-xs">
              <section className="w-full font-bold">شناسه تمرین</section>
              <section className="w-full font-bold">سیستم تمرینی</section>
              <section className="w-full font-bold">نام تمرین</section>
              <section className="w-full font-bold">نام عضله</section>
              <section className="w-full font-bold">جزییات</section>
            </section>
            {rowData?.personalSportProgramDetails?.map((item, index) => (
              <Fragment key={index}>
                <Level2Dtl item={item} />
              </Fragment>
            ))}
          </section>
        </>
      )}
    </>
  );
}
