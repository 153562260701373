//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ level, setLevel, total }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full flex justify-between">
      <section className="w-[344px] flex justify-between items-center">
        {/*  <span className="block font-bold">سطح تمرینات:</span> */}
        {/* <section className="flex cursor-pointer" onClick={() => setLevel(1)}>
          <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all ${level === 1 && "bg-purple"}`}>
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
          <label className="text-sm font-normal  mr-2">همه</label>
        </section>
        <section className="flex cursor-pointer" onClick={() => setLevel(2)}>
          <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all  ${level === 2 && "bg-purple"}`}>
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
          <label className="text-sm font-normal mr-2">پیشرفته</label>
        </section>
        <section className="flex cursor-pointer" onClick={() => setLevel(3)}>
          <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full transition-all  ${level === 3 && "bg-purple"}`}>
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
          <label className="text-sm mr-2 font-normal">مبتدی</label>
        </section> */}
      </section>
      <span>{total} تمرین آموزشی</span>
    </section>
  );
}
