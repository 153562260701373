import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../../components/common/Modal";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ Icon, title, active, link, mode }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [modal, setModal] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const logOut = () => {
    localStorage.clear();
    navigation("/login");
  };
  const hideModal = () => {
    setModal(false);
  };

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {mode === "logOut" ? (
        <section onClick={() => setModal(true)} className="flex cursor-pointer justify-start mt-3 items-center font-bold w-full h-[54px] text-[14px] text-textGray rounded-[12px] pr-7">
          <section className={`w-[32px] h-[32px] rounded-[8px] flex justify-center items-center ${active ? "bg-purple" : "bg-white shadow-md "}`}>
            <Icon fill={active ? "white" : "#5606a8"} />
          </section>
          <span className="mr-3">خروج</span>
        </section>
      ) : (
        <Link to={link} className={`flex justify-start  items-center font-bold w-full h-[54px] text-[14px] text-textGray rounded-[12px] pr-7 ${active && "bg-white shadow-md"}`}>
          <section className={`w-[32px] h-[32px] rounded-[8px] flex justify-center items-center ${active ? "bg-purple" : "bg-white shadow-md "}`}>
            <Icon fill={active ? "white" : "#5606a8"} />
          </section>
          <span className="mr-3 text-[13px] font-semibold">{title}</span>
        </Link>
      )}
      <Modal onClose={hideModal} title="خروج" open={modal}>
        <section className="text-center mt-10">آیا مایل به خروج هستید؟</section>
        <section className="flex justify-center mt-4 gap-3">
          <button onClick={hideModal} className="w-[128px] h-10 bg-white border border-[#5408A9] text-[#5408A9]  rounded-[5px]">
            لغو
          </button>
          <button onClick={logOut} className="w-[128px] h-10 bg-[#5408A9]  rounded-[5px] text-white">
            خروج
          </button>
        </section>
      </Modal>
    </>
  );
}
