import { baseFileUrl } from "../../../../../config";
import { Fragment, useState } from "react";

import Level2Dtl from "./components/Level2Dtl";
import { getPersianMealName } from "../../../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [detailShow, setDetailShow] = useState(false);

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  /*  useEffect(() => {
    if (!detailShow) {
      setDetail2Show(false);
    }
  }, [detailShow]); */
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-4 xl:grid-cols-4 gap-1 px-2 py-4 mt-[12px] border border-[#E8E7EB] h-auto   items-center rounded-[12px] cursor-pointer font-normal transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section>
          <span className="mr-1">{rowData?.namePackage}</span>
        </section>
        {/*  <section>
          <span className="mr-1">{getPersianMealName(rowData?.typeOfMeal)}</span>
        </section> */}
        <section>
          <span className="mr-1 text-xs font-light">{rowData?.description}</span>
        </section>
        <section className="flex justify-center  items-center gap-2">
          <button onClick={() => setDetailShow(!detailShow)}>
            <img className={`w-[20px] ${!detailShow ? "rotate-90" : "rotate-[-90deg]"}`} src={baseFileUrl + "/arrowRight.png"} alt="" />
          </button>
        </section>
      </section>
      {detailShow && (
        <>
          <section className="w-full bg-slate-100 min-h-[70px] rounded-lg">
            <section className="grid grid-cols-3 xl:grid-cols-3 py-2 text-xs">
              <section className="w-full font-bold">شناسه</section>
              <section className="w-full font-bold">نام غذا</section>
              <section className="w-full font-bold">مقدار</section>
              {/*   <section className="w-full font-bold">کالری</section>
              <section className="w-full font-bold">پروتئین</section>
              <section className="w-full font-bold">چربی</section>
              <section className="w-full font-bold">کربوهیدرات</section> */}
            </section>
            {rowData?.foodProgramPackageDetails?.map((item, index) => (
              <Fragment key={index}>
                <Level2Dtl item={item} />
              </Fragment>
            ))}
          </section>
        </>
      )}
    </>
  );
}
