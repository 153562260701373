import React from "react";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setActiveLinkTab, activeLinkTab }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[741px] max-w-full flex gap-2 justify-between my-[20px]">
      <section onClick={() => setActiveLinkTab(1)} className={`w-[105px] h-[43px] xl:w-[235px] xl:h-[97px] flex bg-purple rounded-[12px] cursor-pointer transition-all duration-500 relative ${activeLinkTab === 1 && "shadow-violet-600 shadow-lg"}`}>
        <img src={imagesFileUrl + "dashboard/dietShape1.png"} className="absolute xl:rounded-[12px] rounded-[6px] w-full h-full z-0" alt="" />
        <img src={imagesFileUrl + "dashboard/miniPlate.png"} className="w-[45px] xl:w-auto absolute z-1 top-[-18px] left-2" alt="" />
        <span className="text-white w-full text-center xl:text-right font-bold text-[13px] xl:text-[22px] absolute bottom-[5px] xl:bottom-[22px] right-0 xl:right-[20px]">برنامه غذایی</span>
      </section>
      <section onClick={() => setActiveLinkTab(3)} className={` ${activeLinkTab === 3 && "shadow-pink-500 shadow-lg"} w-[105px] h-[43px] xl:w-[235px] xl:h-[97px] bg-[#FEF4F9]  rounded-[12px] flex relative cursor-pointer`}>
        <img src={imagesFileUrl + "dashboard/dietShape2.png"} className="absolute rounded-[6px] xl:rounded-[12px] bottom-[-1px]  w-full h-full z-0" alt="" />
        <img src={imagesFileUrl + "dashboard/damble.png"} className="w-[50px] xl:w-auto absolute z-1 top-[-18px] left-2" alt="" />
        <span className="text-[#F02484] w-full text-center xl:text-right font-bold text-[13px] xl:text-[22px] absolute bottom-[5px] xl:bottom-[22px] right-0 xl:right-[20px]">برنامه ورزشی</span>
      </section>
      <section onClick={() => setActiveLinkTab(2)} className={`w-[105px] h-[43px] xl:w-[235px]  xl:h-[97px] bg-[#F6FCFE] rounded-[12px] relative transition-all duration-500 cursor-pointer ${activeLinkTab === 2 && "shadow-cyan-300 shadow-lg"}`}>
        <img src={imagesFileUrl + "dashboard/dietShape3.png"} className="absolute w-full rounded-[6px] xl:rounded-[12px] bottom-[-1px] z-0 h-full" alt="" />
        <img src={imagesFileUrl + "dashboard/blob.png"} className="w-[25px] xl:w-auto absolute z-1 top-[-18px] left-2" alt="" />
        <span className="text-[#4CC9EC] w-full text-center xl:text-right font-bold text-[13px] xl:text-[22px] absolute bottom-[5px] xl:bottom-[22px] right-0 xl:right-[20px]">مصرف آب امروز</span>
      </section>
    </section>
  );
}
