import { imagesFileUrl, svgFileUrl } from "../../../../config";
import MenuItem from "./components/MenuItem";
import Target from "./svg/Target";
import Exit from "./svg/Exit";
import Profile from "./svg/Profile";
import { Link, useLocation } from "react-router-dom";
import { Fragment } from "react";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const location = useLocation();
  const menuItems = [
    {
      link: "/admin",
      active: location.pathname === "/admin",
      title: "داشبورد",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/users",
      active: location.pathname === "/admin/users",
      title: "لیست کاربران",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/food-admin-list",
      active: location.pathname === "/admin/food-admin-list",
      title: "لیست غذاها",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/question-list",
      active: location.pathname === "/admin/question-list",
      title: "لیست سوالات",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/contactus-list",
      active: location.pathname === "/admin/contactus-list",
      title: "تماس با ما",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/department-list",
      active: location.pathname === "/admin/department-list",
      title: "لیست دپارتمان ها",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/exercise-category",
      active: location.pathname === "/admin/exercise-category",
      title: " دسته بندی تمرین",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/equipment-list",
      active: location.pathname === "/admin/equipment-list",
      title: "لیست ابزار ورزشی",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/muscle-list",
      active: location.pathname === "/admin/muscle-list",
      title: "لیست عضلات",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/supplement",
      active: location.pathname === "/admin/supplement",
      title: " تعریف مکمل",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/recipe-list",
      active: location.pathname === "/admin/recipe-list",
      title: "رسپی",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/transaction-list",
      active: location.pathname === "/admin/transaction-list",
      title: "تراکنش ها",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/food-package",
      active: location.pathname === "/admin/food-package",
      title: "پکیج های غذایی",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/food-program-list",
      active: location.pathname === "/admin/diets",
      title: "برنامه های غذایی",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/admin/exercise-list",
      active: location.pathname === "/admin/exercise-list",
      title: "برنامه های ورزشی",
      className: "menuItem",
      icon: Profile,
    },
    {
      link: "/dashboard",
      title: "بازگشت به داشبورد",
      className: "menuItem",
      icon: Target,
    },

    {
      link: "/",
      title: "بازگشت به سایت",
      className: "menuItem",
      icon: Target,
    },
    {
      title: "خروج",
      mode: "logOut",
      className: "menuItem",
      icon: Exit,
    },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="fixed left-0 bottom-0 w-full h-[64px]  bg-white  shadow-[0px_-1px_9px_0px_#5408A926] rounded-t-[20px] z-[1000] px-[10%] flex justify-between xl:hidden ">
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuPeople.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          پروفایل
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuTarget.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          هدف
        </span>
        <span className="flex w-[56px] h-[56px] cursor-pointer  bg-[#5408A9] mt-[-18px] rounded-full shadow-[0px_14px_23px_-6px_#5408A980] justify-center items-center">
          <img
            src={svgFileUrl + "mobileMenu.svg"}
            alt=""
            className="w-[20px] h-[20px]"
          />
        </span>
        <span className="flex flex-col justify-center cursor-pointer  items-center">
          <img
            src={svgFileUrl + "mobileMenuSport.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          ورزش
        </span>
        <span className="flex flex-col justify-center cursor-pointer items-center">
          <img
            src={svgFileUrl + "mobileMenuKnife.svg"}
            alt=""
            className="w-[18px] h-[18px]"
          />
          آشپزی
        </span>
      </section>
      <aside className="w-[199px] hidden xl:block">
        <Link to="/">
          <Link to="/">
            <img className="w-[100px] m-auto" src={imagesFileUrl + "logoWhite.png"} alt="" />
          </Link>
        </Link>
        <nav className="mt-10">
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <MenuItem
                title={item.title}
                link={item.link}
                active={item.active}
                Icon={item.icon}
                mode={item.mode}
              />
            </Fragment>
          ))}
        </nav>
      </aside>
    </>
  );
}
