import { imagesFileUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/common";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-[95%] mx-auto relative flex border border-slate-200 bg-gradient-to-b from-[#F9F5FE] to-[#FFFFFF] mt-[30px]  xl:mt-[110px] h-[700px] xl:h-[527px] overflow-hidden rounded-[50px]">
        <section>
          <img className="w-[200px] xl:w-auto xl:h-[350px] hidden xl:block absolute top-[250px] xl:top-[-60px] xl:right-[250px]" src={imagesFileUrl + "section2dbm.png"} alt="" />
          <img className="h-[100px] xl:h-[200px] absolute top-[370px] right-[-20px] xl:top-[20px] xl:right-[60px]" src={imagesFileUrl + "section2watch.png"} alt="" />
          <img className="absolute w-[160px] left-[2%] bottom-[20%] xl:w-[482px] xl:bottom-[0] xl:right-[500px]" src={imagesFileUrl + "section2bgdmb.png"} alt="" />
          <img className=" block xl:hidden w-[77px] right-[30%] absolute top-[300px] " src={imagesFileUrl + "icons/ketelball-home.png"} alt="" />
          <section className="absolute h-[75px] xl:h-auto bottom-[170px] xl:bottom-[80px] w-[90%] right-5 xl:w-[535px] xl:right-[100px] z-20">
            <section className="flex xl:justify-between gap-5 items-center">
              <span className="text-[20px] xl:text-[50px] font-bold">برنامه ورزشی</span>
            </section>
            <section className="flex flex-col xl:gap-4 justify-center ">
              <span className="font-normal mt-4 text-[14px] xl:w-[391px] xl:text-[20px]">برنامه ورزشی اختصاصی توسط مربیان حرفه ای بین الملل</span>
              <Link to="/exercise-plan" className="">
                <Button className=" xl:flex hidden  transition-all hover:shadow-md hover:shadow-[#A6A9BD] hover:translate-y-[-5px] h-[44px] ml-auto rounded-[150px]">مشاهده برنامه های ورزشی</Button>
              </Link>
            </section>
            <Link to="/exercise-plan" className="">
              <Button className="flex xl:hidden mt-[24px] text-[12px]  w-[239px] xl:w-[140px] h-[44px] ml-[0px] rounded-[150px]">مشاهده برنامه ورزشی</Button>
            </Link>
          </section>
        </section>
        <section className="absolute left-0">
          <section className="absolute text-right block w-[65%] left-5 xl:w-[60%] xl:right-[200px] top-[70px] xl:top-[30px] xl:[40px]">
            <span className="block text-[20px] xl:text-[50px] font-bold">برنامه غذایی</span>
            <span className="block text-base text-[10px] mt-[30px] xl:text-[20px] text-justify  font-normal">رژیم منعطف اختصاصی توسط کارشناس تغذیه</span>
            <Link to="/meal-plan" className=" ">
              <Button secondary={true} className="transition-all text-[12px] mr-[20px] xl:text-base hover:shadow-md hover:shadow-[#A6A9BD]  xl:mr-[-10px] hover:translate-y-[-5px] h-[44px] mt-2 rounded-[150px] xl:mt-[20px] ">
                مشاهده برنامه های غذایی
              </Button>
            </Link>
          </section>
          <img className="h-[577px] hidden xl:block" src={imagesFileUrl + "section2vec.png"} alt="" />
          <img className="w-[364px] xl:hidden" src={imagesFileUrl + "section2mobilevec.png"} alt="" />
          <img className="w-[120px] xl:w-[280px] absolute left-0 top-[260px] xl:top-[240px] xl:left-[70px]" src={imagesFileUrl + "section2salad.png"} alt="" />
        </section>
      </section>
    </>
  );
}
