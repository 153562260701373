import { useState } from "react";

import ManFront from "../../../../components/SVG/ManFront";
import ManBack from "../../../../components/SVG/ManBack";
import WomanFront from "../../../../components/SVG/WomanFront";
import WomanBack from "../../../../components/SVG/WomanBack";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ gender, setMuscle, muscle }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [front22, setFront22] = useState(false);
  const [back22, setBack22] = useState(false);
  const [front16, setFront16] = useState(false);
  const [back16, setBack16] = useState(false);
  const [front11, setFront11] = useState(false);
  const [back11, setBack11] = useState(false);
  const [front12, setFront12] = useState(false);
  const [back12, setBack12] = useState(false);
  const [front7, setFront7] = useState(false);
  const [back7, setBack7] = useState(false);
  /* const [muscle, setMuscle] = useState(''); */
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-5">
      <span className="font-bold text-[22px] m-auto block text-center">انتخاب عضله</span>
      <section className=" mt-[24px]">
        {gender === "FEMALE" && (
          <section className="grid grid-cols-1 xl:grid-cols-2">
            <section>
              <WomanFront style={{ height: "350px" }} setMuscle={setMuscle} front11={front11} front12={front12} front16={front16} front22={front22} front7={front7} setBack7={setBack7} setBack11={setBack11} setBack12={setBack12} setBack16={setBack16} setBack22={setBack22} alt="" />
            </section>
            <section>
              <WomanBack style={{ height: "350px" }} setMuscle={setMuscle} alt="" back11={back11} back12={back12} back16={back16} back22={back22} back7={back7} setFront7={setFront7} setFront11={setFront11} setFront12={setFront12} setFront16={setFront16} setFront22={setFront22} />
            </section>
          </section>
        )}
        {gender === "MALE" && (
          <section className="grid grid-cols-1 xl:grid-cols-2">
            <section>
              <ManFront style={{ height: "350px" }} setMuscle={setMuscle} alt="" front11={front11} front12={front12} front16={front16} front22={front22} front7={front7} setBack7={setBack7} setBack11={setBack11} setBack12={setBack12} setBack16={setBack16} setBack22={setBack22} />
            </section>
            <section>
              <ManBack style={{ height: "350px" }} back11={back11} back12={back12} back16={back16} back22={back22} back7={back7} setFront7={setFront7} setFront11={setFront11} setFront12={setFront12} setFront16={setFront16} setFront22={setFront22} setMuscle={setMuscle} alt="" />
            </section>
          </section>
        )}
      </section>
    </section>
  );
}
