import React, { useState } from "react";
import { svgFileUrl } from "../../../../../../../../config";

import AddDietModal from "./AddDietModal";
import ListItem from "./ListItem";
import { Button, Modal } from "../../../../../../../../components/common";
import { useAxiosWithToken } from "../../../../../../../../hooks";
import api from "../../../../../../../../api";
import { notify } from "../../../../../../../../helper";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ packageData, setPackageData, clearHeader }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [addModal, setAddModal] = useState(false);
  const [foodList, setFoodList] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const deleteFood = (id) => {
    setFoodList((prevItems) => [...prevItems.filter((el) => el.foodNutritionId !== id)]);
  };
  const saveFood = () => {
    let params = foodList;
    setActionLoading(true);
    useAxiosWithToken
      .post(api.foodProgramPackage.addFoodProgramPackageDetail, params)
      .then((res) => {
        setActionLoading(false);
        notify.Success("غذا با موفقیت افزوده شد");
        setFoodList([]);
        setPackageData();
        clearHeader();
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[20px] mb-6 xl:mb-0 w-[90%] mx-auto border xl:w-full border-[#E6E8E9] rounded-[10px] px-[16px] py-[24px]">
      <section className="flex justify-between">
        <span className="font-bold rounded-[5px] bg-[#F9F5FE] w-auto p-4 text-sm xl:text-base xl:h-[26px]  flex items-center justify-center  text-purple text-center">{packageData?.namePackage}</span>
        <button onClick={() => setAddModal(true)} className="w-[117px] h-[40px] border border-purple text-purple  rounded-full mr-[16px] flex gap-2 justify-center items-center">
          <img src={svgFileUrl + "plus.svg"} alt="" />
          انتخاب غذا
        </button>
      </section>
      {foodList?.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem deleteFood={deleteFood} item={item} index={index + 1} />
        </React.Fragment>
      ))}
      {foodList.length > 0 && (
        <Button onClick={saveFood} loading={actionLoading} className="mt-5 m-auto w-[160px] h-[40px] xl:h-12 xl:w-[180px]">
          ثبت نهایی
        </Button>
      )}
      <Modal title="انتخاب غذا" width={700} open={addModal} onClose={() => setAddModal(false)}>
        <AddDietModal onClose={() => setAddModal(false)} foodList={foodList} setFoodList={setFoodList} packageId={packageData?.id} />
      </Modal>
    </section>
  );
}
