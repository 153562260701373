import { imagesFileUrl } from "../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setTools, tools }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[30px]">
      <span className="block text-center font-bold text-[22px]">انتخاب تجهیزات</span>
      <button onClick={() => setTools(0)} className="w-full p-2 py-4 border  border-silver rounded-md mt-4">
        <section className="flex justify-center gap-4 items-center cursor-pointer">
          <span className={`w-[20px] h-[20px] flex justify-center border border-purple items-center rounded-full mr-2 ${tools === 0 && "bg-purple"}`}>
            <img src={imagesFileUrl + "icons/radioCheck.svg"} alt="" />
          </span>
          <span> همه تجهیزات</span>
        </section>
      </button>
      <section className="grid grid-cols-3 xl:grid-cols-3 items-center gap-3 mt-[12px]">
        <section onClick={() => setTools(5)} className={` h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 5 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/ketelball.png"} className="w-[50px]" alt="" />
          <span className="text-sm font-bold ">کتل بل</span>
        </section>
        <section onClick={() => setTools(3)} className={`w-[80px] h-[80px] cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 3 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/damble1.png"} className="w-[50px] " alt="" />
          <span className="text-sm font-bold ">دمبل</span>
        </section>
        <section onClick={() => setTools(6)} className={`w-[80px] h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 6 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/cable.png"} className="w-[50px] " alt="" />
          <span className="text-sm font-bold ">سیم کش</span>
        </section>
        <section onClick={() => setTools(4)} className={`w-[80px] h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 4 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/halter.png"} className="w-[50px]" alt="" />
          <span className="text-sm font-bold">هالتر</span>
        </section>
        <section onClick={() => setTools(2)} className={`w-[80px] h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 2 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/bodyWeight.png"} className="w-[50px]" alt="" />
          <span className="text-sm font-bold ">وزن بدن</span>
        </section>
        <section onClick={() => setTools(7)} className={`w-[80px] h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 7 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/foam-roller.png"} className="w-[50px]" alt="" />
          <span className="text-sm font-bold ">فوم رولر</span>
        </section>
        <section onClick={() => setTools(1)} className={` h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 1 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/kesheshi.png"} className="w-[50px]" alt="" />
          <span className="text-[12px] font-bold ">حرکات کششی</span>
        </section>
        <section onClick={() => setTools(9)} className={` h-[80px]  cursor-pointer border flex flex-col justify-center items-center  bg-white rounded-[14px] hover:border-purple hover:shadow-xl transition-all ${tools === 9 ? "border-purple" : "border-[#E6E8E9]"}`}>
          <img src={imagesFileUrl + "icons/kesh.png"} className="w-[50px]" alt="" />
          <span className="text-sm font-bold ">کش</span>
        </section>
      </section>
    </section>
  );
}
