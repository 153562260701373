import Modal from "../Modal";
import Button from "../Button";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ openModal, setOpenModal, title, question, actionLoading, successFunction }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <Modal onClose={() => setOpenModal(false)} open={openModal} title={title}>
      <span>{question}</span>
      <section className="flex justify-center mt-10 gap-2">
        <Button onClick={() => setOpenModal(false)} className="h-[40px]" outlined={true}>
          خیر
        </Button>
        <Button loading={actionLoading} onClick={successFunction} className="h-[40px]">
          بلی
        </Button>
      </section>
    </Modal>
  );
}
