import { useState, useEffect } from "react";
import ManFront from "../../components/SVG/ManFront";
import ManBack from "../../components/SVG/ManBack";
import WomanFront from "../../components/SVG/WomanFront";
import WomanBack from "../../components/SVG/WomanBack";
import GenderSelector from "./components/GenderSelector";
import ToolsSelector from "./components/ToolsSelector";
import { useNavigate } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [front22, setFront22] = useState(false);
  const [back22, setBack22] = useState(false);
  const [front16, setFront16] = useState(false);
  const [back16, setBack16] = useState(false);
  const [front11, setFront11] = useState(false);
  const [back11, setBack11] = useState(false);
  const [back7, setBack7] = useState(false);
  const [front7, setFront7] = useState(false);
  const [front12, setFront12] = useState(false);
  const [back12, setBack12] = useState(false);
  const [muscle, setMuscle] = useState("");
  const [gender, setGender] = useState("FEMALE");
  const [tools, setTools] = useState(0);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (muscle) {
      navigation(`/exercise/exercise-detail?gender=${gender}&muscleId=${muscle}&equipmentId=${tools}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muscle, gender, tools]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="max-w-[90%] w-[1850px] m-auto mt-[90px] xl:mt-[60px] 2xl:mt-[120px] mb-[160px]">
      {/*  <section className="hidden xl:block">
        <span className="font-normal text-[16px] text-purple">خانه</span>
        <span> / </span>
        <span className="font-normal text-[16px] text-[#A6A9BD]">تمرینات ورزشی</span>
      </section> */}
      <section className="grid grid-cols-3 gap-4 mt-[32px]">
        <section className="h-auto xl:h-[700px] col-span-3 xl:col-span-1 flex flex-col justify-center items-center border border-[#B7E9F7] bg-[#F6FCFE] rounded-[18px] py-6 xl:py-[70px] px-[32px]">
          <section className="h-auto w-auto xl:h-[488px] m-auto">
            <GenderSelector state={gender} setState={setGender} />
            <ToolsSelector tools={tools} setTools={setTools} />
          </section>
        </section>
        {/* Muscle Selctor */}
        <section className="h-auto xl:h-[700px] col-span-3 xl:col-span-2 border border-[#B7E9F7] bg-[#F6FCFE] rounded-[18px]">
          <span className="font-bold text-[22px] m-auto block text-center mt-[20px]">انتخاب عضله</span>
          <section className="flex justify-center items-center flex-wrap">
            {gender === "FEMALE" && (
              <>
                <section>
                  <WomanFront style={{ height: "550px" }} setMuscle={setMuscle} front11={front11} front12={front12} front16={front16} front22={front22} front7={front7} setBack7={setBack7} setBack11={setBack11} setBack12={setBack12} setBack16={setBack16} setBack22={setBack22} alt="" />
                </section>
                <section>
                  <WomanBack style={{ height: "550px" }} setMuscle={setMuscle} back11={back11} back12={back12} back16={back16} back22={back22} back7={back7} setFront7={setFront7} setFront11={setFront11} setFront12={setFront12} setFront16={setFront16} setFront22={setFront22} alt="" />
                </section>
              </>
            )}
            {gender === "MALE" && (
              <>
                <section>
                  <ManFront style={{ height: "550px" }} setMuscle={setMuscle} alt="" front11={front11} front12={front12} front16={front16} front22={front22} front7={front7} setBack7={setBack7} setBack11={setBack11} setBack12={setBack12} setBack16={setBack16} setBack22={setBack22} />
                </section>
                <section>
                  <ManBack style={{ height: "550px" }} back11={back11} back12={back12} back16={back16} back22={back22} back7={back7} setFront7={setFront7} setFront11={setFront11} setFront12={setFront12} setFront16={setFront16} setFront22={setFront22} setMuscle={setMuscle} alt="" />
                </section>
              </>
            )}
          </section>
        </section>
      </section>
      {/* <section className="w-full">
        <button className="block w-[84px] h-[40px] mt-[20px] bg-purple text-white rounded-full m-auto">ادامه</button>
      </section> */}
    </section>
  );
}
