import Step from "../../../assets/images/basicInfo/step1L.png";
import Progress from "../components/Progress";
import NumberPicker from "../../../components/common/NumberPicker";
import { useEffect, useState } from "react";
import { ErrorMessage } from "../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setHeight, defaultValue, height }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (height < 120) {
      setError("قد نباید کمتر 120 سانتی متر باشد");
      setDisabled(true);
    } else if (height > 220) {
      setError("قد نباید بیشتر از 220 سانتی متر باشد");
      setDisabled(true);
    } else {
      setError("");
      setDisabled(false);
    }
  }, [height]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className="h-[78%] w-full flex flex-col xl:block justify-between items-center">
        <section className="w-full">
          <span className="block text-center font-bold text-xl xl:text-3xl mt-7">قدتو وارد کن</span>
          <section className="w-[350px] m-auto">
            <NumberPicker value={defaultValue} setValue={setHeight} type="height" />
            <section className="mt-5 m-auto">
              <ErrorMessage>{error}</ErrorMessage>
            </section>
          </section>
        </section>

        <button onClick={() => setStep(4)} disabled={disabled} className="text-[20px] disabled:opacity-60 bg-purple xl:m-auto xl:mt-10 block p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl">
          مرحله بعد
        </button>
      </section>

      {/* border-radius: 16px;
    font-size: 20px;
    font-family: YekanBakh;
    background-color: #5408aa;
    display: block;
    padding: 10px;
    width: 430px;
    max-width: 100%;
  
    color: white;
    margin-top: 60px;
    transition: all 0.5s linear; */}
    </>
  );
}
