import { useState, useEffect } from "react";
import { imagesFileUrl } from "../../../config";
import DietItem from "./components/DietItem";
import Tabs from "./components/Links";
import DownloadPdf from "./components/FoodPdfDownload";
import api from "../../../api";
import { useDietStore } from "../../../store/dashboard/diet";
import WaterCounter from "./components/WaterCounter";
import ExercisePlan from "./components/ExercisePlan";
import DietAllInfo from "./components/DietAllInfo";
import { Link } from "react-router-dom";
import { useAxiosWithToken } from "../../../hooks";
import { useBasicInfoStore } from "../../../store/dashboard/basicInfo";
import { Loading } from "../../../components/common";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
const getDateNumberPersian = (value) => {
  switch (value) {
    case "FIRST":
      return "اول";
    case "SECOND":
      return "دوم";
    case "THIRD":
      return "سوم";
    case "FOURTH":
      return "چهارم";
    case "FIFTH":
      return "پنجم";
    case "SIXTH":
      return "ششم";
    case "SEVENTH":
      return "هفتم";
    case "EIGHTH":
      return "هشتم";
    case "NINTH":
      return "نهم";
    case "TENTH":
      return "دهم";
    case "ELEVENTH":
      return "یازدهم";
    case "TWELFTH":
      return "دوازدهم";
    default:
    // code block
  }
};
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const dietInfo = useDietStore((state) => state.dietInfo);
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const day = getDateNumberPersian(dietInfo?.day);
  const month = getDateNumberPersian(dietInfo?.month);
  const week = getDateNumberPersian(dietInfo?.week);
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [foodCatList, setFoodCatList] = useState([]);
  const [foodPlanList, setFoodPlanList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [dietTabActive, setDietTabActive] = useState(1);
  const [activeLinkTab, setActiveLinkTab] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getFoodCategory = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.categories.searchCategories + "?pageSize=50")
      .then((res) => {
        setFoodCatList(res.data.elements);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const getChoosePlan = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.foodProgramPackage.userPackageList)
      .then((res) => {
        setFoodPlanList(res?.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getFoodCategory();
    getChoosePlan();
  }, []);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="bg-white rounded-[8px] p-4 px-5 shadow-md">
      <Loading />
      <section className="flex justify-between items-center pl-1 ">
        <h1 className="font-bold text-lg">{`ماه ${month} / هفته ${week} / روز ${day}`}</h1>
        <Link to="/dashboard" href="#" className="text-[#5408A9] text-[12px] flex items-center gap-2">
          بازگشت
          <section className="px-[10.5px] py-2">
            <img alt="" src={imagesFileUrl + "dashboard/Vector.png"} className="w-[5px] h-[10px]" />
          </section>
        </Link>
      </section>
      <DietAllInfo />
      <Tabs activeLinkTab={activeLinkTab} setActiveLinkTab={setActiveLinkTab} />
      {activeLinkTab === 1 && (
        <>
          <section className="xl:w-[741px] min-h-8 h-auto bg-[#F1E8FC] rounded-[18px] mt-4 px-[26px] py-[24px]">
            <section className="w-full min-h-[60px] h-auto mb-8 flex flex-wrap justify-between items-center rounded-md xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] p-2">
              <button onClick={() => setDietTabActive(1)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 1 && `bg-[#F1E8FC]`}`}>
                صبحانه
              </button>
              <button onClick={() => setDietTabActive(2)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 2 && `bg-[#F1E8FC]`}`}>
                میان وعده صبح
              </button>
              <button onClick={() => setDietTabActive(3)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 3 && `bg-[#F1E8FC]`}`}>
                ناهار
              </button>
              <button onClick={() => setDietTabActive(4)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 4 && `bg-[#F1E8FC]`}`}>
                میان وعده عصر
              </button>
              <button onClick={() => setDietTabActive(5)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 5 && `bg-[#F1E8FC]`}`}>
                شام
              </button>
            </section>
            <section className="my-2">{foodPlanList && basicInfo.firstName && <DownloadPdf data={foodPlanList} basicInfo={basicInfo} />}</section>
            {dietTabActive === 1 && <DietItem foodPlanList={foodPlanList?.breakfasts} type="BREAKFAST" activeTab={activeTab} tab={1} setActiveTab={setActiveTab} foodCatList={foodCatList} catLoading={loading} img={imagesFileUrl + "dashboard/diet.png"} title="صبحانه" disabled={true} status="success" />}
            {dietTabActive === 2 && <DietItem foodPlanList={foodPlanList?.snackBeforeLunches} type="SNACK_BEFORE_LUNCH" activeTab={activeTab} tab={2} setActiveTab={setActiveTab} foodCatList={foodCatList} catLoading={loading} img={imagesFileUrl + "dashboard/diet4.png"} title="میان وعده" time="صبح" disabled={true} status="success" />}
            {dietTabActive === 3 && <DietItem foodPlanList={foodPlanList?.lunches} type="LUNCH" activeTab={activeTab} tab={3} setActiveTab={setActiveTab} foodCatList={foodCatList} catLoading={loading} img={imagesFileUrl + "dashboard/diet1.png"} title="ناهار" status="none" />}
            {dietTabActive === 4 && <DietItem foodPlanList={foodPlanList?.snackBeforeLunches} type="SNACK_BEFORE_DINNER" activeTab={activeTab} tab={4} setActiveTab={setActiveTab} foodCatList={foodCatList} catLoading={loading} img={imagesFileUrl + "dashboard/diet2.png"} title="میان وعده" status="success" time="عصر" />}
            {dietTabActive === 5 && <DietItem foodPlanList={foodPlanList?.dinners} type="DINNER" DINNER activeTab={activeTab} tab={5} setActiveTab={setActiveTab} foodCatList={foodCatList} catLoading={loading} img={imagesFileUrl + "dashboard/diet3.png"} title="شام" status="success" />}
          </section>
        </>
      )}
      {activeLinkTab === 2 && <WaterCounter />}
      {activeLinkTab === 3 && <ExercisePlan />}
    </section>
  );
}
