import { devBaseUrl, mainBaseUrl } from "../config";
const getBaseUrl = () => {
  if (window.location.host === "bodyness.ir") {
    return mainBaseUrl;
  } else {
    return devBaseUrl;
  }
};
let baseUrl = getBaseUrl();
//////Token/////////////////
const api = {
  userInformation: {
    getAdditionalInformation: baseUrl + "user-information/additional-information",
  },
  auth: {
    sendMobileNumber: baseUrl + "notification/send-otp",
    verifyOTP: baseUrl + "notification/verify-otp",
    loginOrSignUp: baseUrl + "user-information/login-or-signup",
  },
  user: {
    getUserInfo: baseUrl + "personal-program/find-full-personal-program",
    editUserInfo: baseUrl + "user-information/edit-profile/",
    updateUser: baseUrl + "user-information/update-user/",
    uploadImage: baseUrl + "user-information/upload-profile-image",
  },
  exercise: {
    searchExercise: baseUrl + "exercise-equipment-and-muscle/search-with-pageable",
    getExerciseSystem: baseUrl + "classification-of-exercises/search-with-pageable",
    getMuscles: baseUrl + "muscle/search-muscles-pageable",
    getExercise: baseUrl + "exercise-equipment-and-muscle/search-with-pageable",
    addExercise: baseUrl + "sport-personal-program/add",
  },
  recipe: {
    searchRecipe: baseUrl + "recipe/search-recipes-pageable",
  },
  ticket: {
    calculateTickets: baseUrl + "ticket/calculate-tickets",
    searchTickets: baseUrl + "ticket/search-tickets",
    searchDepartments: baseUrl + "ticket-department/search-departments",
    addTicket: baseUrl + "ticket/add-ticket",
    addMessage: baseUrl + "ticket-message/add-message",
    searchMessages: baseUrl + "ticket-message/search-messages",
  },
  categories: {
    searchCategories: baseUrl + "food-categories/search-categories",
  },
  foodsConsumed: {
    searchCategories: baseUrl + "food-consumed/search-foods-consumed",
  },
  foodsNutrition: {
    searchFoodsNutrition: baseUrl + "food-nutrition-values/search-foods-nutrition",
    addFoodNutrition: baseUrl + "food-nutrition-values/add-food-nutrition",
    editFoodNutrition: baseUrl + "food-nutrition-values/edit-food-nutrition/",
  },
  personalProgram: {
    addDairyToPersonalProgram: baseUrl + "personal-program/add-dairy-to-personal-program",
    getCurrentProgram: baseUrl + "personal-program/find-current-personal-program",
    getCurrentProgramPerDay: baseUrl + "personal-program/find-current-personal-program",
    removeDairy: baseUrl + "personal-program/remove-dairy-from-personal-program",
    addPersonalSportProgram: baseUrl + "personal-sport-program/add",
    addPersonalSportProgramDtl: baseUrl + "personal-sport-program/add-detail/",
    getProgramByUserRequest: baseUrl + "personal-food-program/get-by-user-request",
  },
  water: {
    addWaterToProgram: baseUrl + "personal-program/add-daily-water-to-personal-program",
  },
  disease: {
    findAllList: baseUrl + "disease/find-all-list",
  },
  request: {
    getAllRequest: baseUrl + "request/search-with-pageable",
  },
  question: {
    getSicknessQuestion: baseUrl + "question/search-list?questionType=MEDICINE",
    getExerciseQuestion: baseUrl + "question/search-list?questionType=SPORT",
    addAnswer: baseUrl + "answer/add",
    documentAdd: baseUrl + "medical-and-sport-document/add",
  },
  foodProgramPackage: {
    addFoodProgramPackage: baseUrl + "food-program-package/add",
    searchFoodProgramPackage: baseUrl + "food-program-package/search/",
    addFoodProgramPackageDetail: baseUrl + "food-program-package-detail/add",
    deleteFoodDetail: baseUrl + "food-program-package-detail/delete/",
    getPackageList: baseUrl + "food-program-package/find-all-list",
    addPackageToUserDiet: baseUrl + "personal-food-program/add",
    userPackageList: baseUrl + "personal-food-program/find-all-list",
    createPersonalProgramPackage: baseUrl + "personal-program/add-dairy-from-package-to-personal-program",
    deleteFoodProgramPackage: baseUrl + "food-program-package/delete/",
  },
  contactUs: {
    addContactUs: baseUrl + "contact-us/add",
  },
  payment: {
    request: baseUrl + "payment/request",
  },
  discount: {
    getDiscount: baseUrl + "request/get-discount-info",
  },
  referral: {
    isValidReferralCode: baseUrl + "request/is-valid-referral-code",
  },
  packageInfo: {
    getPackageInfo: baseUrl + "package-info/search",
  },
  sportPlan: {
    getSportPlan: baseUrl + "personal-sport-program/search-with-list",
  },
  doctor: {
    addMedicalPrescription: baseUrl + "medical-prescription/add",
    getMedicalPrescriptionList: baseUrl + "medical-prescription/search-with-list",
    getMedicalDescriptionList: baseUrl + "medical-description/search-with-list",
    getMedicalDescriptionCategory: baseUrl + "medical-description-categories/search-with-list",
    prescriptionChangeStatus: baseUrl + "medical-prescription/change-status",
    foodProgramDelete: baseUrl + "personal-food-program/delete/",
    foodChangeStatus: baseUrl + "personal-food-program/change-status",
    editForApprove: baseUrl + "request/edit-for-approved",
    requestReserve: baseUrl + "request/reserved-request/",
  },
  coach: {
    getCoachInfo: baseUrl + "personal-page/search-with-pageable",
    getCoachExerciseList: baseUrl + "personal-sport-program/search-with-list",
    removeCoachExercise: baseUrl + "personal-sport-program/remove/",
    approveCoachExercise: baseUrl + "personal-sport-program/approve/",
    editForApprove: baseUrl + "request/edit-for-approved",
    getFoodProgramByUser: baseUrl + "request/personal-food-program/get-by-user-request",
  },
  transaction: {
    getTransaction: baseUrl + "request/search-transaction-and-tracking",
  },
  admin: {
    getDashboardInfo: baseUrl + "admin-dashboard/app-count",
    getUserList: baseUrl + "user-information/search-users",
    getQuestionList: baseUrl + "question/search-whit-pageable",
    addQuestionList: baseUrl + "question/add",
    deleteQuestion: baseUrl + "question/delete/",
    getContactUsList: baseUrl + "contact-us/search-with-pagination",
    getDepartmentList: baseUrl + "ticket-department/search-departments",
    addDepartment: baseUrl + "ticket-department/add-department",
    deleteDepartment: baseUrl + "ticket-department/delete-department/",
    getEquipmentList: baseUrl + "equipment/search-equipments-pageable",
    deleteEquipment: baseUrl + "equipment/delete-equipment/",
    addEquipment: baseUrl + "equipment/add-equipment",
    getMuscleList: baseUrl + "muscle/search-muscles-pageable",
    addMuscleList: baseUrl + "muscle/add-muscle",
    deleteMuscle: baseUrl + "muscle/delete-muscle/",
    getClassification: baseUrl + "classification-of-exercises/search-with-pageable",
    deleteClassification: baseUrl + "classification-of-exercises/delete/",
    addClassification: baseUrl + "classification-of-exercises/add",
    getSupplement: baseUrl + "supplement/search-with-pageable",
    deleteSupplement: baseUrl + "supplement/delete/",
    addSupplement: baseUrl + "supplement/add",
    getRecipe: baseUrl + "recipe/search-recipes-pageable",
    deleteRecipe: baseUrl + "recipe/delete-recipe/",
    addRecipe: baseUrl + "recipe/add-recipe",
  },
};
export default api;
