import { Link } from "react-router-dom";
import { baseFileUrl, imagesFileUrl, svgFileUrl } from "../../../../config";
import ProfileBtn from "../../ProfileBtn";
import { useEffect, useState } from "react";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ mode, menuClass, setMenuClass }) {
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const listenScrollEvent = (e) => {
    if (window.scrollY > 0) {
      setMenuClass("#5606a8");
    } else {
      setMenuClass(mode === "dark" ? "#5606a8" : mode === "light" ? "#FFFFFF26" : "");
    }
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <nav className="origin z-[9998]" style={{ backgroundColor: menuClass }}>
        <div class="wrapper relative max-w-[1300px] h-[80px] items-center justify-between mx-auto hidden xl:flex">
          <div class="logo">
            <Link className=" justify-start items-center hidden xl:flex" to="/">
              <img src={baseFileUrl + "/images/logo-new.png"} className="h-[80px] mr-3" alt="" />
            </Link>
          </div>
          <ul class="nav-links justify-center items-center">
            <li>
              <Link to="/" className="text-white  font-normal ">
                خانه
              </Link>
            </li>
            <li>
              <ul class="drop-menu grid grid-cols-3">
                <li>
                  <Link to="/exercise-plan" className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-purple transition-all hover:scale-110 hover:border-white">
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                    </div>
                    دریافت برنامه ورزشی
                  </Link>
                </li>
                <li>
                  <Link to="/meal-plan" className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-purple transition-all hover:scale-110 hover:border-white">
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                    </div>
                    برنامه غذایی
                  </Link>
                </li>
                <li>
                  <Link to="/exercise/choose-exercise" className="text-purple hover:bg-slate-100 transition-all px-2 rounded-lg font-normal flex items-center gap-2">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-purple transition-all hover:scale-110 hover:border-white">
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                    </div>
                    آموزش حرکات فیتنس و بدنسازی
                  </Link>
                </li>
                <li>
                  <Link to="/tools" className="text-purple font-normal hover:bg-slate-100 transition-all px-2 rounded-lg flex items-center gap-2">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-purple transition-all hover:scale-110 hover:border-white">
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                    </div>
                    سلامت سنج ها
                  </Link>
                </li>
                <li>
                  <Link to="/recipe" className="text-purple font-normal hover:bg-slate-100 transition-all px-2 rounded-lg flex items-center gap-2">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-purple transition-all hover:scale-110 hover:border-white">
                      <img src={svgFileUrl + "arrowLeftDark.svg"} alt="" />
                    </div>
                    رسپی ها
                  </Link>
                </li>
              </ul>
            </li>
            <li className="h-full">
              <section class="desktop-item  h-full text-white cursor-pointer flex justify-center items-center font-normal text-lg mx-[10px]">
                <img className="ml-1" src={svgFileUrl + "check-star.svg"} alt="" />
                <span>خدمات ما</span>
                <img className="mr-1" src={svgFileUrl + "arrowDownWhite.svg"} alt="" />
              </section>
              <div class="mega-box">
                <div class="content overflow-hidden relative rounded-xl">
                  <img src={baseFileUrl + "/images/bg-header-right.svg"} className="absolute top-0 right-0 bg-transparent" alt="" />
                  <img src={baseFileUrl + "/images/bg-header-left.svg"} className="absolute bottom-0 object-cover left-0 bg-transparent" alt="" />
                  <li className="relative z-[1000]">
                    <Link to="/exercise-plan" className="text-purple  hover:bg-[#feedf598] transition-all px-2 hover:rounded-md font-normal flex items-center gap-2">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full  p-3 transition-all hover:scale-110 hover:border-none">
                        <img src={imagesFileUrl + "icons/menu-serv-1.svg"} className="w-[50px] h-[50px] " alt="" />
                      </div>
                      دریافت برنامه ورزشی
                    </Link>
                  </li>
                  <li className="relative z-[1000]">
                    <Link to="/meal-plan" className="text-purple hover:bg-[#feedf598] transition-all px-2 hover:rounded-md font-normal flex items-center gap-2">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full  p-3  transition-all hover:scale-110 hover:border-none">
                        <img src={imagesFileUrl + "icons/menu-serv-3.svg"} className="w-[50px] h-[50px] " alt="" />
                      </div>
                      دریافت برنامه غذایی
                    </Link>
                  </li>
                  <li className="relative z-[1000]">
                    <Link to="/exercise/choose-exercise" className="text-purple hover:bg-[#feedf598] transition-all px-2 hover:rounded-md font-normal flex items-center gap-2">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full  transition-all hover:scale-110 hover:border-none">
                        <img src={imagesFileUrl + "icons/menu-serv-5.svg"} className="w-[40px] h-[40px] " alt="" />
                      </div>
                      آموزش حرکات فیتنس و بدنسازی
                    </Link>
                  </li>
                  <li className="relative z-[1000]">
                    <Link to="/tools" className="text-purple font-normal hover:bg-[#feedf598] transition-all px-2 hover:rounded-md flex items-center gap-2">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full  p-3 transition-all hover:scale-110 hover:border-none">
                        <img src={imagesFileUrl + "icons/menu-serv-2.svg"} className="w-[50px] h-[50px] " alt="" />
                      </div>
                      سلامت سنج ها
                    </Link>
                  </li>
                  <li className="relative z-[1000]">
                    <Link to="/recipe" className="text-purple font-normal hover:bg-[#feedf598] transition-all px-2 hover:rounded-md flex items-center gap-2">
                      <div className="flex h-16 w-16 items-center justify-center rounded-full  p-3  transition-all hover:scale-110 hover:border-none">
                        <img src={imagesFileUrl + "icons/menu-serv-4.svg"} className="w-[50px] h-[50px] " alt="" />
                      </div>
                      رسپی ها
                    </Link>
                  </li>
                </div>
              </div>
            </li>
            <li>
              <Link to="/corporate-services" className="text-white  font-normal">
                خدمات سازمانی
              </Link>
            </li>
            <li>
              <Link to="/team-us" className="text-white  font-normal">
                تیم ما
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="text-white  font-normal">
                تماس با ما
              </Link>
            </li>
          </ul>
          <div>
            <section className="flex ml-4 justify-end items-center">
              <ProfileBtn />
            </section>
          </div>
        </div>
      </nav>
    </>
  );
}
