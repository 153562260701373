import { svgFileUrl } from "../../../../../../../config";



//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ rowData, showModal }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  // ─── States ─────────────────────────────────────────────────────────────────────



  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="grid grid-cols-1 xl:grid-cols-4 gap-1 px-2 py-4 mt-[12px] border border-[#E8E7EB] h-auto   items-center rounded-[12px] cursor-pointer font-normal transition-all hover:shadow-[0px_2px_14px_-4px_#5408A929]">
        <section>
          <span className="mr-1">{rowData?.id}</span>
        </section>
        <section>
          <span className="mr-1">{rowData?.namePackage}</span>
        </section>
        <section>
          <span className="mr-1 text-xs font-light">{rowData?.description}</span>
        </section>
        <section className="flex justify-center  items-center gap-2">
          <button onClick={() => showModal(rowData)} className="px-2 text-purple font-semibold py-2 rounded-full bg-[whitesmoke] flex justify-center gap-1 items-center">
            <span> نمایش جزییات</span>
            <img className="w-[20px] h-[20px]" src={svgFileUrl + "detail.svg"} alt="" />
          </button>
        </section>

      </section>

    </>
  );
}
