import { BlobProvider } from "@react-pdf/renderer";
import BodyNessPdf from "../../../../../../../../../components/PDF/ExercisePlan";
const MyPDFPage = ({ data }) => {
  return (
    <BlobProvider document={<BodyNessPdf data={data} />}>
      {({ url, loading }) =>
        loading ? (
          <button disabled>در حال بارگزاری</button>
        ) : (
          <a href={url} download="example.pdf" className={`w-[148px] h-[30px] border border-[#F3509D]  rounded-[100px] flex justify-center items-center hover:text-white hover:bg-[#F3509D] text-[#F3509D]  text-sm`}>
            دانلود PDF
          </a>
        )
      }
    </BlobProvider>
  );
};

export default MyPDFPage;
