import { useState } from "react";
import Progress from "./components/Progressbar";
import CaloryItem from "./components/CalloryItemApex";
import Links from "./components/Links";
import Counter from "./components/Counter";
import { imagesFileUrl } from "../../../config";
import Ruler from "./components/Ruler";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeNumber, setActiveNumber] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="bg-white rounded-[8px] p-4 px-5 shadow-md">
      <section className="w-[741px] h-[231px] flex justify-between items-center border border-[#E6E8E9] bg-[#F7F9FE] rounded-[18px] m-auto mt-3 px-10">
        <section className="w-[306px] h-[159px] flex flex-col justify-between items-center">
          <Progress color="#4CC9EC" title="کربوهیدرات" weight="5/214" />
          <Progress color="#F02484" style={{ marginTop: 10 }} title="چربی" weight="5/214" />
          <Progress color="#7639BA" style={{ marginTop: 10 }} title="پروتئین" weight="5/214" />
        </section>
        <CaloryItem goalCalory={2400} consumedCalory={1380} percent={50} />
      </section>
      <Links />
      <section className='w-[736px] h-[353px] py-[34px] px-[33px] border border-[#B7E9F7] bg-[#EEFAFE] rounded-[18px] m-auto bg-[url("/public/images/dashboard/toolsbg.png")]'>
        <span className="font-normal">تعداد لیوان آب مصرفی امروز خود را مشخص کنید.</span>
        <section className="w-[450px] h-[168px] flex justify-between m-auto mt-[30px]">
          <section>
            <Counter activeNumber={activeNumber} setActiveNumber={setActiveNumber} />
          </section>
          <section className="w-[160px] flex items-center">
            <section className="h-[148px] w-[45px]">
              <Ruler activeNumber={activeNumber} />
            </section>
            <img src={imagesFileUrl + "/dashboard/glassWater.png"} className="w-[99px] h-[168px]" alt="" />
          </section>
        </section>
      </section>
    </section>
  );
}
