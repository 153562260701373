import { useState } from "react";
import Modal from "../../../../../components/common/Modal";
import Button from "../../../../../components/common/Button";
import TypeSelector from "./TypeSelector";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { notify } from "../../../../../helper";
import { NotifyMessage } from "../../../../../enums";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ open, closeModal, rowData }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const typeOptions = [
    { value: "GRAM", label: "گرم" },
    { value: "TABLESPOON", label: "قاشق غذاخوری" },
    { value: "DESSERTSPOON", label: "قاشق مربا خوری" },
    { value: "TEASPOON", label: "قاشق چای‌خوری" },
    { value: "LADLE", label: "ملاقه" },
    { value: "MATCHBOX", label: "قوطی کبریت" },
    { value: "CUP", label: "فنجان" },
    { value: "HALF_CUP", label: "نیم‌فنجان" },
    { value: "SMALL_BOWL", label: "کاسه کوچک" },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [gram, setGram] = useState();
  const [type, setType] = useState("BREAKFAST");
  const [unit, setUnit] = useState("GRAM");
  const [actionLoading, setActionLoading] = useState(false);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const addFood = () => {
    setActionLoading(true);
    let params = {
      typeOfMealEnum: type,
      recipeId: rowData.id,
      consumedGram: parseInt(gram),
      unit: unit,
    };

    useAxiosWithToken
      .put(api.personalProgram.addDairyToPersonalProgram, params)
      .then((res) => {
        setActionLoading(false);
        closeModal();
        notify.Success(NotifyMessage.SUCCESS_ACTION);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <Modal width={600} title="افزودن به برنامه غذایی" open={open} onClose={closeModal}>
      <section className="flex items-center justify-between mt-4">
        <span className="font-bold text-sm text-purple">{rowData?.foodName}</span>
        <span className="font-normal text-sm">{"به ازای صد گرم - " + rowData?.calories + " کالری"}</span>
      </section>
      <section className="flex mt-5 relative border border-[#E6E8E9] rounded-full overflow-hidden">
        <input
          onChange={(e) => {
            let value = e.target.value.replace(/\D/g, "");
            setGram(value);
          }}
          maxLength={20}
          value={gram}
          type="text"
          className="w-full xl:w-full h-[40px] mx-auto  text-sm  pr-3 pl-12 outline-none border-none border border-transparent focus:border-transparent focus:ring-0"
          placeholder="مقدار مصرف خود را وارد کنید"
        />
        <select onChange={(e) => setUnit(e.target.value)} className="ml-1 cursor-pointer border-none text-center w-[250px] focus:border-transparent focus:ring-0">
          {typeOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </section>
      {gram > 3000 && <span className="block text-red-600 font-light text-sm pr-3 mt-2">مقدار ورودی نباید بیشتر از 3000 باشد</span>}
      <TypeSelector type={type} setType={setType} />
      <section className="ltr">
        <Button disabled={!(gram > 0 && gram <= 3000)} onClick={addFood} loading={actionLoading} type="primary" round className="w-[110px]  bg-purple text-white rounded-full font-normal text-sm mr-auto mt-4">
          ذخیره
        </Button>
      </section>
    </Modal>
  );
}
