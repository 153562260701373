import React from "react";

function Index({ actions, setDeleteId, item1, showDeleteModal }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      {actions && (
        <td className="w-[140px] rounded-xl justify-center items-center gap-1 border-[3px] border-white text-center">
          {actions.map((item, index) => (
            <span key={index}>
              {item.type === "edit" && (
                <button
                  onClick={() => {
                    item.onClick(item1);
                  }}
                  className="text-xs bg-green-500 mr-2 text-white px-2 py-1 rounded-md"
                >
                  ویرایش
                </button>
              )}
              {item.type === "delete" && (
                <button
                  onClick={() => {
                    showDeleteModal();
                    setDeleteId(item1?.id);
                  }}
                  className="text-md font-normal text-red-500 px-3 py-1 rounded-md"
                >
                  حذف
                </button>
              )}
            </span>
          ))}
        </td>
      )}
    </>
  );
}

export default React.memo(Index);
