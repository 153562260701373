import React, { useState, useContext, useEffect } from 'react'

import PDFDownloader from '../PdfDownloader';
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ rowData }) {
    // ─── Global Variable ────────────────────────────────────────────────────────────

    // ─── States ─────────────────────────────────────────────────────────────────────

    // ─── Life Cycle ─────────────────────────────────────────────────────────────────

    // ─── Functions ──────────────────────────────────────────────────────────────────

    //
    // ──────────────────────────────────────────────────── I ──────────
    //   :::::: R E N D E R : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────
    //
    return (
        <>
            {rowData && <PDFDownloader data={rowData} />}
            <hr className='my-4 border-[whitesmoke]' />
            <section className="w-full min-h-[100px] rounded-lg">
                <section className="grid grid-cols-4 py-2 text-lg">
                    <span>نام دارو</span>
                    <span>نوع دارو</span>
                    <span>تعداد</span>
                    <span>توضیحات</span>
                </section>
                {rowData?.medicalPrescriptionDetails?.map((item, index) => (
                    <section key={index} className="grid grid-cols-4 py-2  text-md text-purple">
                        <span>{item.drugName}</span>
                        <span>{item.drugType}</span>
                        <span>{item.count}</span>
                        <span>{item.description}</span>
                    </section>
                ))}
            </section>
        </>
    )
}
