import React, { useEffect } from "react";
import Plus from "../../../assets/images/basicInfo/plus.png";
import Minus from "../../../assets/images/basicInfo/minus.png";
import { hasValue } from "../../../helper";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ value, setValue, type }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  let min = type === "weight" ? 35 : type === "height" ? 120 : type === "age" ? 15 : 0;
  let max = type === "weight" ? 250 : type === "height" ? 220 : type === "age" ? 80 : 0;
  let unitText = type === "weight" ? "کیلوگرم" : type === "height" ? "سانتی متر" : type === "age" ? "سال" : 0;
  // ─── States ─────────────────────────────────────────────────────────────────────
  /* const [weight, setWeight] = useState(65); */
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const increaseValue = () => {
    if (value < max) {
      setValue(parseInt(value) + 1);
    }
  };
  const decreaseValue = () => {
    if (value > min) {
      setValue(parseInt(value) - 1);
    }
  };

  useEffect(() => {
    console.log(value);
  }, [value]);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full h-[48px] flex  items-center bg-white border border-silver rounded-[12px] mt-4 justify-around">
      <img alt="" src={Plus} className="h-[19px] w-[19px] cursor-pointer" onClick={increaseValue} />
      <section className="flex justify-center items-center">
        <input
          type="text"
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setValue(value);
            } else {
              e.target.value = value.replace(/\D/g, "");
            }
          }}
          onMouseLeave={() => !hasValue(value) && setValue(0)}
          onBlur={() => !hasValue(value) && setValue(0)}
          value={String(value)}
          className="text-[20px] h-[40px] text-center  inline-block w-[80px] border-silver rounded-md  border"
        />
        <span className="text-[12px] font-normal mr-2">{unitText}</span>
      </section>
      <section onClick={decreaseValue} className="w-[19px] h-[19px] cursor-pointer flex justify-center items-center">
        <img alt="" src={Minus} className="w-[19px] " />
      </section>
    </section>
  );
}
