import { useEffect, useState } from "react";
import Step from "../../../assets/images/basicInfo/step456L.png";
/* import BodyFatSelectorOld from '../components/BodyFatSelector_old'; */
import BodyFatSelector from "../components/BodyFatSelector";
import Progress from "../components/Progress";
import Body1 from "../../../assets/images/basicInfo/body(1).png";
import Body2 from "../../../assets/images/basicInfo/body(2).png";
import Body3 from "../../../assets/images/basicInfo/body(2).png";
import Body4 from "../../../assets/images/basicInfo/body(3).png";
import Body5 from "../../../assets/images/basicInfo/body(4).png";
import Body6 from "../../../assets/images/basicInfo/body(5).png";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ setStep, activeStep, setBodyFat }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [myBodyFat, setMyBodyFat] = useState(1);
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    setBodyFat(myBodyFat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBodyFat]);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <Progress stepPic={Step} activeStep={activeStep} setStep={setStep} />
      <section className="h-[78%] flex flex-col justify-between xl:block">
        <section>
          <span className="block text-center font-bold text-xl xl:text-3xl mt-7">درصد چربی رو مشخص کن</span>
          <img src={myBodyFat === 1 ? Body1 : myBodyFat === 2 ? Body2 : myBodyFat === 3 ? Body3 : myBodyFat === 4 ? Body4 : myBodyFat === 5 ? Body5 : myBodyFat === 6 ? Body6 : ""} className="bodyImage" alt="" />
          <section className="w-[300px] m-auto">
            {/*  <BodyFatSelectorOld setBodyFat={setMyBodyFat} /> */}
            <section className="mt-4">
              <BodyFatSelector setState={setMyBodyFat} />
            </section>
          </section>
        </section>
        <button onClick={() => setStep(7)} className="text-[20px] bg-purple mt-10 xl:m-auto xl:mt-10 block p-[10px] w-[430px] max-w-[100%] text-white transition-all duration-500 rounded-2xl">
          مرحله بعد
        </button>
      </section>
    </>
  );
}
