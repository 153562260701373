import { useEffect, useState } from "react";
import { Button, ComponentLoading, ConfirmModal, Modal, TextBox } from "../../../../../components/common";
import { useAxiosWithToken } from "../../../../../hooks";
import api from "../../../../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableRow from "./components/TableRow";
import { notify } from "../../../../../helper";
import { NotifyMessage } from "../../../../../enums";
import MealDetailModal from '../../../MealDetailModal'
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const mobileNumber = searchParams.get("mobileNumber");
  const requestId = searchParams.get("userId");
  const navigation = useNavigate();
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false)
  const [description, setDescription] = useState("");
  const [rowData, setRowData] = useState()
  const showModal = (rowData) => {
    setDetailModal(true)
    setRowData(rowData)
  }
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getList = () => {
    useAxiosWithToken
      .get(api.personalProgram.getProgramByUserRequest + `?personalFoodProgramStatus=WAITING_TO_APPROVE&requestId=${requestId}&username=${mobileNumber}`)
      .then((res) => {
        setLoading(false);
        setData(res.data.foodProgramPackages);
        setId(res.data.id);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const rejectFood = () => {
    setDeleteLoading(true);
    useAxiosWithToken
      .put(api.doctor.editForApprove + `?requestProgramStatus=TAKEN_AND_NEED_CHANGE&personalFoodProgramId=${id}&id=${requestId}&rejectDescription=${description}`)
      .then((res) => {
        setDeleteLoading(false);
        setRejectModal(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        getList();
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };
  const approve = () => {
    setActionLoading(true);
    useAxiosWithToken
      .put(api.doctor.editForApprove + "?requestProgramStatus=TAKEN_AND_APPROVED&personalFoodProgramId=" + id + "&id=" + requestId)
      .then((res) => {
        setActionLoading(false);
        setApproveModal(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setTimeout(() => {
          navigation("/doctor-dashboard/without-plan-user");
        }, 2000);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };
  const showDetailModal = () => {

  }
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="h-auto mt-[40px] xl:py-[32px] xl:px-[26px] overflow-hidden bg-white rounded-[16px] shadow-md">
        <section className="xl:w-full m-auto mt-10 text-center text-[14px] relative">
          <section className="hidden xl:grid grid-cols-4 gap-1">
            <section className="w-full">ردیف</section>
            <section className="w-full">نام پکیچ</section>
            <section className="w-full">وعده غذایی</section>
            <section className="w-full">جزییات</section>
          </section>
          <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
            <ComponentLoading loading={loading} />
            {data?.map((item, index) => (
              <TableRow showModal={showModal} reloadTable={getList} rowData={item} />
            ))}
          </section>
        </section>
        {id && (
          <section className="flex justify-start gap-2 mt-3 items-center">
            <Button onClick={() => setApproveModal(true)} className="">
              تایید پرونده پزشکی و نمایش برای کاربر
            </Button>
            <Button onClick={() => setRejectModal(true)} className=" bg-red-500">
              رد برنامه و برگرداندن آن به کارشناس تغذیه
            </Button>
          </section>
        )}
        <Modal onClose={() => setRejectModal(false)} open={rejectModal} title="رد برنامه غذایی">
          <span>آیا مایلید برنامه غذایی را رد کنید؟</span>
          <TextBox onChange={(e) => setDescription(e?.target?.value)} />
          <section className="flex justify-end mt-2 gap-2">
            <Button onClick={() => setRejectModal(false)} className="h-[40px]" outlined={true}>
              خیر
            </Button>
            <Button disabled={description.length < 2} loading={deleteLoading} onClick={rejectFood} className="h-[40px]">
              بلی
            </Button>
          </section>
        </Modal>
        <ConfirmModal successFunction={approve} actionLoading={actionLoading} openModal={approveModal} setOpenModal={setApproveModal} question="آیا مایلید پرونده پزشکی را تایید کنید؟" />
      </section>
      <Modal width={1200} open={detailModal} onClose={() => setDetailModal(false)}>
        <MealDetailModal rowData={rowData} />
      </Modal>
    </>
  );
}
