import { useState, useEffect, Fragment } from "react";
import { Button, ComponentLoading, ConfirmModal, Modal } from "../../../../../../../components/common";
import TableRow from "./components/TableRow";
import { useAxiosWithToken } from "../../../../../../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../../../../../api";
import { notify } from "../../../../../../../helper";
import { NotifyMessage } from "../../../../../../../enums";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ reload, reloadTable }) {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  // ─── Global Variable ────────────────────────────────────────────────────────────
  /*   const mobileNumber = searchParams.get("mobileNumber"); */
  const requestId = searchParams.get("userId");
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [exerciseIds, setExerciseIds] = useState([]);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getExerciseList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.coach.getCoachExerciseList, {
        params: {
          requestId: requestId,
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res?.data);
        let arr = [];
        res?.data?.forEach((item, index) => {
          arr.push(item?.id);
        });

        setExerciseIds(arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const approve = () => {
    setActionLoading(true);
    useAxiosWithToken
      .put(api.coach.editForApprove + "?requestProgramStatus=TAKEN_AND_APPROVED&personalSportProgramId=" + exerciseIds + "&id=" + requestId)
      .then((res) => {
        setActionLoading(false);
        notify.Success(NotifyMessage.SUCCESS_ACTION);
        setApproveModal(false);
        setTimeout(() => {
          navigation("/coach-dashboard/without-plan-user");
        }, 2000);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getExerciseList();
  }, [reload]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px]">
      <section className="w-full m-auto mt-10 text-center text-[14px] relative">
        <section className="grid grid-cols-1 xl:grid-cols-5 gap-1">
          <section className="w-full">شناسه تمرین</section>
          <section className="w-full">دانلود</section>
          <section className="w-full">جزییات تمرین</section>
          <section className="w-full col-span-2">حذف</section>
        </section>
        <section className="relative w-[95%] xl:w-full mx-auto min-h-12">
          <ComponentLoading loading={loading} />
          {data?.map((item, index) => (
            <Fragment key={index}>
              <TableRow reloadTable={reloadTable} rowData={item} />
            </Fragment>
          ))}
        </section>
        {data.length > 0 && (
          <Button loading={actionLoading} onClick={() => setApproveModal(true)} className="ml-auto mt-10">
            تایید پرونده ورزشی و نمایش برای کاربر
          </Button>
        )}
      </section>
      <ConfirmModal successFunction={approve} actionLoading={actionLoading} openModal={approveModal} setOpenModal={setApproveModal} question="آیا مایلید پرونده ورزشی را تایید کنید؟" />
    </section>
  );
}
