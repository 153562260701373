import { useEffect, useState } from "react";
import Info from "./components/Info";
import Diet from "./components/Diet";
import DoctorPrescription from "./components/DoctorPrescription";
import { useSearchParams } from "react-router-dom";
import { useAxiosWithToken } from "../../../hooks";
import api from "../../../api";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const [searchParams] = useSearchParams();
  const mobileNumber = searchParams.get("mobileNumber");
  const requestId = searchParams.get("userId");
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getDataInfo = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.userInformation.getAdditionalInformation + `?mobileNumber=${mobileNumber}&requestProgramId=${requestId}`)
      .then((res) => {
        setUserData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getDataInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <>
      <section className="w-full xl:w-full h-[80px] flex justify-between items-center rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] px-4  xl:px-[86px]">
        <button onClick={() => setActiveTab(1)} className={`xl:w-[200px]  w-auto px-[5px] h-10 xl:h-[48px] rounded-full ${activeTab === 1 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs  xl:text-xl`}>
          اطلاعات تکمیلی
        </button>
        <button onClick={() => setActiveTab(3)} className={`xl:w-[200px] px-[5px]  w-auto h-10 xl:h-[48px] rounded-full ${activeTab === 3 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs xl:text-xl`}>
          افزودن نسخه پزشکی
        </button>
        <button onClick={() => setActiveTab(2)} className={`xl:w-[200px]  w-auto px-[5px] h-10 xl:h-[48px] rounded-full ${activeTab === 2 ? "bg-[#F1E8FC] text-purple" : "text-[#3E4151]"} font-semibold text-xs xl:text-xl`}>
          برنامه های غذایی
        </button>
      </section>
      {activeTab === 1 && <Info loading={loading} data={userData} />}
      {activeTab === 2 && <Diet />}
      {activeTab === 3 && <DoctorPrescription data={userData} />}
    </>
  );
}
