import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export function useRouteListener() {
  const location = useLocation();
  const [routeStatus, setRouteStatus] = useState(0);

  useEffect(() => {
    setRouteStatus((prev) => prev + 1);
  }, [location.pathname]);

  return [routeStatus];
}

export default useRouteListener;
