import Select from "react-select";
import "./style.css";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ placeHolder, dark, disabled, search, setState, options, state, className }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ********************************

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <Select
      options={options}
      onChange={setState}
      className={className}
      disabled={disabled}
      isRtl={true}
      value={state}
      placeholder={placeHolder ? placeHolder : "انتخاب کنید..."}
      isSearchable={search}
      loadingMessage={() => <span>در حال بارگزاری...</span>}
      noOptionsMessage={() => <span>اطلاعاتی موجود نیست</span>}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "",
          primary: "#2C8EE8",
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#2C8EE8" : "#D1D1D1",
          backgroundColor: dark ? "#2f2f2f80" : "white",
          width: "100% !important",
          minHeight: 48,
          overflow: "hidden",
          borderRadius: 8,
          color: "#666666",
          fontSize: 12,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        }),
        /*   option: (provided) => ({
            ...provided,
            backgroundColor: dark ? "black" : "white",
            color: dark ? "white" : "black",
          }), */
        singleValue: (provided) => ({
          ...provided,
          color: dark ? "white" : "black",
        }),
      }}
    />
  );
}
